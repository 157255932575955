import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@/utils/helpers';

import { useConfigStore, usePersistedPrismicStore, useSearchStore } from '@/store';

import OnlineAppointmentIcon from './OnlineAppointmentIcon';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        boxShadow: '0 0 0 1px #D5D5D5',
        background: '#FBFBF9',
        padding: '16px',
        userSelect: 'none',
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '3rem',
        transition: 'background 0.2s ease, box-shadow 0.3s ease',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
        '&:hover': {
            background: '#f3f3f3',
        },
        '&:active': {
            background: '#eaeaea',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    treatmentCard: {
        gap: 0,
        width: '15rem',
        flexDirection: 'column',
        alignItems: 'start',
    },
    accordionCard: {
        height: '9rem',
    },
    treatmentCardTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: '0.5rem',
        alignItems: 'center',
    },
    selectedAccordionCard: {
        boxShadow: '0 0 0 2px #000',
    },
    treatmentDetailsWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '0.25rem',
    },
    treatmentDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '12rem',
        width: '100%',
        fontWeight: 700,
    },
    treatmentDescription: {
        margin: 0,
        flexShrink: 1,
        flexGrow: 1,
        color: '#686868',
        fontSize: '1rem',
        marginBottom: '0.25rem',
    },
    professionalProfilePicture: {
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        objectFit: 'cover',
    },
    professionalCardInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
    },
    infoText: {
        color: 'rgba(46, 46, 46, 0.70)',
        fontWeight: 350,
        fontSize: '14px',
    },
    seeMoreButton: {
        border: 'none',
        background: 'transparent',
        padding: 0,
        cursor: 'pointer',
        color: theme.palette.common.yellow,
        textTransform: 'uppercase',
        marginLeft: '0.25rem',
        fontWeight: 'bold',
    },
}));

export const SearchResultType = {
    TREATMENT: 'treatment',
    SPECIALTY: 'specialty',
    PROFESSIONAL: 'professional',
};

const ACCORDION_VIEW_APPOINTMENT_SELECTION_LIMIT = 3;

const SearchResultCard = ({ searchResultType = SearchResultType.TREATMENT, prismicData, cardKey }) => {
    const classes = useStyles();
    const history = useHistory();
    const query = useQuery();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [professionalData, setProfessionalData] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const search = useSearchStore((state) => state.search);
    const locations = useSearchStore((state) => state.locations);
    const specialties = useSearchStore((state) => state.specialties);
    const treatments = useSearchStore((state) => state.treatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const selectedSpecialty = useSearchStore((state) => state.selectedSpecialty);
    const isOnlineAppointment = useSearchStore((state) => state.isOnlineAppointment);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const {
        [i18n.language]: { professionalProfiles: processedProfessionalProfiles },
    } = usePersistedPrismicStore((state) => state.findAppointmentData);
    const ref = useRef();

    useEffect(() => {
        if (searchResultType !== SearchResultType.PROFESSIONAL) return;

        const professional = processedProfessionalProfiles.find(
            (professional) => professional.key[0].text === prismicData.key[0].text
        );

        setProfessionalData(professional);
    }, [prismicData, searchResultType]);

    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, []);

    const handleClick = (e) => {
        if (!ref.current) return;

        if (!ref.current?.contains(e.target)) {
            setShowFullDescription(false);
        }
        if (e.target.id === `${cardKey}_see_more`) {
            setShowFullDescription(true);
        }
    };

    const onSpecialtySelected = () => {
        query.set('specialty', prismicData.key[0].text);
        history.replace({ search: query.toString() });
        useSearchStore.setState({ selectedSpecialty: prismicData.key[0].text, search: prismicData.name[0].text });
    };

    const onProfessionalSelected = () => {
        query.set('selected_professional', prismicData.key[0].text);
        history.replace({ search: query.toString() });
        useSearchStore.setState({
            selectedProfessional: prismicData.key[0].text,
            search: prismicData.display_name[0].text,
        });
    };

    const getUnderlinedText = (text) => {
        // Use a regular expression to split the text in a case-insensitive manner, while preserving the original case.
        // Escape special characters in the search string to ensure the regular expression works correctly.
        const escapedSearch = search.trim().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regex = new RegExp(`(${escapedSearch})`, 'gi');

        // Split the text into parts, including the search text.
        const parts = text.split(regex);

        return (
            <div
                lang={i18n.language}
                style={{
                    hyphens: 'auto',
                }}
            >
                {!search && (
                    <span
                        lang={i18n.language}
                        style={{
                            wordBreak: 'break-word',
                        }}
                    >
                        {text}
                    </span>
                )}
                {search &&
                    parts.map((part, index) => {
                        // Check if the current part matches the search text, using a case-insensitive comparison.
                        const isMatch = part.toLowerCase() === search.trim().toLowerCase();
                        return (
                            <Fragment key={index}>
                                {!isMatch ? (
                                    // This is a non-search part of the text.
                                    <span
                                        lang={i18n.language}
                                        style={{
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {part}
                                    </span>
                                ) : (
                                    // This part matches the search text and should be underlined.
                                    <span
                                        lang={i18n.language}
                                        style={{
                                            textDecoration: 'underline',
                                            fontFamily: 'MessinaSans-SemiBold',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {part}
                                    </span>
                                )}
                            </Fragment>
                        );
                    })}
            </div>
        );
    };

    const updateSelectedTreatments =
        (isOnline = false) =>
        (event) => {
            if (event.target.id === `${cardKey}_see_more`) return;
            if (currentCustomer?.is_accordion_view_active) {
                let newSelectedTreatments = { ...selectedTreatments };

                if (!currentCustomer?.is_multi_treatment_booking_allowed) {
                    newSelectedTreatments = {
                        [prismicData.key[0].text]: true,
                    };
                } else if (newSelectedTreatments[prismicData.key[0].text] && isOnline === isOnlineAppointment) {
                    delete newSelectedTreatments[prismicData.key[0].text];
                } else if (
                    Object.keys(newSelectedTreatments).length === 0 ||
                    (!isOnlineAppointment &&
                        !isOnline &&
                        Object.keys(newSelectedTreatments).length < ACCORDION_VIEW_APPOINTMENT_SELECTION_LIMIT)
                ) {
                    newSelectedTreatments[prismicData.key[0].text] = true;
                } else {
                    return;
                }

                useSearchStore.setState({
                    selectedTreatments: newSelectedTreatments,
                    isOnlineAppointment: isOnline,
                });

                return;
            }

            useSearchStore.setState({
                selectedTreatments: {
                    [prismicData.key[0].text]: true,
                },
                showAvailableProfessionals: true,
                isOnlineAppointment: isOnline,
            });
        };

    if (searchResultType === SearchResultType.TREATMENT) {
        const hasLongName = prismicData.name[0].text.length > 22;

        return (
            <>
                {(prismicData.location ||
                    treatments[selectedLocation ?? currentCustomer.locations[0].key[0].text]?.[prismicData.key[0].text]
                        ?.location) && (
                    <div
                        key={`${cardKey}-location`}
                        className={clsx(
                            classes.card,
                            classes.treatmentCard,
                            currentCustomer?.is_accordion_view_active && classes.accordionCard,
                            currentCustomer?.is_accordion_view_active &&
                                selectedTreatments[prismicData.key[0].text] &&
                                !isOnlineAppointment &&
                                classes.selectedAccordionCard
                        )}
                        style={{
                            justifyContent: selectedSpecialty ? 'center' : 'space-between',
                            minHeight: selectedSpecialty ? '71px' : 'auto',
                            width:
                                selectedSpecialty ||
                                (currentCustomer?.is_accordion_for_multiple_professionals &&
                                    !currentCustomer?.is_category_view_active) ||
                                (currentCustomer?.is_accordion_view_active &&
                                    !currentCustomer?.is_accordion_for_multiple_professionals &&
                                    matchesXs)
                                    ? '100%'
                                    : '15rem',
                            height:
                                showFullDescription ||
                                !currentCustomer?.is_accordion_view_active ||
                                !currentCustomer?.is_detailed_accordion_treatment_card_active
                                    ? 'auto'
                                    : '9rem',
                        }}
                        onClick={(e) => updateSelectedTreatments()(e)}
                        onMouseLeave={() => setShowFullDescription(false)}
                        ref={ref}
                    >
                        {hasLongName && !showFullDescription && currentCustomer?.is_treatment_card_collapse_active ? (
                            <div>
                                {getUnderlinedText(prismicData.name[0].text.substring(0, 22))}...
                                <button className={classes.seeMoreButton} id={`${cardKey}_see_more`}>
                                    {t('SeeMore')}
                                </button>
                            </div>
                        ) : (
                            getUnderlinedText(prismicData.name[0].text)
                        )}
                        {!selectedSpecialty && (
                            <div className={classes.treatmentDetailsWrapper}>
                                {currentCustomer?.is_accordion_view_active ? (
                                    <>
                                        {currentCustomer?.is_accordion_treatment_card_description_active && (
                                            <p className={classes.treatmentDescription}>
                                                {!showFullDescription && prismicData.description[0].text.length > 30 ? (
                                                    <>
                                                        {prismicData.description[0].text.substring(0, 30)}...
                                                        {(!hasLongName ||
                                                            currentCustomer?.is_accordion_for_multiple_professionals) &&
                                                            currentCustomer?.is_treatment_card_collapse_active && (
                                                                <button
                                                                    className={classes.seeMoreButton}
                                                                    id={`${cardKey}_see_more`}
                                                                >
                                                                    {t('SeeMore')}
                                                                </button>
                                                            )}
                                                    </>
                                                ) : (
                                                    prismicData.description[0].text
                                                )}
                                            </p>
                                        )}
                                        {currentCustomer?.is_detailed_accordion_treatment_card_active && (
                                            <div className={classes.treatmentDetails}>
                                                <p style={{ margin: 0 }}>{prismicData.duration?.[0]?.text ?? '-'}</p>
                                                <p style={{ margin: 0 }}>{prismicData.price?.[0]?.text ?? '-'}</p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Typography className={classes.infoText}>
                                        {
                                            specialties[
                                                prismicData.specialty ??
                                                    treatments[
                                                        selectedLocation ?? currentCustomer.locations[0].key[0].text
                                                    ]?.[prismicData.key[0].text]?.specialty
                                            ].name[0].text
                                        }
                                    </Typography>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {(prismicData.online ||
                    treatments[selectedLocation ?? currentCustomer.locations[0].key[0].text]?.[prismicData.key[0].text]
                        ?.online) && (
                    <div
                        key={`${cardKey}-online`}
                        className={clsx(
                            classes.card,
                            classes.treatmentCard,
                            currentCustomer?.is_accordion_view_active && classes.accordionCard,
                            currentCustomer?.is_accordion_view_active &&
                                selectedTreatments[prismicData.key[0].text] &&
                                isOnlineAppointment &&
                                classes.selectedAccordionCard
                        )}
                        style={{
                            justifyContent: selectedSpecialty ? 'center' : 'space-between',
                            minHeight: selectedSpecialty ? '71px' : 'auto',
                            width:
                                selectedSpecialty ||
                                (currentCustomer?.is_accordion_for_multiple_professionals &&
                                    !currentCustomer?.is_category_view_active) ||
                                (currentCustomer?.is_accordion_view_active &&
                                    !currentCustomer?.is_accordion_for_multiple_professionals &&
                                    matchesXs)
                                    ? '100%'
                                    : '15rem',
                            height:
                                showFullDescription ||
                                !currentCustomer?.is_accordion_view_active ||
                                !currentCustomer?.is_detailed_accordion_treatment_card_active
                                    ? 'auto'
                                    : '9rem',
                        }}
                        onClick={(e) => updateSelectedTreatments(true)(e)}
                        ref={ref}
                    >
                        <div className={classes.treatmentCardTitleContainer}>
                            {hasLongName &&
                            !showFullDescription &&
                            currentCustomer?.is_treatment_card_collapse_active ? (
                                <div>
                                    {getUnderlinedText(prismicData.name[0].text.substring(0, 22))}...
                                    <button className={classes.seeMoreButton} id={`${cardKey}_see_more`}>
                                        {t('SeeMore')}
                                    </button>
                                </div>
                            ) : (
                                getUnderlinedText(prismicData.name[0].text)
                            )}
                            <OnlineAppointmentIcon />
                        </div>
                        {!selectedSpecialty && (
                            <div className={classes.treatmentDetailsWrapper}>
                                {currentCustomer?.is_accordion_view_active ? (
                                    <>
                                        {currentCustomer?.is_accordion_treatment_card_description_active &&
                                            currentCustomer?.is_treatment_card_collapse_active && (
                                                <p className={classes.treatmentDescription}>
                                                    {!showFullDescription &&
                                                    prismicData.description[0].text.length > 30 ? (
                                                        <>
                                                            {prismicData.description[0].text.substring(0, 30)}...
                                                            {(!hasLongName ||
                                                                currentCustomer?.is_accordion_for_multiple_professionals) && (
                                                                <button
                                                                    className={classes.seeMoreButton}
                                                                    id={`${cardKey}_see_more`}
                                                                >
                                                                    {t('SeeMore')}
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        prismicData.description[0].text
                                                    )}
                                                </p>
                                            )}
                                        {currentCustomer?.is_detailed_accordion_treatment_card_active && (
                                            <div className={classes.treatmentDetails}>
                                                <p style={{ margin: 0 }}>{prismicData.duration?.[0]?.text ?? '-'}</p>
                                                <p style={{ margin: 0 }}>{prismicData.price?.[0]?.text ?? '-'}</p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Typography className={classes.infoText}>
                                        {
                                            specialties[
                                                prismicData.specialty ??
                                                    treatments[
                                                        selectedLocation ?? currentCustomer.locations[0].key[0].text
                                                    ]?.[prismicData.key[0].text]?.specialty
                                            ].name[0].text
                                        }
                                    </Typography>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }

    if (searchResultType === SearchResultType.SPECIALTY) {
        return (
            <div key={cardKey} className={classes.card} onClick={onSpecialtySelected}>
                {getUnderlinedText(prismicData.name[0].text)}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M10 19C9.71875 19 9.46875 18.9062 9.28125 18.7188C8.875 18.3438 8.875 17.6875 9.28125 17.3125L14.5625 12L9.28125 6.71875C8.875 6.34375 8.875 5.6875 9.28125 5.3125C9.65625 4.90625 10.3125 4.90625 10.6875 5.3125L16.6875 11.3125C17.0938 11.6875 17.0938 12.3438 16.6875 12.7188L10.6875 18.7188C10.5 18.9062 10.25 19 10 19Z"
                        fill="#2E2E2E"
                    />
                </svg>
            </div>
        );
    }

    if (searchResultType === SearchResultType.PROFESSIONAL) {
        return (
            <div key={cardKey} className={classes.card} onClick={onProfessionalSelected}>
                <img
                    className={classes.professionalProfilePicture}
                    src={prismicData.profile_picture.url}
                    alt="professional profile"
                />
                <div className={classes.professionalCardInfoContainer}>
                    {getUnderlinedText(prismicData.display_name[0].text)}
                    <Typography className={classes.infoText}>
                        {professionalData
                            ? professionalData.specialityType.name[0].text
                            : Object.values(locations).find(
                                  (location) => location.prismicId === prismicData.location_hub.id
                              )?.title?.[0]?.text}
                    </Typography>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M10 19C9.71875 19 9.46875 18.9062 9.28125 18.7188C8.875 18.3438 8.875 17.6875 9.28125 17.3125L14.5625 12L9.28125 6.71875C8.875 6.34375 8.875 5.6875 9.28125 5.3125C9.65625 4.90625 10.3125 4.90625 10.6875 5.3125L16.6875 11.3125C17.0938 11.6875 17.0938 12.3438 16.6875 12.7188L10.6875 18.7188C10.5 18.9062 10.25 19 10 19Z"
                        fill="#2E2E2E"
                    />
                </svg>
            </div>
        );
    }
};

export default SearchResultCard;
