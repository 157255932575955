import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';

import { Context as AuthContext } from '@/context/AuthContext';

import { processAPIWithHeaders } from '@/utils/helpers';

import { useNotificationsStore } from '@/store';

import FilledButton from './FilledButton';
import OutlinedButton from './OutlinedButton';
import countryCode from './PhoneInput/countryCode.json';
import UnderlinedButton from './UnderlinedButton';

const useStyles = makeStyles((theme) => ({
    classTitle: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1.31rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    btnLabel: {
        fontFamily: 'MessinaSans-SemiBold',
        color: theme.palette.common.darkGrey,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.7rem',
        },
    },
    inputFieldContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formControlMobileBtn: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',

        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        borderRightWidth: 0,

        marginBottom: 15,
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    textFields: {
        height: '3.75em',
        marginTop: 10,
    },
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    filledInputRootMobile: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
            // backgroundColor: "red",
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    progressBar: {
        color: theme.palette.common.white,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    successMessageTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '21px',
        marginBottom: '10px',
        color: theme.palette.common.darkGrey,
    },
    successMessage: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
}));

const SmsNotificationCard = ({ dataSet, mainBenefits, mainBenefitsReg }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [phoneState, setPhoneState] = useState(false);

    const {
        state: { user, usersession, userAccount },
        setuseraccounts,
        setuser,
    } = useContext(AuthContext);

    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchor, setAnchor] = useState(null);
    const [loading, setLoading] = useState(false);
    const showSmsSuccessMessage = useNotificationsStore((state) => state.showSuccessMessage);
    const messageRef = useRef();

    useEffect(() => {
        if (user.phone?.number) {
            const numberParts = user.phone.number.split(' ');
            if (numberParts.length >= 2) {
                setMobileNumber(numberParts[1]);
                setMobileCode(numberParts[0]);
                return;
            }

            setMobileNumber(numberParts[0]);
            setMobileCode('+49');
        }
    }, []);

    useEffect(() => {
        messageRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    });

    const activateSMSNotifications = async () => {
        setLoading(true);

        const updatedAccount = await processAPIWithHeaders(`secure/user/accounts/${userAccount.user_id}`, 'POST', {
            body: {
                ...userAccount,
                notifications: {
                    is_email_active: userAccount.notifications.is_email_active,
                    is_sms_active: true,
                },
                subscriptions: {
                    default: true,
                },
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: usersession.idToken.jwtToken,
            },
        });
        console.debug(updatedAccount);

        useNotificationsStore.setState({ showSuccessMessage: true });

        if (user.phone?.number !== mobileCode.concat(` ${mobileNumber}`)) {
            const updatedUser = await processAPIWithHeaders(`secure/users/${user.id}`, 'POST', {
                body: {
                    ...user,
                    phone: {
                        number: mobileCode.concat(` ${mobileNumber}`),
                    },
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: usersession.idToken.jwtToken,
                },
            });

            setuser(updatedUser);
        }

        setuseraccounts(updatedAccount);
        setLoading(false);
    };

    const handleClosePhone = (mobileCode = '') => {
        setAnchor(null);
        if (mobileCode !== '') setMobileCode(mobileCode);
    };

    return (
        <Grid container direction="row" style={{ height: '50%' }}>
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        height: '70%',
                        flexDirection: 'column',
                    }}
                >
                    {!showSmsSuccessMessage ? (
                        <>
                            <Typography className={classes.classTitle} style={{ marginBottom: 10 }}>
                                {dataSet ? mainBenefits.notified_headline[0].text : 'Save time before your appointment'}
                            </Typography>
                            <Typography className={classes.classTeacher} style={{ marginBottom: 20 }}>
                                {dataSet
                                    ? mainBenefits.notified_text[0].text
                                    : 'Fill out the anamnesis to share with your doctor before the appointment.'}
                            </Typography>

                            {!phoneState && (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: matches && 'column',
                                    }}
                                >
                                    <OutlinedButton
                                        text={
                                            <Typography className={classes.btnLabel}>
                                                {dataSet
                                                    ? mainBenefits.activate_sms_notification_cta[0].text
                                                    : t('fActivate sms notifications')}
                                            </Typography>
                                        }
                                        onPress={() => setPhoneState(true)}
                                        color={theme.palette.common.darkGrey}
                                        fullWidth={matches && true}
                                    />
                                    <UnderlinedButton
                                        text={dataSet ? mainBenefits.not_interested_cta[0].text : t('Not Interested')}
                                        onPress={() => useNotificationsStore.getState().setHideSmsNotificationCard()}
                                        noMarginTop={!matches && true}
                                        fullWidth={matches && true}
                                        marginLeft={20}
                                        noJSLeft={matches && true}
                                    />
                                </div>
                            )}
                            {phoneState && (
                                <div
                                    className={classes.inputFieldContainer}
                                    style={{ flexDirection: matches && 'column' }}
                                >
                                    <div style={{ width: matches ? '80%' : '50%' }}>
                                        <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Grid item md={2} sm={3} xs={3}>
                                                <Button
                                                    // id="mobileCodeButton"
                                                    style={{ marginTop: 15 }}
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => setAnchor(e.currentTarget)}
                                                    disableRipple
                                                    className={classes.formControlMobileBtn}
                                                    classes={{
                                                        label: classes.menuLabel,
                                                    }}
                                                >
                                                    <div>
                                                        <Typography
                                                            style={{
                                                                fontFamily: 'MessinaSans-SemiBold',
                                                                fontSize: '0.9rem',
                                                                color: theme.palette.common.darkGrey,
                                                                textTransform: 'none',
                                                                textAlign: 'center',
                                                                // backgroundColor: "red"
                                                                // marginLeft:
                                                                //   mobileCode === "Female" ? 5 : mobileCode === "Other" ? -5 : -10,
                                                            }}
                                                        >
                                                            {mobileCode}
                                                        </Typography>
                                                    </div>
                                                    <img
                                                        src={ic_arrow_bottom}
                                                        alt="select"
                                                        className={classes.downArrow}
                                                    />
                                                </Button>

                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchor}
                                                    keepMounted
                                                    open={Boolean(anchor)}
                                                    onClose={() => {
                                                        handleClosePhone();
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            width: 285,
                                                            backgroundColor: 'white',
                                                            marginTop: -1,
                                                            border: `2px solid ${theme.palette.common.yellow}`,
                                                            height: '40%',
                                                            // widht: "7%",
                                                        },
                                                    }}
                                                >
                                                    {countryCode.map((code) => (
                                                        <MenuItem
                                                            className={classes.menuItem}
                                                            onClick={() => handleClosePhone(code.dialCode)}
                                                            style={{
                                                                backgroundColor:
                                                                    code.dialCode === mobileCode ? '#F9F8F4' : 'white',
                                                            }}
                                                            ref={code.dialCode === mobileCode ? messageRef : null}
                                                        >
                                                            <div
                                                                className=""
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                }}
                                                            >
                                                                <div
                                                                    className=""
                                                                    style={{
                                                                        paddingRight: 10,
                                                                    }}
                                                                >
                                                                    <img src={code.flag.toLowerCase()} />
                                                                </div>

                                                                {code.name}
                                                            </div>
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Grid>
                                            <TextField
                                                id="mobileNumber"
                                                label={mainBenefitsReg[0].mobileNumber || t('MobileNumber')}
                                                variant="filled"
                                                color="primary"
                                                type="number"
                                                value={mobileNumber}
                                                onChange={(event) => setMobileNumber(event.target.value)}
                                                // onBlur={() => validateForm("mobileNumber")}
                                                fullWidth
                                                style={{
                                                    marginTop: 15,
                                                    magrinBottom: 15,
                                                    height: '3.75em',
                                                }}
                                                classes={classes.textFields}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRootMobile,
                                                        focused: classes.fieldFocused,
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                    </div>
                                    <FilledButton
                                        variant="contained"
                                        text={
                                            dataSet ? mainBenefits.confirm_phone_cta[0].text : t('fill out anamnesis')
                                        }
                                        loading={loading}
                                        onPress={activateSMSNotifications}
                                        disabled={!isValidPhoneNumber(mobileCode.concat(` ${mobileNumber}`)) || loading}
                                        color={theme.palette.common.darkGrey}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography className={classes.successMessageTitle}>
                                {dataSet ? mainBenefits.all_set[0].text : 'All set!'}
                            </Typography>
                            <Typography className={classes.successMessage}>
                                {dataSet
                                    ? mainBenefits.success_message[0].text.replace('{phone}', mobileCode + mobileNumber)
                                    : 'Success Message'}
                            </Typography>
                        </>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default SmsNotificationCard;
