import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import arrow_up from '@/assets/Icons/ic_arrow_top.svg';

import { formatDateForTable } from '@/utils/helpers.js';

import { usePersistedPrismicStore, useSearchStore } from '@/store';

import DocumentList from './DocumentList';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: ({ expanded }) => (expanded ? '#FAFAFA' : '#fff'),
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    },
    summary: {
        padding: '0 1.5rem 0 0',
        minHeight: '4rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '0.1875rem solid #F5F5F5',
        [theme.breakpoints.down('xs')]: {
            paddingRight: '2.5rem',
        },
    },
    icon: {
        width: '1rem',
        height: '0.9rem',
        objectFit: 'contain',
    },
    appointmentData: {
        width: '100%',
        boxSizing: 'border-box',
        paddingLeft: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
        },
        '& .MuiTypography-root': {
            flexBasis: '33%',
        },
    },
    date: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
}));

const DocumentsAccordion = ({ appointment, expanded, onAccordionChange }) => {
    const { i18n } = useTranslation();
    const classes = useStyles({ expanded: expanded });
    const { [i18n.language]: doctorData } = usePersistedPrismicStore((state) => state.doctorData);
    const treatments = useSearchStore((state) => state.treatments);
    const { allAppointmentsPrismicData: doc } = doctorData;

    const doctor = useMemo(
        () =>
            doc?.filter(
                (s) => s.type !== 'page_professionals' && s?.data?.key?.[0]?.text === appointment.professional.key
            ),
        [doc, appointment.professional]
    );
    const doctorName = doctor?.[0]?.data?.display_name?.[0]?.text ?? '';

    const service =
        treatments[appointment.location_key]?.[appointment.data.services[0].key]?.name[0].text ?? appointment?.name;

    const date = formatDateForTable(appointment.start);

    return (
        <div>
            <Accordion className={classes.container} expanded={expanded}>
                <AccordionSummary
                    onClick={() => onAccordionChange(appointment.id)}
                    expandIcon={<img alt="shrink accordion" src={arrow_up} className={classes.icon} />}
                    id="panel1a-header"
                    className={classes.summary}
                >
                    <div className={classes.appointmentData}>
                        <Typography className={classes.date}>{date}</Typography>
                        <Typography className={classes.text}>{service}</Typography>
                        <Typography className={classes.text}>{doctorName}</Typography>
                    </div>
                </AccordionSummary>
                <DocumentList documents={appointment?.documents} />
            </Accordion>
        </div>
    );
};

export default DocumentsAccordion;
