//* * React */
//* * MUI */
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
//* * Others */
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//* * Router */
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

//* * Styled Component */
import BorderLinearProgress from '@/components/Common/BorderLinearProgress';
import CardContainer from '@/components/Common/CardContainer';
//* * Reusable Components */
import StepMenu from '@/components/Common/StepMenu/StepMenu';
//* * Styles & Child Components */
import { ConsentStyles, CreateUserAccount, SignDocumentModal, VerifyAccount } from '@/components/ConsentForms';
import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
//* * Context */
import {
    ConsentFormsContext,
    FormDataCategory,
    FormTypes,
    guardianValue,
    signEnum,
    toggleValues,
} from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

//* * API HOOKS */
import { useConsentFormsApi } from '@/hooks/useConsentForms';
import useConsentFormsData from '@/hooks/useConsentFormsData';
import useFormSteps from '@/hooks/useFormSteps';
import useHealthInsurance from '@/hooks/useHealthInsurance';
//* * Hooks */
import usePrismicData from '@/hooks/usePrismicData';
import useProfessionalMappings from '@/hooks/useProfessionalMappings';
import { useSignUp } from '@/hooks/useSignUp';
import useUserAddress from '@/hooks/useUserAddress';
import useUserData from '@/hooks/useUserData';
import { useVeryfyCode } from '@/hooks/useVeryfyCode';

import { consentFormServiceUpdate } from '@/service/ConsentForms/consentFormService';

import { dayjs } from '@/utils/dayjsSetup';
//* * Helpers */
import { emailExists, getKeyByValue, getText, isBase64, textToBase64Image, useQuery } from '@/utils/helpers';

import { usePersistedPrismicStore, useProfessionalsStore, useSearchStore } from '@/store';

import ContractDetails from './Pages/ContractDetailsPage';
//* * Pages */
import PersonalInformation from './Pages/PersonalInformationPage';
import SummaryAndConsent from './Pages/SummaryAndConsentPage';

const CorterierTreatment = () => {
    const classes = ConsentStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const history = useHistory();
    const query = useQuery();
    const step = query.get('step');

    const professionals = useSearchStore((state) => state.professionals);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);

    /** Context */
    const {
        consentFormsContent,
        whichEnum,
        signState,
        setSignState,
        setSignature,
        formData,
        setErrors,
        errors,
        guardianErrors,
        setGuardianErrors,
        guardianData,
        signature,
        setSummaryEdit,
        openSignModal,
        setOpenSignModal,
        professional,
        setProfessional,
        //* * Password */
        passwordError,
        confirmPasswordError,
        password,
        confirmPassword,
        validatePassword,
        //* * Eterno Account States */
        createAccount,
        verificationCode,
        signUpUser: signUpUserContext,
        consentAllForms,
        setConsentAllForms,
        consentFormsLoading,
        setConsentFormsLoading,
        formTemplateId,
    } = useContext(ConsentFormsContext);

    const {
        state: { user },
    } = useContext(AuthContext);

    const { insuranceCompany, insuranceId, insuranceType } = useContext(ContextProviderTwo);

    const { guardian, guardianFirstName, guardianLastName, guardianStreet, guardianZipCode, guardianCity } =
        guardianData;

    const consentFormsTreatment = usePersistedPrismicStore((state) => state.consentFormsTreatment);
    const heraklesAllForms = usePersistedPrismicStore((state) => state.heraklesAllForms);

    const { loading: heraklesLoading, content: heraklesContent } = usePrismicData(heraklesAllForms);
    const { loading: treatmentLoading, content } = usePrismicData(consentFormsTreatment, 'welcome_treatment_contract');
    const { loading: popupLoading, content: popupContent } = usePrismicData(consentFormsTreatment, 'signature_popup');
    const { loading: translationLoading, content: translationContent } = usePrismicData(
        consentFormsTreatment,
        'translation_popup'
    );
    const { loading: extendedLoading, content: extendedContent } = usePrismicData(
        consentFormsTreatment,
        'extended_treatment_contract'
    );
    const { loading: messagePageLoading, content: messagePageContent } = usePrismicData(
        consentFormsTreatment,
        'consent_forms_-_welcome_page'
    );

    const { [i18n.language]: anamnesisData } = usePersistedPrismicStore((state) => state.anamnesisData);

    useConsentFormsData(
        {
            treatmentLoading: treatmentLoading,
            popupLoading: popupLoading,
            translationLoading: translationLoading,
            extendedLoading: extendedLoading,
            messagePageLoading: messagePageLoading,
            heraklesLoading: heraklesLoading,
        },
        {
            content: content,
            popupContent: popupContent,
            translationContent: translationContent,
            extendedContent: extendedContent,
            messagePageContent: messagePageContent,
            heraklesContent: heraklesContent,
        }
    );
    useUserData();
    useHealthInsurance();
    useUserAddress();
    useProfessionalMappings();

    //* * Use SignUp Hook */
    const { signUp, user: signUpUser, loading: isLoading } = useSignUp();
    const { verifyCode, loading: isVerifying } = useVeryfyCode();

    //* * Use API Hook */
    const { loading: apiLoading, sendConsentForm } = useConsentFormsApi();

    const { isConsentAllForms, next, back, isFirstStep, isLastStep, currentForm, currentStepIndex } = useFormSteps();

    const filteredForms = useMemo(
        () => consentAllForms?.forms?.filter(({ id }) => id !== 'ANAMNESIS'),
        [consentAllForms?.forms]
    );
    const currentFormIndex = filteredForms?.findIndex((form) => form?.id === currentForm?.id);
    const signatureTypeForNextForm = consentAllForms?.formTemplates[currentFormIndex + 1]?.signatureType ?? null;
    const currentFormTemplateId = consentAllForms?.forms[currentStepIndex]?.id ?? null;
    const addAccountCreationForm = isConsentAllForms && isLastStep && !user;

    //* * Current Flow Pages */
    const stepsEnum = useMemo(
        () => ({
            'personal-information': 'personal-information',
            'contract-details': 'contract-details',
            ...(addAccountCreationForm && { 'create-account': 'create-account' }),
            ...(addAccountCreationForm && createAccount === toggleValues.YES && { verification: 'verification' }),
            'summary-and-consent': 'summary-and-consent',
        }),
        [addAccountCreationForm, createAccount]
    );

    /** Child Pages */
    const pages = useMemo(
        () => ({
            'personal-information': PersonalInformation,
            'contract-details': ContractDetails,
            ...(addAccountCreationForm && { 'create-account': CreateUserAccount }),
            ...(addAccountCreationForm && createAccount === toggleValues.YES && { verification: VerifyAccount }),
            'summary-and-consent': SummaryAndConsent,
        }),
        [addAccountCreationForm, createAccount]
    );

    const currentStepNo = useMemo(() => Object.keys(pages).findIndex((p) => p === step), [pages, step]);
    const currentStepName = useMemo(() => Object.keys(pages).find((p) => p === step), [pages, step]);
    const isLastPage = useMemo(
        () => Object.keys(pages)[Object.keys(pages).length - 1] === currentStepName,
        [pages, currentStepName]
    );

    const navigateToStepOne = () => {
        if (step === 'verification' && isConsentAllForms && currentStepNo < 0) {
            // after account verification redirect to summary page
            history.replace(`/consent-treatment-extended?step=${Object.keys(pages).at(-1)}`);
        } else if (currentStepNo < 0) {
            history.replace(`/consent-treatment-extended?step=${Object.keys(pages)[0]}`);
        }
    };

    useEffect(navigateToStepOne, []);

    const [pageTitles, setPageTitles] = useState({
        'personal-information': 'Personal Information',
        'contract-details': 'Contract Details',
        ...(addAccountCreationForm && { 'create-account': 'create-account' }),
        ...(addAccountCreationForm && createAccount === toggleValues.YES && { verification: 'create-account' }),
        'summary-and-consent': 'Summary And Consent',
    });

    // State to stop continous API calls
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!treatmentLoading) {
            //* * Setting page titles for progress bar */
            setPageTitles({
                'personal-information': getText(content?.personal_information_nav),
                'contract-details': getText(content?.treatment_details_nav),
                ...(addAccountCreationForm && {
                    'create-account': getText(extendedContent?.eterno_account) || 'create-account',
                }),
                ...(addAccountCreationForm &&
                    createAccount === toggleValues.YES && {
                        verification: getText(extendedContent?.eterno_account) || 'create-account',
                    }),
                'summary-and-consent': getText(content?.summary_and_consent_nav),
            });
        }
    }, [addAccountCreationForm, content, createAccount, extendedContent?.eterno_account, treatmentLoading]);

    /** Current Page */
    const renderPage = useCallback(() => {
        const CurrentPage = pages[step] || 'p';
        return <CurrentPage />;
    }, [pages, step]);

    /** Returns Next Or Previous Step Name */
    const getStepName = useCallback(
        (which = whichEnum.NEXT) => {
            const keys = Object.keys(pages);
            const index = keys.indexOf(currentStepName);
            const whichIndex = which === whichEnum.NEXT ? index + 1 : index - 1;
            return keys[whichIndex];
        },
        [currentStepName, pages, whichEnum.NEXT]
    );

    const getNextButtonText = useMemo(() => {
        const continueText = consentFormsContent?.buttons?.continue || 'Continue';
        const sendText = getText(anamnesisData.mainDataSummary?.send_cta) ?? 'Send';
        const signDocText = consentFormsContent?.buttons?.signDocument || 'Sign document';
        const readyToSignText = consentFormsContent?.buttons?.readyToSign || 'I’m ready to sign';

        if (isLastPage && signState === signEnum.SIGNED) {
            return signDocText;
        }

        if (isLastPage) {
            return readyToSignText;
        }

        if (signState === signEnum.SIGNED && isLastStep) {
            return sendText;
        }

        return continueText;
    }, [consentFormsContent, isLastPage, signState, isLastStep, anamnesisData]);

    useEffect(() => {
        if (!professional?.key || !professionalMappings) return;

        setProfessional({
            ...professional,
            ext_id: professionalMappings[professional.key].dc_user_id,
        });
    }, [currentStepIndex, professionalMappings, consentAllForms]);

    /** Back Button Function */
    const handleBack = () => {
        if (isConsentAllForms) {
            if (
                !isFirstStep &&
                (currentStepName === 'personal-information' || currentStepName === 'contract-details')
            ) {
                const type = consentAllForms?.formTemplates[currentFormIndex - 1]?.signatureType || '';
                const src = consentAllForms?.formsBody[currentFormIndex - 1]?.signature_data?.base64_content || '';
                if (type && src) {
                    setSignature({ type: type, src: src });
                    setSignState(signEnum.SIGNED);
                    back();
                }
            }
            history.goBack();
            return false;
        }

        if (currentStepNo >= 0 && stepsEnum[getStepName(whichEnum.PREVIOUS)]) {
            history.push(`/consent-treatment-extended?step=${stepsEnum[getStepName(whichEnum.PREVIOUS)]}`);
        } else {
            // Redirect to welcome page
            history.push('/extended-treatment-welcome');
        }
    };

    /** Continue Button Function */
    const handleContinue = async () => {
        const isSelfPayer = insuranceType === 'SELFPAYER';

        const isGuardian = guardian?.toLocaleLowerCase() === guardianValue.YES.toLocaleLowerCase();

        const defaultProfessional = {
            id: '2c4f8fa7-e423-48f9-add0-c6d6dabc1475',
            key: 'hh-markus-corterier',
            name: 'Dr. med. univ. Markus Philipp Corterier',
            ...(user && { ext_id: '62d0855a166e395cf4fd81ea' }),
        };

        const legalGuardianData = {
            active: isGuardian,
            ...(isGuardian && {
                personal: {
                    first_name: guardianFirstName || '',
                    last_name: guardianLastName || '',
                },
                contact: {
                    street: guardianStreet || '',
                    zip: guardianZipCode || '',
                    city: guardianCity || '',
                    country: '',
                },
            }),
        };

        const signatureData =
            signState !== signEnum.DIRECT_SIGN
                ? {
                      base64_content: isBase64(signature?.src)
                          ? signature?.src
                          : textToBase64Image(signature?.src, 300, 200),
                  }
                : {};

        const API_BODY = {
            instances: [{ type: FormTypes.CONSENT_LG, id: formTemplateId || currentFormTemplateId || '' }],
            ...(isConsentAllForms && { document_id: currentFormTemplateId }),
            ...(user && { user_id: user?.id }),
            ...(user && {
                professional: professional ?? defaultProfessional,
            }),
            ...(!user && {
                personal_data: {
                    professional: professional ?? defaultProfessional,
                    personal: {
                        title: formData?.title,
                        first_name: formData?.firstName.trim(),
                        middle_name: formData?.middleName?.trim(),
                        last_name: formData?.lastName.trim(),
                        date_of_birth: dayjs(formData?.dob, 'DD.MM.YYYY', true).isValid()
                            ? dayjs(formData?.dob, 'DD.MM.YYYY').format('YYYY-MM-DD')
                            : formData?.dob,
                        email: formData?.email,
                        phone: formData?.phoneNo?.number,
                        gender: formData?.gender || '',
                    },
                    contact: {
                        street: formData?.street,
                        zip: formData?.zipCode,
                        city: formData?.city,
                        country: formData?.country?.name,
                    },
                    health_insurance: {
                        insurance_type:
                            insuranceType?.toUpperCase() === 'SELFPAYER' ? 'SELF_PAYER' : insuranceType?.toUpperCase(),
                        insurance_company: insuranceCompany || '',
                        ...(!isSelfPayer && {
                            insurance_id: insuranceId || '',
                        }),
                    },
                },
            }),
            signature_data: signatureData,
            legal_guardian_data: legalGuardianData,
            consent: true,
        };

        //* * Password Page */
        const body = {
            title: formData?.title || '-',
            firstName: formData?.firstName || '',
            lastName: formData?.lastName || '',
            dob: formData?.dob || '',
            email: formData?.email || '',
            street: formData?.street || '',
            zipCode: formData?.zipCode || '',
            city: formData?.city || '',
            country: formData?.country || '',
            gender: formData?.gender || '',
        };

        if (!user && isConsentAllForms) {
            if (
                createAccount === toggleValues.YES &&
                !verificationCode &&
                currentStepName === stepsEnum['create-account']
            ) {
                if (!password || !confirmPassword || passwordError || confirmPasswordError) {
                    validatePassword(t);
                } else {
                    //* * Signing up new user */
                    await signUp(formData?.email, confirmPassword, body);

                    //* * Redirecting to next page */
                    history.push(`/consent-treatment-extended?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                }
                return;
            }
            if (verificationCode && currentStepName === stepsEnum.verification) {
                //* * Verification Page */
                const res = await verifyCode(signUpUser || signUpUserContext, verificationCode, body, confirmPassword);

                if (res === 'SUCCESS') {
                    history.push('/consent-treatment-extended?step=summary-and-consent');
                }
                return;
            }
        }

        //* * Normal Flow | Summary Page */
        if (isLastPage) {
            setSummaryEdit(false); // resetting edit state

            if (signState !== signEnum.SIGNED) {
                //* * Open sign document modal */
                setOpenSignModal(true);
                return;
            }

            // Summary Page | Update form (signature) | Register User
            const signatureDataCategory = {
                ...(user && { user_id: user?.id }),
                category: FormDataCategory.SIGNATURE,
                data: signatureData,
            };

            if (user) {
                setConsentFormsLoading(true);
                await sendConsentForm({
                    body: signatureDataCategory,
                    isAllFormsLastPage: false, // prevent form submission
                    isCommunication: false,
                    isUpdateForm: true,
                });
                setConsentFormsLoading(false);
            } else if (isConsentAllForms) {
                setConsentAllForms((prev) => ({
                    ...prev,
                    updateFormBody: [...prev.updateFormBody, signatureDataCategory],
                }));
            }

            // ----------------------------------------------------------------
            if (isConsentAllForms) {
                if (isLastStep) {
                    if (!(user ? API_BODY : API_BODY.personal_data)?.professional?.ext_id) {
                        return;
                    }

                    setConsentFormsLoading(true);
                    try {
                        if (user) {
                            const updateForm = consentAllForms.updateFormBody?.map((formBody) => ({
                                ...formBody,
                                user_id: user?.id,
                            }));
                            const updateResMap = updateForm.map(async (formBody) => {
                                await consentFormServiceUpdate(formBody);
                            });
                            await Promise.all(updateResMap);
                        }

                        // Add current API body
                        const finalBody = consentAllForms.formsBody?.reduce(
                            (acc, form) => ({
                                ...acc,
                                ...form,
                                instances: [...acc.instances, ...form.instances],
                            }),
                            {
                                ...API_BODY,
                                ...(user && { professional: professional }),
                                ...(user && { user_id: user?.id }),
                            }
                        );

                        await sendConsentForm({
                            body: finalBody,
                            isAllFormsLastPage: isLastStep,
                            isCommunication: false,
                        });
                        next();
                        setConsentFormsLoading(false);
                        return false;
                    } catch (error) {
                        setConsentFormsLoading(false);
                        console.error(error);
                        return false;
                    }
                }

                const alreadySaved = consentAllForms?.formsBody?.some(
                    (body) => body?.instances[0]?.id === currentFormTemplateId
                );

                // FormsBody with updated signature
                const formsBody = consentAllForms?.formsBody?.map((body) => {
                    if (body.instances[0]?.id === currentFormTemplateId) {
                        return { ...body, signature_data: API_BODY?.signature_data || body?.signature_data };
                    }
                    return body;
                });

                setConsentAllForms((prev) => ({
                    ...prev,
                    formsBody: alreadySaved ? formsBody : [...prev.formsBody, API_BODY],
                }));

                if (signatureTypeForNextForm) {
                    // reset current signature
                    setSignature(null);
                    setSignState(signatureTypeForNextForm);
                }
                next('contract-details');
                return false;
            }

            setConsentFormsLoading(true);
            sendConsentForm({
                body: API_BODY,
                isLastPage: isLastPage,
                isCommunication: false,
                isAllFormsLastPage: isLastStep || isLastPage,
                isHerakles: false,
            })
                .then((_) => {
                    //* * Setting Next Page */
                    setConsentFormsLoading(false);
                    history.replace(`/extended-treatment-thankyou`);
                })
                .catch((error) => {
                    //* * Handle any errors that occur during the API calls */
                    setConsentFormsLoading(false);
                    console.error(error);
                });
        }

        /** If confirmed */
        if (currentStepNo >= 0 && stepsEnum[getStepName(whichEnum.NEXT)]) {
            const navigateToNextStep = () => {
                history.push(`/consent-treatment-extended?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
            };

            //* * PERSONAL DETAILS PAGE */
            if (currentStepName === getKeyByValue(pageTitles, pageTitles['personal-information'])) {
                // Required fields
                const localFormData = {
                    firstName: formData?.firstName || '',
                    lastName: formData?.lastName || '',
                    dob: formData?.dob || '',
                    phoneNo: formData?.phoneNo?.number || '',
                    email: formData?.email || '',
                    street: formData?.street || '',
                    zipCode: formData?.zipCode || '',
                    city: formData?.city || '',
                    country: formData?.country || '',
                    gender: formData?.gender || '',
                };

                const { email } = formData;

                // Copy Of Errors State
                const copyErrors = { ...errors };

                // Set Email Error Message
                const setEmailError = (err) => {
                    copyErrors.emailError = err;
                    setErrors(copyErrors);
                };

                // FormData Validation
                Object.keys(localFormData).forEach((form) => {
                    if (form === 'email') {
                        setErrors((prev) => ({ ...prev, emailError: '' }));

                        if (localFormData.email === '') {
                            copyErrors.emailError = 'requiredError';
                            setErrors(copyErrors);
                        } else if (!isEmail(email)) {
                            copyErrors.emailError = 'invalidEmailError';
                            setErrors(copyErrors);
                        } else if (user) {
                            copyErrors.emailError = '';
                            setErrors(copyErrors);
                        } else {
                            if (isFetching) return;
                            // Wait for API request to complete
                            setIsFetching(true);

                            emailExists(localFormData.email, setEmailError, 'emailAlreadyExistError')
                                .then(() => {
                                    setIsFetching(false);
                                })
                                .catch((err) => {
                                    console.debug('Error', err);
                                    setIsFetching(false);
                                });
                        }
                    } else if (form === 'phoneNo') {
                        copyErrors[`${form}Error`] = '';
                        setErrors(copyErrors);

                        // Checking Null Error Message
                        if (localFormData.phoneNo === '') {
                            copyErrors[`${form}Error`] = 'requiredError';
                            setErrors(copyErrors);
                        }
                    } else {
                        // Empty Error Message
                        copyErrors[`${form}Error`] = '';
                        setErrors(copyErrors);

                        // Checking Null Error Message
                        if (localFormData[form] === '' || localFormData[form] === '-') {
                            copyErrors[`${form}Error`] = 'requiredError';
                            setErrors(copyErrors);
                        }
                    }
                });

                // Guardian Validation
                const isGuardian = guardian === guardianValue.YES;
                if (isGuardian) {
                    // Copy Of Errors State
                    const copyGuardianErrors = { ...guardianErrors };
                    Object.keys(guardianData).forEach((guardian) => {
                        // Empty Error Message
                        copyGuardianErrors[`${guardian}Error`] = '';
                        setGuardianErrors(copyGuardianErrors);

                        // Checking Null Error Message
                        if (guardianData[guardian] === '') {
                            copyGuardianErrors[`${guardian}Error`] = 'requiredError';
                            setGuardianErrors(copyGuardianErrors);
                        }
                    });
                }

                // isValidate function (checking whether form is filled)
                const isValidate = () => {
                    const ld = localFormData;
                    const gd = guardianData;
                    const oMap = (obj) => Object.values(obj);
                    if (isGuardian)
                        return oMap({ ...ld, ...gd }).every((d) => d !== '') && oMap(errors).every((d) => d === '');
                    return oMap(ld)?.every((data) => data !== '') && oMap(errors).every((d) => d === '');
                };
                console.debug(
                    '🚀 ~ file: CorterierTreatment.jsx:658 ~ isValidate:',
                    isGuardian,
                    localFormData,
                    errors,
                    isValidate()
                );

                if (isValidate()) {
                    const formData = isConsentAllForms
                        ? {
                              user_id: user?.id,
                              category: FormDataCategory.LEGAL_GUARDIAN,
                              data: legalGuardianData,
                          }
                        : API_BODY;

                    if (user) {
                        await sendConsentForm({
                            body: formData,
                            isLastPage: false,
                            isAllFormsLastPage: false,
                            isCommunication: false,
                            isInsurance: true,
                            isUpdateForm: isConsentAllForms,
                        });
                    } else if (isConsentAllForms) {
                        setConsentAllForms((prev) => ({
                            ...prev,
                            updateFormBody: [...prev.updateFormBody, formData],
                        }));
                    }

                    navigateToNextStep();
                }
            } else if (!isLastPage) {
                navigateToNextStep();
            }
        }
    };

    return (
        <>
            <Grid container className={classes.ContainerBody}>
                {!matchesSm && (
                    <Typography className={clsx(classes.mainHeading, classes.mb15)}>
                        {consentAllForms?.formTemplates[currentFormIndex]?.content?.name ??
                            (consentFormsContent
                                ? consentFormsContent?.titles.extendedContractTitle
                                : 'Patient declaration')}
                    </Typography>
                )}
                <Grid container>
                    <Grid item md={4} sm={10} xs={10} className={classes.sidebarMenu}>
                        <StepMenu
                            title={consentFormsContent ? consentFormsContent?.titles.mainTitle : 'Treatment Contract'}
                            data={
                                isLastStep
                                    ? consentFormsContent?.navSteps
                                    : consentFormsContent?.navSteps?.filter(
                                          (item) =>
                                              !item
                                                  .toLowerCase()
                                                  .includes(i18n.language === 'de' ? '-konto' : 'account')
                                      )
                            }
                            currentStepNo={currentStepNo}
                        />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <CardContainer classNames={matchesSm || matchesXs ? [classes.px20] : []}>
                            <div className={classes.progressContainer}>
                                <Typography className={[classes.progressText, classes.mb5]}>
                                    {`${currentStepNo + 1}/${Object.keys(pages).length} ${pageTitles[step]}`}
                                </Typography>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={((currentStepNo + 1) * 100) / Object.keys(pages).length}
                                />
                            </div>
                            <div>{renderPage()}</div>
                            {/* Footer Buttons */}
                            <div className={clsx(classes.footerButtons, classes.mt30)}>
                                <OutlinedButton
                                    {...(apiLoading ? { disabled: true } : {})}
                                    text={(consentFormsContent && consentFormsContent?.buttons.back) || 'Back'}
                                    onPress={handleBack}
                                    fullWidth={matchesXs}
                                />
                                <FilledButton
                                    //* * Toggle Enable/Disable Button */
                                    disabled={
                                        apiLoading ||
                                        isLoading ||
                                        isVerifying ||
                                        consentFormsLoading ||
                                        (step === 'verification' && !verificationCode) ||
                                        insuranceType === 'PRIVATE'
                                            ? !insuranceCompany
                                            : !insuranceType
                                    }
                                    loading={isLoading || isVerifying || apiLoading || consentFormsLoading}
                                    text={getNextButtonText}
                                    onPress={handleContinue}
                                    fullWidth={matchesXs}
                                    marginBottom={matchesXs && 20}
                                />
                            </div>
                        </CardContainer>
                    </Grid>
                </Grid>
            </Grid>
            {/* Sign Document Modal */}
            <SignDocumentModal city={allDocuments[professionals[professional?.key]?.location_hub?.id]?.city[0].text} />
        </>
    );
};

export default CorterierTreatment;
