import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { getUserStatus, toUnsubscribe } from '@/service/Appointments/unsubscribe';

import { usePrismicStore } from '@/store';

import { Context as AuthContext } from '../context/AuthContext';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        minHeight: 500,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContentClassDetail: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        marginBottom: 20,
    },

    subHeading: {
        ...theme.typography.HL2,
        marginBottom: 15,
    },
    appointmentContainer: {
        marginBottom: 15,
        maxWidth: theme.layout.contentWidth,
    },
    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
    },
}));

const UnSubscribe = () => {
    const classes = useStyles();
    const [unsubscribeStatus, setUnSubscribeStatus] = useState(null);
    const [unsubscribe, setUnSubscribe] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showNormalData, setShowNormalData] = useState(true);
    const [showUnsubscribe, setShowUnsubscribe] = useState(false);
    const {
        state: { usersession, userAccount },
    } = useContext(AuthContext);

    const history = useHistory();

    const mainData = usePrismicStore((state) => state.unsubscribeData);
    const dataSet = !!mainData;

    const unSubscribeUser = (usersession) => {
        setLoading(true);

        getUserStatus(usersession)
            .then((response) => {
                // direct unSubscribeUser
                console.debug('Unsubscribe: response of unsubscribe ', response);
                setUnSubscribeStatus(response?.data);
                const unSubscribeUser = response?.data;

                if (unSubscribeUser === null || unSubscribeUser === undefined) {
                    console.debug('Unsubscribe: false1');
                    return;
                }
                if (unSubscribeUser?.subscriptions.default === false) {
                    setShowNormalData(false);
                    setShowUnsubscribe(true);
                    console.debug('Unsubscribe: false');
                    setLoading(false);
                    // return
                } else {
                    setLoading(true);
                    const { user_id } = userAccount;
                    const { privacy_policy_status } = userAccount;
                    const { terms_and_conditions_status } = userAccount;
                    const { use_of_analytics_status } = userAccount;
                    const { verification_status } = userAccount;
                    const { language } = userAccount.locale;
                    const { country } = userAccount.locale;
                    const { status } = userAccount;
                    const notifications = {
                        is_email_active: userAccount.notifications.is_email_active,
                        is_sms_active: userAccount.notifications.is_sms_active,
                    };

                    toUnsubscribe(
                        usersession,
                        user_id,
                        privacy_policy_status,
                        terms_and_conditions_status,
                        use_of_analytics_status,
                        verification_status,
                        language,
                        country,
                        status,
                        notifications
                    )
                        .then((response) => {
                            setLoading(false);

                            console.debug('Unsubscribe: response2', response);
                            setUnSubscribe(response);
                            setShowNormalData(false);
                            // setShowUnsubscribe(true);
                        })
                        .catch((error) => {
                            console.debug('Unsubscribe: error2', error);
                        });
                }
            })
            .catch((err) => {
                console.debug('Unsubscribe: error of unsubscribe ', err);
            });
    };

    return (
        <>
            {showNormalData && (
                <div className={classes.contentContainer}>
                    <Typography className={classes.mainHeading}>
                        {dataSet
                            ? mainData?.unsubscribe_title[0].text
                            : 'Are you sure you want to unsubscribe from our emails?'}
                    </Typography>
                    <Typography className={classes.shortText}>
                        {dataSet
                            ? mainData?.unsubscribe_subtitle[0].text
                            : 'You will still receive security related emails like when changing your password.'}
                    </Typography>
                    <FilledButton
                        text={dataSet ? mainData?.unsubscribe_cta[0].text : 'Unsubscribe'}
                        onPress={() => {
                            unSubscribeUser(usersession);
                        }}
                        color="red"
                        marginTop={20}
                        loading={loading}
                    />
                    <UnderlinedButton
                        text={dataSet ? mainData?.cancel_cta[0].text : 'cancel'}
                        onPress={() => {
                            history.push('/');
                        }}
                        marginLeft={20}
                    />
                </div>
            )}

            {showUnsubscribe && (
                <div className={classes.contentContainer}>
                    <Typography className={classes.mainHeading}>
                        {dataSet
                            ? mainData?.successfully_unsubscribed_title[0].text
                            : 'You have successfully unsubscribed from our emails. '}
                    </Typography>
                </div>
            )}
            {unsubscribe !== null && unsubscribe !== undefined && (
                <div className={classes.contentContainer}>
                    <Typography className={classes.mainHeading}>
                        {dataSet
                            ? mainData?.successfully_unsubscribed_title[0].text
                            : 'You have successfully unsubscribed from our emails. '}
                    </Typography>
                </div>
            )}
        </>
    );
};

export default UnSubscribe;
