import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Context as AuthContext } from '@/context/AuthContext';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import Account from '@/pages/Account';
import AppointmentConfirm from '@/pages/ConfirmAppointment';
//* * HeraklesAllForms Code */
import AllFormsCheckIn from '@/pages/ConsentForms/AllFormsCheckIn';
import AllFormsCode from '@/pages/ConsentForms/AllFormsCode';
import AllFormsLocation from '@/pages/ConsentForms/AllFormsLocation';
import AllFormsThankYouPage from '@/pages/ConsentForms/AllFormsThankyou';
import AllFormsWelcome from '@/pages/ConsentForms/AllFormsWelcome';
import ConsentAllFormsThankYou from '@/pages/ConsentForms/ConsentAllForms/ThankYou';
// CONSENT ALL_FORMS
import ConsentAllFormsWelcome from '@/pages/ConsentForms/ConsentAllForms/Welcome';
//* * Corterier */
import CorterierTreatment from '@/pages/ConsentForms/CorterierTreatmentContract/CorterierTreatment';

/** Thank You Pages */
import ThankYouPage from '@/pages/ConsentForms/CorterierTreatmentContract/Pages/ThankYouPage';
import CorterierTreatmentWelcome from '@/pages/ConsentForms/CorterierTreatmentContract/Pages/Welcome';
//* * HeraklesForms */
import HeraklesForms from '@/pages/ConsentForms/HeraklesAllForms/HeraklesForms';
import HeraklesThankYouPage from '@/pages/ConsentForms/HeraklesAllForms/Pages/ThankYouPage';
//* * HeraklesAllForms Welcome */
import ConsentPrivacyWelcome from '@/pages/ConsentForms/HeraklesAllForms/Pages/Welcome';
//* * HeraklesCommunicationForms */
import HeraklesCommunicationForms from '@/pages/ConsentForms/HeraklesCommunication/HeraklesCommunicationForms';
import ConsentCommunicationThankYouPage from '@/pages/ConsentForms/HeraklesCommunication/Pages/ThankYouPage';
import ConsentCommunicationWelcome from '@/pages/ConsentForms/HeraklesCommunication/Pages/Welcome';
//* * HeraklesCourseEnrollmentForms */
import HeraklesCourseForms from '@/pages/ConsentForms/HeraklesCourse/HeraklesCourseForms';
import ConsentCourseThankYouPage from '@/pages/ConsentForms/HeraklesCourse/Pages/ThankYouPage';
import ConsentCourseWelcome from '@/pages/ConsentForms/HeraklesCourse/Pages/Welcome';
//* * Herakles Admission Forms */
import HeraklesTherapyForms from '@/pages/ConsentForms/HeraklesTherapy/HeraklesTherapyForms';
import ConsentAdmissionThankYouPage from '@/pages/ConsentForms/HeraklesTherapy/Pages/ThankYouPage';
import ConsentAdmissionWelcome from '@/pages/ConsentForms/HeraklesTherapy/Pages/Welcome';
import ConsentTreatment from '@/pages/ConsentForms/TreatmentContract/ConsentTreatment';
import ConsentTreatmentThankYouPage from '@/pages/ConsentForms/TreatmentContract/Pages/ThankYouPage';
/// ** Consent Forms */
//* * Riede */
import ConsentTreatmentWelcome from '@/pages/ConsentForms/TreatmentContract/Pages/Welcome';
import ConsentHistory from '@/pages/ConsentManagement/History';
import ConsentOverview from '@/pages/ConsentManagement/Overview';
import CustomAnamnesis from '@/pages/CustomAnamnesis';
import Dashboard from '@/pages/Dashboard';
import DoctorsDetails from '@/pages/DoctorsDetails';
import GuestUnsubscribe from '@/pages/GuestUnsubscribe';
import HealthDataPage from '@/pages/HealthProfileOverview/HealthDataPage';
import HealthProfileOverview from '@/pages/HealthProfileOverview/HealthProfileOverview';
import LegacyUploadedByMe from '@/pages/HealthProfileOverview/LegacyUploadedByMe';
import SharedDocuments from '@/pages/HealthProfileOverview/SharedDocuments/SharedDocuments';
import Login from '@/pages/Login';
import MyAppointments from '@/pages/MyAppointments';
import MyDoctors from '@/pages/MyDoctors';
import NotFound from '@/pages/NotFound';
import PrismicFallback from '@/pages/PrismicFallback';
import QuestionPlayground from '@/pages/QuestionPlayground';
import ResetPassword from '@/pages/ResetPassword';
import SearchPage from '@/pages/SearchPage';
import StaticPage from '@/pages/StaticPage/StaticPage.jsx';
import UnSubscribe from '@/pages/UnSubscribe';

import {
    isCorrectWhiteLabelDomain,
    isDevEnvironment,
    isEternoDomain,
    isLocalEnvironment,
    isZeroDomain,
    processAPIWithHeaders,
    redirectToEternoCloud,
    redirectToZero,
} from '@/utils/helpers';

import { useBookingStore, useConfigStore, useCookiesStore, usePersistedPrismicStore, usePrismicStore } from '@/store';

import CookieConstant from './Common/CookieConstant';
import CookieConstantMain from './Common/CookieConstantMain';
import DownloadDocumentModal from './DownloadDocumentModal/DownloadDocumentModal';
import EternoSpinner from './EternoLogoSpinner/EternoSpinner';
import Footer from './Footer';
import Header from './Header';
import UploadDocumentModal from './UploadDocumentModal/UploadDocumentModal';

const useStyles = makeStyles(() => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        fontFamily: 'MessinaSans-Regular',
    },
}));

const StaticPages = {
    PRIVACY: 'Privacy Policy',
    TERMS: 'Terms & Conditions',
    IMPRINT: 'Imprint',
};

const Router = () => {
    const {
        state: { user },
        setuser,
        setusersession,
        setuseraccounts,
    } = useContext(AuthContext);

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [customerKey, setCustomerKey] = useState(null);
    const customers = usePrismicStore((state) => state.customers);
    const showBookingModal = useBookingStore((state) => state.showBookingModal);
    const showPrismicFallback = useConfigStore((state) => state.showPrismicFallback);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const cookieConsentData = usePersistedPrismicStore((state) => state.cookieConsentData);

    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    useEffect(() => {
        getCurrentUser();
    }, []);

    const getAndSetUserSession = async () => {
        console.debug('RefreshToken: Get and set user ');
        try {
            const user = await Auth.currentAuthenticatedUser();
            setusersession(user.signInUserSession);
            refreshTokenAtExp(user.signInUserSession.idToken.payload.exp);
        } catch (err) {
            console.debug(err);
        }
    };

    const refreshTokenAtExp = async (exp) => {
        const expVal = exp * 1000;
        console.debug('RefreshToken: At ', expVal);
        const now = Date.now();
        console.debug('RefreshToken: Now ', now);
        const refreshIn = expVal - now;
        console.debug('RefreshToken: In ', refreshIn);
        setTimeout(() => {
            getAndSetUserSession();
        }, refreshIn);
    };

    const getCurrentUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();

            console.debug('currentUser:', user);
            const { username } = user;
            const token = user.signInUserSession.idToken.jwtToken;

            const response = await processAPIWithHeaders(`secure/users/${username}`, 'GET', {
                headers: {
                    Authorization: token,
                },
            });

            const userAccounts = await processAPIWithHeaders(`secure/user/accounts/${username}`, 'GET', {
                headers: {
                    Authorization: token,
                },
            });

            setuser(response);
            setuseraccounts(userAccounts);
            setusersession(user.signInUserSession);
            setLoading(false);
            refreshTokenAtExp(user.signInUserSession.idToken.payload.exp);
        } catch (err) {
            console.debug(err);
            setLoading(false);
        }
    };

    // work for cookie

    const { i18n } = useTranslation();

    const [largeCookie, setLargeCookie] = useState(false);
    const [cookieOpen, setCookieOpen] = useState(false);

    useEffect(() => {
        const cookieAccepted = useCookiesStore.getState().accepted;

        if (cookieAccepted === null) setCookieOpen(true);
    }, []);

    const cookieConsentContent = cookieConsentData[i18n.language];

    const setEternoCustomer = () => {
        useConfigStore.setState({ currentCustomer: customers[import.meta.env.VITE_ETERNO_CUSTOMER_KEY] });
        setCustomerKey(import.meta.env.VITE_ETERNO_CUSTOMER_KEY);
    };

    const setCustomer = (key) => {
        useConfigStore.setState({ currentCustomer: customers[key] });
        setCustomerKey(key);
    };

    useEffect(() => {
        if (!customers) return;

        const match = window.location.pathname.match(/\/customer\/([^/]+)/);

        if (isLocalEnvironment()) {
            if (!match) {
                setEternoCustomer();
                return;
            }

            if (match && customers[match[1]] && !customers[match[1]].is_eterno_customer) {
                setCustomer(match[1]);
                return;
            }
            redirectToEternoCloud();
        }

        if (isEternoDomain()) {
            setEternoCustomer();
            return;
        }

        if (
            match &&
            customers[match[1]] &&
            !customers[match[1]].is_eterno_customer &&
            isCorrectWhiteLabelDomain(customers[match[1]].domain)
        ) {
            setCustomer(match[1]);
            return;
        }

        if (isZeroDomain()) {
            redirectToZero();
            return;
        }
        redirectToEternoCloud();
    }, [customers]);

    const acceptCookies = (essentialCookies = false, analyticsCookies = false) => {
        useCookiesStore.setState({
            accepted: true,
            essentialCookies: essentialCookies,
            analyticsCookies: analyticsCookies,
        });
        setCookieOpen(false);
        setLargeCookie(false);
    };

    const rejectCookies = () => {
        useCookiesStore.setState({ accepted: false, essentialCookies: false, analyticsCookies: false });
        setCookieOpen(false);
        setLargeCookie(false);
    };

    const PrivateRoute = ({ children, ...rest }) => {
        const renderContent = ({ location }) =>
            user ? children : <Redirect to={`/login?redirectTo=${location.pathname}`} />;

        return <Route {...rest} render={renderContent} />;
    };

    const notIncludingArr = [
        {
            path: '/question-playground',
            Component: QuestionPlayground,
        },
    ];

    if (showPrismicFallback) return <PrismicFallback />;

    if (loading || !customers || !customerKey || !currentCustomer) {
        return (
            <div className={classes.spinnerContainer}>
                <EternoSpinner />
            </div>
        );
    }

    return (
        <BrowserRouter basename={currentCustomer?.is_eterno_customer ? '' : `/customer/${customerKey}`}>
            <Header />
            <Switch>
                <Route exact path="/">
                    {!user || (user && showBookingModal) ? <SearchPage /> : <Dashboard />}
                </Route>
                <Redirect exact from="/dashboard" to="/" />
                <Redirect exact from="/find-appointment/:id" to="/?selected_professional=:id" />
                <Redirect exact from="/find-appointment" to="/" />
                <Redirect from="/book-appointment" to="/" />
                <Redirect exact from="/professional-details/:id" to="/professional/:id" />
                <Route exact path="/login">
                    {user ? <Redirect to="/" /> : <Login />}
                </Route>
                <Route exact path="/privacy">
                    <StaticPage page={StaticPages.PRIVACY} />
                </Route>
                <Route exact path="/terms">
                    <StaticPage page={StaticPages.TERMS} />
                </Route>
                <Route exact path="/imprint">
                    <StaticPage page={StaticPages.IMPRINT} />
                </Route>
                <Route exact path="/reset-password">
                    <ResetPassword />
                </Route>
                <Route exact path="/professional/:id">
                    <DoctorsDetails />
                </Route>
                <Route exact path={isDocumentExchangeFeatureFlagActivated ? '/shared-documents' : '/uploaded-by-me'}>
                    {isDocumentExchangeFeatureFlagActivated ? <SharedDocuments /> : <LegacyUploadedByMe />}
                </Route>
                <Route exact path="/all-forms-welcome">
                    <AllFormsWelcome />
                </Route>
                <Route exact path="/all-forms-thankyou">
                    <AllFormsThankYouPage />
                </Route>
                <Route exact path="/all-forms-checkin">
                    <AllFormsCheckIn />
                </Route>
                <Route exact path="/consent-privacy">
                    <HeraklesForms />
                </Route>
                <Route exact path="/consent-privacy-welcome">
                    <ConsentPrivacyWelcome />
                </Route>
                <Route exact path="/consent-privacy-thankyou">
                    <HeraklesThankYouPage />
                </Route>
                <Route exact path="/consent-admission">
                    <HeraklesTherapyForms />
                </Route>
                <Route exact path="/consent-admission-welcome">
                    <ConsentAdmissionWelcome />
                </Route>
                <Route exact path="/consent-admission-thankyou">
                    <ConsentAdmissionThankYouPage />
                </Route>
                <Route exact path="/consent-communication">
                    <HeraklesCommunicationForms />
                </Route>
                <Route exact path="/consent-communication-welcome">
                    <ConsentCommunicationWelcome />
                </Route>
                <Route exact path="/consent-communication-thankyou">
                    <ConsentCommunicationThankYouPage />
                </Route>
                <Route exact path="/herakles-course">
                    <HeraklesCourseForms />
                </Route>
                <Route exact path="/herakles-course-welcome">
                    <ConsentCourseWelcome />
                </Route>
                <Route exact path="/herakles-course-thankyou">
                    <ConsentCourseThankYouPage />
                </Route>
                <Route exact path="/consent-treatment-welcome">
                    <ConsentTreatmentWelcome />
                </Route>
                <Route exact path="/consent-treatment-thankyou">
                    <ConsentTreatmentThankYouPage />
                </Route>
                <Route exact path="/consent-treatment">
                    <ConsentTreatment />
                </Route>
                <Route exact path="/consent-treatment-extended">
                    <CorterierTreatment />
                </Route>
                <Route exact path="/extended-treatment-welcome">
                    <CorterierTreatmentWelcome />
                </Route>
                <Route exact path="/extended-treatment-thankyou">
                    <ThankYouPage />
                </Route>
                <Route exact path="/all-forms-code">
                    <AllFormsCode />
                </Route>
                <Route exact path="/all-forms-location">
                    <AllFormsLocation />
                </Route>
                <Route exact path="/consent-all-forms-welcome">
                    <ConsentAllFormsWelcome />
                </Route>
                <Route exact path="/consent-all-forms-thankyou">
                    <ConsentAllFormsThankYou />
                </Route>
                <Route exact path="/guest/unsubscribe">
                    <GuestUnsubscribe />
                </Route>
                <Route exact path="/custom-anamnesis">
                    <CustomAnamnesis />
                </Route>
                <Route exact path="/professionals">
                    <MyDoctors />
                </Route>
                <Redirect exact from="/practitioners" to="/professionals" />

                {isDevEnvironment() &&
                    notIncludingArr.map(({ path, Component }) => (
                        <Route exact key={path} path={path}>
                            <Component />
                        </Route>
                    ))}

                <PrivateRoute path="/appointments">
                    <MyAppointments />
                </PrivateRoute>
                <PrivateRoute path="/appointments/:id">
                    <MyAppointments />
                </PrivateRoute>
                <PrivateRoute path="/unsubscribe">
                    <UnSubscribe />
                </PrivateRoute>
                <PrivateRoute path="/account">
                    <Account />
                </PrivateRoute>
                <PrivateRoute path="/health-profile-overview">
                    <HealthProfileOverview />
                </PrivateRoute>
                <PrivateRoute path="/health-data">
                    <HealthDataPage />
                </PrivateRoute>
                <PrivateRoute path="/confirm-appointment">
                    <AppointmentConfirm />
                </PrivateRoute>
                <PrivateRoute path="/consent-overview">
                    <ConsentOverview />
                </PrivateRoute>
                <PrivateRoute path="/consent-history">
                    <ConsentHistory />
                </PrivateRoute>
                <Route path="*" component={NotFound} />
            </Switch>
            {isDocumentExchangeFeatureFlagActivated && <UploadDocumentModal />}
            {isDocumentExchangeFeatureFlagActivated && <DownloadDocumentModal />}
            <Footer />
            {/* cookie work start */}
            {cookieOpen && !largeCookie && cookieConsentContent && (
                <CookieConstant
                    open={cookieOpen}
                    onClose={() => setCookieOpen(false)}
                    setLargeCookie={setLargeCookie}
                    mainData={cookieConsentContent}
                    acceptCookies={acceptCookies}
                    rejectCookies={rejectCookies}
                />
            )}

            {cookieConsentContent && (
                <CookieConstantMain
                    open={largeCookie}
                    onClose={() => setLargeCookie(false)}
                    mainData={cookieConsentContent}
                    acceptCookies={acceptCookies}
                />
            )}
            {/* cookie work end */}
        </BrowserRouter>
    );
};

export default Router;
