import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Child Components
import HealthMenu from '@/components/HealthProfileOverview/HealthMenu';
import Loader from '@/components/Loader';

import { Context as healthProfileOverviewContext } from '@/context/HealthProfileOverviewContext';

import { usePersistedPrismicStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: 40,
        minHeight: '70vh',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
    },

    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },

    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    headerButton: {
        background: 'transparent',
        borderRadius: '5px',
        margin: '.4rem',
        '&:hover': {
            background: 'transparent',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '.2rem 0',
        },
    },
    headerButtonText: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 600,
        color: theme.palette.common.brown,
        fontSize: '14px',
    },
}));

const HealthContainer = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    // HealthProfile Context
    const {
        state: { menuOpen },
    } = useContext(healthProfileOverviewContext);

    if (!dataSet || !content) return <Loader />;

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <div className={classes.contentContainerBody}>
                    <Grid container className={classes.pageHeader}>
                        {!matches && (
                            <Typography
                                className={classes.mainHeading}
                                style={{
                                    fontWeight: 600,
                                    marginLeft: 20,
                                }}
                                id="main_"
                            >
                                {content.your_health_profile[0].text}
                            </Typography>
                        )}
                        {matches || matchesXs || (matchesXs && !menuOpen) ? <HealthMenu /> : null}
                    </Grid>
                    <Grid container>
                        <Grid item xl={4} lg={4} md={4} xs={12} className={classes.sideBarContainer}>
                            {!matches && !matchesXs ? <HealthMenu /> : null}
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                            {/* child component */}
                            {children}
                            {/* child component */}
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default HealthContainer;
