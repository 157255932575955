import { Grid, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import pencilIcon from '@/assets/images/pencilIcon.png';
import summaryPersonalInfo from '@/assets/images/summaryPersonalInfo.svg';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useI18nCountries from '@/hooks/useI18nCountries';

import { formatDateByLanguage } from '@/utils/helpers';

import consentFormsStyles from '../ConsentFormsStyles';
import ListItem from './ListItem';

const SummaryPersonalInfoWithAddress = () => {
    const classes = consentFormsStyles();
    const history = useHistory();
    const { i18n } = useTranslation();

    /** Context */
    const {
        consentFormsContent: content,
        formData,
        isAllForms,
        allFormsPages,
        movePage,
        whichEnum,
        summaryEdit,
        setSummaryEdit,
    } = useContext(ConsentFormsContext);

    const countryList = useI18nCountries();

    const { firstName, middleName, lastName, dob, email, zipCode, street, city, country } = formData;

    /** Edit personal information */
    const handleEdit = () => {
        if (isAllForms) {
            setSummaryEdit(true); // enable edit state
            movePage(whichEnum.PREVIOUS, history);
            history.push(`${history.location.pathname}?step=personal-information`);
        } else {
            history.push(`${history.location.pathname}?step=personal-information`);
        }
    };

    return (
        <div className={classes.summaryPersonalInfo}>
            {/* Header */}
            <div className={classes.personalInfoHeader}>
                <img src={summaryPersonalInfo} className={classes.personalInfoIcon} />
                <Typography variant="body1" className={classes.shortHeading}>
                    {content ? content?.titles.personalInformation : 'Personal Information'}
                </Typography>
            </div>

            {/* Personal Information */}
            <Grid container className={clsx(classes.personalInfoData, classes.my10)}>
                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.fullName : 'Full name'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{`${firstName || '-'} ${lastName || '-'}`}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.dob : 'Date of birth'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{dob ? formatDateByLanguage(dob, i18n) : '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content?.heraklesContent?.emailSummary || 'E-Mail'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{email || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.streetAndNumber : 'Street & Number'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{street || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.zipCode : 'ZIP Code'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{zipCode || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.city : 'City'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{city || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData?.country : 'Country'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>
                            {typeof country === 'string' ? countryList[country] : country?.name || ''}
                        </ListItem>
                    </Grid>
                </Grid>
            </Grid>

            {/* Edit Icon */}
            <IconButton onClick={handleEdit} className={classes.editButton} disableRipple>
                <img src={pencilIcon} className={classes.editIcon} />
            </IconButton>
        </div>
    );
};

export default SummaryPersonalInfoWithAddress;
