import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { ContextProvider } from '@/context/ProjectContext';

import { insuranceApi } from '@/service/AnamnesisSectionAPI/AccountHealthAPI';
import { adressApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';

import { processAPIWithHeaders, timeout } from '@/utils/helpers';

import { useConfigStore } from '@/store';

/**
 *
 * @param {*} body
 * @param {*} password
 * @returns [loading, error, signUp]
 */
export const useVeryfyCode = () => {
    // Local States Variables
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    // Context Variables
    const { setuser, setusersession, setuseraccounts } = useContext(AuthContext);
    const { isVerificationError, setIsVerificationError } = useContext(ConsentFormsContext);
    const { languageGlobal } = useContext(ContextProvider);
    const { validUntil, insuranceCompany, insuranceId, insuranceNumber, insuranceStatus, insuranceType } =
        useContext(ContextProviderTwo);

    // Hooks
    const { i18n } = useTranslation();

    const signIn = async (body, password) => {
        try {
            const user = await Auth.signIn(body?.email, password);
            const userData = user.attributes;
            const { currentCustomer } = useConfigStore.getState();
            const customer_id = currentCustomer?.customer_id?.[0].text;

            const response = await processAPIWithHeaders('secure/users', 'POST', {
                body: {
                    id: user.username,
                    email: body?.email,
                    name: {
                        first: userData['custom:firstName'],
                        middle: userData['custom:middleName'],
                        last: userData['custom:lastName'],
                    },
                    gender: userData['custom:gender'],
                    date_of_birth: userData['custom:dob'],
                    title: userData['custom:title'],
                    phone: { number: userData['custom:phone'] },
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.signInUserSession.idToken.jwtToken,
                    customer_id: customer_id,
                },
            });

            const userAccounts = await processAPIWithHeaders(`secure/user/accounts/${user.username}`, 'GET', {
                headers: {
                    Authorization: user.signInUserSession.idToken.jwtToken,
                },
            });

            await timeout(1000);
            if (userAccounts && userAccounts.status) {
                const updateAccount = await processAPIWithHeaders(`secure/user/accounts/${user.username}`, 'POST', {
                    body: {
                        user_id: user.username,
                        privacy_policy_status: 'ACCEPTED',
                        terms_and_conditions_status: 'ACCEPTED',
                        use_of_analytics_status: 'UNKNOWN',
                        verification_status: 'VERIFIED',
                        locale: {
                            language: i18n.language,
                            country: 'DE',
                        },
                        status: 'ACTIVE',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                    },
                });
                setuseraccounts(updateAccount);
            } else {
                const createAccount = await processAPIWithHeaders(`secure/user/accounts/${user.username}`, 'POST', {
                    body: {
                        user_id: user.username,
                        privacy_policy_status: 'ACCEPTED',
                        terms_and_conditions_status: 'ACCEPTED',
                        use_of_analytics_status: 'UNKNOWN',
                        verification_status: 'VERIFIED',
                        locale: {
                            language: i18n.language,
                            country: 'DE',
                        },
                        status: 'ACTIVE',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                    },
                });
                setuseraccounts(createAccount);
            }

            setuser(response);
            setusersession(user.signInUserSession);

            const bodyTwo = {
                user_id: user?.signInUserSession.idToken.payload.sub,
                street: body?.street,
                zip: body?.zipCode,
                city: body?.city,
                country: {
                    code: body?.country?.code,
                    name: body?.country?.name,
                },
            };

            const isInsurancePrivate = insuranceType === 'PRIVATE';
            const isSelfPayer = insuranceType === 'SELFPAYER';

            const HEALTH_INSURANCE_API_BODY = {
                user_id: user?.signInUserSession.idToken.payload.sub,
                insurance_type: insuranceType?.toUpperCase(),
                ...(!isSelfPayer && {
                    insurance_id: insuranceId || '',
                }),
                ...(isInsurancePrivate && {
                    insurance_company: insuranceCompany,
                }),
            };

            const prom = new Promise.all(
                adressApi(user?.signInUserSession, bodyTwo),
                ...(insuranceType && [insuranceApi(user?.signInUserSession, HEALTH_INSURANCE_API_BODY)])
            );

            await prom;

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    // SignUp Function
    const verifyCode = async (user, code, body, password) => {
        try {
            if (code.length !== 6) {
                setIsVerificationError(true);
                return;
            }
            setLoading(true);
            setIsVerificationError(false);
            const result = await Auth.confirmSignUp(user.username, code);
            await signIn(body, password);
            if (result) setResponse(result);
            return result;
        } catch (err) {
            setLoading(false);
            setError(err.message);
            setIsVerificationError(true);
            return err.message;
        }
    };

    return { verifyCode: verifyCode, response: response, loading: loading, error: error };
};
