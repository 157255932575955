import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view';

import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import useFormSteps from '@/hooks/useFormSteps';

import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';

import { menuItems, trackStormlyAnamnesisSection, useQuery } from '@/utils/helpers';

import { useTrackingStore } from '@/store';

import LifeStyleAlcohol from './LifeStyleComponents/LifeStyleAlcohol';
import LifeStyleButtonComponent from './LifeStyleComponents/LifeStyleButtonComponent.jsx';
import LifeStyleDiet from './LifeStyleComponents/LifeStyleDiet';
import LifeStyleDrugs from './LifeStyleComponents/LifeStyleDrugs';
import LifeStyleOtherInfo from './LifeStyleComponents/LifeStyleOtherInfo.jsx';
import LifeStyleSmoke from './LifeStyleComponents/LifeStyleSmoke';
import LifeStyleSmoking from './LifeStyleComponents/LifeStyleSmoking';
import LifeStyleSports from './LifeStyleComponents/LifeStyleSports';
import { useStyles } from './LifeStyleComponents/LifeStyleStyles';
import LifeStyleYourSleep from './LifeStyleComponents/LifeStyleYourSleep.jsx';

const useStyless = makeStyles((theme) => ({
    topHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 500,
        fontSize: '21px',
        marginBottom: 20,
    },
    divider: { height: '2px', background: '#F5F5F5', border: 'none' },
}));

// reusable function to set prismic data in state
const setData = (mainData, setState, ansGroup, ansText, ansSelector) => {
    mainData[ansGroup].forEach((i) => {
        setState((prev) => [
            ...prev,
            {
                value: i[ansText][0].text,
                label: i[ansSelector],
            },
        ]);
    });
};

export default function LifeStyle({ mainData, user, usersession, allData, isFromHealthData = false }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const classess = useStyless();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const [fallAsleepData, setFallAsleepData] = useState([]);
    const [sleepProblems, setSleepProblem] = useState([]);
    const [snoreAnswer, setSnoreAnswer] = useState([]);
    const [grindAnswer, setGrindAnswer] = useState([]);
    const [restedAnswer, setRestedAnswer] = useState([]);

    // ------ Mental Health ------
    const [stressedAnswer, setStressedAnswer] = useState([]);
    const [moodAnswer, setMoodAnswer] = useState([]);
    const [energyLevelAnswer, setEnergyLevelAnswer] = useState([]);
    const [counter, setCounter] = useState(0);
    console.debug('prismicData1:', mainData);

    const messageRefTwo = useRef();
    useEffect(() => {
        console.debug('sergeriesArr12', counter);
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [counter]);

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    const query = useQuery();
    const queryStep = query.get('step');
    console.debug('🚀 ~ file: LifeStyle.jsx ~ line 92 ~ LifeStyle ~ query', queryStep);
    console.debug('isFromHealthData123', isFromHealthData);

    const {
        state: { userData },
    } = useContext(AuthContext);

    const {
        state: {
            step,
            save_changes_option,
            life_style,
            is_open_state,
            is_edit_overview,
            whichOneDrugs,
            life_style_context,
            whichOneDietTwo,
        },
        BackBtn,
        UpdateSteps,
        setLifeStyle,
        onSaveButton,
        onCancelButton,
        setIsOpenState,
        setReviewOption,
        setWhichOneDrugs,
    } = useContext(HealthProfileContextMain);
    const {
        multipleDiet,
        selectedCount,
        whichDiet,
        sports,
        value_,
        exercise,
        smoke,
        smokePerDay,
        smokeHowLong,
        alcohol,
        alcoholKind,
        alcoholPerWeek,
        drugs,
        setMultipleDiet,
        setSelectedCount,
        setWhichDiet,
        setSports,
        setValue_,
        setExercise,
        setSmoke,
        setSmokePerDay,
        setSmokeHowLong,
        setAlcohol,
        setAlcoholKind,
        setAlcoholPerWeek,
        setDrugs,
        setBody7,
        body7,
        allDataApi,
        setHasAccount,
        rSelect,
        setRSelect,
        nightSleep,
        setNightSleep,
        snoreSelect,
        setSnoreSelect,
        grindSelect,
        setGrindSelect,
        restedSelect,
        setRestedSelect,
        // Mental Health
        setStressSelect,
        stressSelect,
        setMoodSelect,
        moodSelect,
        setEnergySelect,
        energySelect,
        // other info value
        otherInfo,
        setOtherInfo,
        sleepHours,
        setSleepHours,
        whichOtherDietSubQuestion,
        setWhichOtherDietSubQuestion,
        setApiData,
        apiData,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    console.debug(
        '🚀 ~ file: LifeStyle.jsx ~ line 115 ~ LifeStyle ~ sleepHours',
        sleepHours,
        rSelect,
        nightSleep,
        snoreSelect,
        grindSelect,
        restedSelect,
        stressSelect,
        moodSelect,
        energySelect
    );
    // debugger;
    const [whichOneDrugsLocal, setWhichOneDrugsLocal] = useState([{ whichOne: '', howOftenDrugs: '' }]);

    const whichOneDrugsLocalRef = useRef([{ whichOne: '', howOftenDrugs: '' }]);

    const LifeStyleDrugsWhichOne = (isDrugs) => {
        console.debug('hello1233mub', isDrugs);
        const whichOneDrugsFunctional = Object.assign([], whichOneDrugsLocal);
        setWhichOneDrugs(whichOneDrugsLocal);
        // setWhichOneDrugsLocal_(whichOneDrugsLocal);
        lifeStyleData(isDrugs, whichOneDrugsFunctional);
    };

    const lifeStyleData = (isDrugs, whichOneDrugsFunctional) => {
        if (isDiabled()) {
            setNewFromTrue(true);
            setCounter(counter + 1);
            return;
        }
        setNewFromTrue(false);

        setLifeStyle({
            multipleDiet: multipleDiet,
            whichDiet: whichDiet,
            sports: sports,
            weekDays: value_,
            exercise: exercise,
            smoke: smoke,
            smokePerDay: smokePerDay,
            smokeHowLong: smokeHowLong,
            alcohol: alcohol,
            alcoholKind: alcoholKind,
            alcoholPerWeek: alcoholPerWeek,
            drugs: drugs,
            whichOneDrugsContext: whichOneDrugs,
            whichOtherDietSubQuestion: whichOtherDietSubQuestion,
        });
        LifeStyleAPI(isDrugs, whichOneDrugsFunctional);
        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);
            onSaveButton(history, is_edit_overview);
        }
    };

    const Cancel = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        setMultipleDiet(life_style.multipleDiet);
        setWhichDiet(life_style.whichDiet);
        setSports(life_style.sports);
        setValue_(life_style.weekDays);
        setExercise(life_style.exercise);
        setSmoke(life_style.smoke);
        setSmokePerDay(life_style.smokePerDay);
        setSmokeHowLong(life_style.smokeHowLong);
        setAlcohol(life_style.alcohol);
        setAlcoholKind(life_style.alcoholKind);
        setAlcoholPerWeek(life_style.alcoholPerWeek);
        setWhichOtherDietSubQuestion(life_style.whichOtherDietSubQuestion);
        setWhichOneDrugs(life_style.whichOneDrugsContext);
        setDrugs(life_style.drugs);
        onCancelButton(history); // life_style
        console.debug('lifeStyle1: Cancel', whichOneDrugsLocal);
    };

    console.debug('life_style12333:', life_style);

    const LifeStyleAPI = (isDrugs, whichOneDrugsFunctional) => {
        const user_id = user ? usersession?.idToken.payload.sub : '';

        const transformedArr = [];
        if (isDrugs && whichOneDrugsFunctional.length > 0) {
            for (let i = 0; i < whichOneDrugsFunctional.length; i++) {
                transformedArr.push({
                    type: whichOneDrugsFunctional[i].whichOne,
                    frequency: whichOneDrugsFunctional[i].howOftenDrugs,
                    frequency_type: 'month',
                });
            }
        }
        console.debug('transformedArr1', transformedArr);
        const body = {
            user_id: user_id,
            category: 'lifestyle',

            data: {
                diet: multipleDiet,
                diet_other: whichOtherDietSubQuestion,
                sports: {
                    active: sports === 'YES',
                    exercise_per_week: sports === 'NO' ? 0 : value_,
                    daily_exercise_type: exercise,
                },
                habits: {
                    smoke: {
                        active: smoke === 'YES',
                        packs_per_day: smokePerDay,
                        num_years: smokeHowLong,
                    },
                    alcohol: {
                        active: alcohol === 'YES',
                        type: alcoholKind,
                        drinks_per_week: alcoholPerWeek,
                    },
                    drugs: {
                        active: drugs === 'YES',
                        data: transformedArr,
                    },
                },
                // sleep: {
                //     hours_per_night: sleepHours,
                //     insomnia: {
                //         falling_asleep: rSelect.toLocaleUpperCase() === 'YES' ? true : false,
                //         staying_asleep: nightSleep.toLocaleUpperCase() === 'YES' ? true : false,
                //     },
                //     is_snorer:
                //         snoreSelect.toLocaleUpperCase() === 'YES'
                //             ? true
                //             : snoreSelect.toLocaleUpperCase() === 'NO'
                //             ? false
                //             : '',
                //     is_teeth_grinder:
                //         grindSelect.toLocaleUpperCase() === 'YES'
                //             ? true
                //             : grindSelect.toLocaleUpperCase() === 'NO'
                //             ? false
                //             : '',
                //     is_rested_waker: restedSelect.toLocaleUpperCase() === 'YES' ? true : false,
                // },
                // energy: {
                //     stressed_6_months: stressSelect,
                //     mood_6_months: moodSelect,
                //     energy_6_months: energySelect,
                // },
                // other_info: otherInfo,
            },
        };

        if (
            rSelect !== null ||
            nightSleep !== null ||
            snoreSelect !== null ||
            grindSelect !== null ||
            restedSelect !== null
        ) {
            body.data.sleep = {
                hours_per_night: sleepHours,
                insomnia: {
                    falling_asleep: !!(rSelect && rSelect.toLocaleUpperCase() === 'YES'),
                    staying_asleep: !!(nightSleep && nightSleep.toLocaleUpperCase() === 'YES'),
                },
                is_snorer:
                    snoreSelect && snoreSelect?.toLocaleUpperCase() === 'YES'
                        ? true
                        : snoreSelect.toLocaleUpperCase() === 'NO'
                          ? false
                          : '',
                is_teeth_grinder:
                    grindSelect && grindSelect?.toLocaleUpperCase() === 'YES'
                        ? true
                        : grindSelect.toLocaleUpperCase() === 'NO'
                          ? false
                          : '',
                is_rested_waker: restedSelect.toLocaleUpperCase() === 'YES',
            };
            body.data.energy = {
                stressed_6_months: stressSelect,
                mood_6_months: moodSelect,
                energy_6_months: energySelect,
            };
            body.data.other_info = otherInfo;
        }

        console.debug('life_style12333: BODY1', body.data);
        setBody7(body);
        user &&
            yourBodyAPI(usersession, body)
                .then((response) => setApiData(response?.data))
                .catch((error) => console.debug('error1', error));
    };

    useEffect(() => {
        console.debug('🚀 ~ file: LifeStyle.jsx:330 ~ useMemo ~ apiData');
        if (body7 && drugs === 'YES') {
            const { category_data } = apiData;
            console.debug('🚀 ~ file: LifeStyle.jsx:330 ~ useMemo ~ apiData', apiData, body7);
            const a = body7?.data.habits.drugs.data;
            const b = [];
            for (let i = 0; i < a.length; i++) {
                const aa = a[i];
                b.push({
                    whichOne: aa.type,
                    howOftenDrugs: aa.frequency,
                });
            }
            console.debug('🚀 ~ file: LifeStyle.jsx:330 ~ useMemo ~ apiData', b);
            if (whichOneDrugs[0]?.howOftenDrugs.length > 0) {
                setWhichOneDrugsLocal(b);
            }
        } else {
            setWhichOneDrugsLocal([{ whichOne: '', howOftenDrugs: '' }]);
        }
    }, []);

    console.debug(' hello1233: lifeStyle123', whichOneDrugsLocalRef.current, whichOneDrugsLocal);
    const lifestyleIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'lifestyle');
    useEffect(() => {
        console.debug('category22', allDataApi);
        if (user && allDataApi !== undefined && allDataApi !== null && body7 === null) {
            if (
                allDataApi?.category_data[lifestyleIndex] &&
                allDataApi?.category_data[lifestyleIndex].category === 'lifestyle'
            ) {
                const category2 = allDataApi?.category_data[lifestyleIndex].data;

                const arrangedData = [];
                if (category2?.habits.drugs?.active && category2?.habits.drugs?.data?.length > 0) {
                    for (let i = 0; i < category2?.habits.drugs.data.length; i++) {
                        arrangedData.push({
                            whichOne: category2?.habits.drugs?.data[i]?.type,
                            howOftenDrugs: category2?.habits.drugs?.data[i]?.frequency,
                        });
                    }
                }

                console.debug("category2?.insomnia?.falling_asleep ? 'Yes' : 'No'", category2?.is_snorer);
                if (category2?.diet.length === 0) {
                    setMultipleDiet(['NO PREFERENCES']);
                } else {
                    setMultipleDiet(category2?.diet);
                }
                setWhichOtherDietSubQuestion(category2.diet_other);
                setSports(category2.sports.active ? 'YES' : 'NO');
                setValue_(category2?.sports.exercise_per_week);
                setExercise(category2?.sports.daily_exercise_type);
                setSmoke(category2?.habits.smoke.active ? 'YES' : 'NO');
                setSmokePerDay(category2?.habits.smoke.packs_per_day);
                setSmokeHowLong(category2?.habits.smoke.num_years);
                setAlcohol(category2?.habits.alcohol.active ? 'YES' : 'NO');
                setAlcoholKind(category2?.habits.alcohol.type);
                setAlcoholPerWeek(category2?.habits.alcohol.drinks_per_week);
                setDrugs(category2?.habits.drugs.active ? 'YES' : 'NO');
                setWhichOneDrugsLocal(
                    arrangedData.length > 0 && arrangedData[0].whichOne
                        ? arrangedData
                        : [{ whichOne: '', howOftenDrugs: '' }]
                );
                console.debug('category2.sports.active', category2?.habits.drugs?.data);

                // setWhichOneDrugs(category2?.drugs.);
                if (isFromHealthData) {
                    setSleepHours(category2?.sleep && category2?.sleep.hours_per_night);
                    setRSelect(
                        category2?.sleep
                            ? category2?.sleep.insomnia?.falling_asleep
                                ? 'YES'
                                : !category2?.sleep.insomnia?.falling_asleep
                                  ? 'NO'
                                  : ''
                            : ''
                    );
                    setNightSleep(
                        category2?.sleep
                            ? category2?.sleep?.insomnia?.staying_asleep
                                ? 'YES'
                                : !category2?.sleep?.insomnia?.staying_asleep
                                  ? 'NO'
                                  : ''
                            : ''
                    );
                    setSnoreSelect(
                        category2?.sleep
                            ? category2?.sleep.is_snorer
                                ? 'YES'
                                : category2?.sleep.is_snorer === false
                                  ? 'NO'
                                  : ''
                            : ''
                    );
                    setGrindSelect(
                        category2?.sleep
                            ? category2?.sleep.is_teeth_grinder
                                ? 'YES'
                                : category2?.sleep.is_teeth_grinder === false
                                  ? 'NO'
                                  : ''
                            : ''
                    );
                    setRestedSelect(
                        category2?.sleep
                            ? category2?.sleep.is_rested_waker
                                ? 'YES'
                                : !category2?.sleep.is_rested_waker
                                  ? 'NO'
                                  : ''
                            : ''
                    );
                    // preselecting mental energy
                    setStressSelect(category2?.energy && category2?.energy.stressed_6_months);
                    setMoodSelect(category2?.energy && category2?.energy.mood_6_months);
                    setEnergySelect(category2?.energy && category2?.energy.energy_6_months);
                    setOtherInfo(category2?.other_info ? category2?.other_info : '');
                }
            }
        }
    }, [allDataApi]);

    useEffect(() => {
        if (Object.keys(mainData).length > 0) {
            setData(
                mainData,
                setFallAsleepData,
                '2_falling_asleep_answer_group',
                'yes_no_answer',
                'yes_no_answer_selector'
            );

            setData(mainData, setSleepProblem, '3_sleeping_answer_group', 'yes_no_answer', 'yes_no_selector');

            setData(mainData, setSnoreAnswer, '4_snore_answer_group', 'yes_no_answer', 'yes_no_selector');

            setData(mainData, setGrindAnswer, '5_grinding_answer_group', 'yes_no_answer', 'yes_no_answer_selector');

            setData(mainData, setRestedAnswer, '6_rested_answer_group', 'yes_no_answer', 'yes_no_answer_selector');

            // ------ Mental Health ------

            setData(mainData, setStressedAnswer, '1_stress_answers_group', 'stress_answer', 'stress_answer_selector');

            setData(mainData, setMoodAnswer, '2_mood_answers_group', 'mood_answer', 'mood_answer_selector');

            setData(
                mainData,
                setEnergyLevelAnswer,
                '3_energy_answers_group',
                'energy_answer',
                'energy_answer_selector'
            );
        }
    }, [mainData]);

    const isDiabled = () => {
        if (
            multipleDiet.length === 0 ||
            exercise === '' ||
            smoke === '' ||
            alcohol === '' ||
            drugs === '' ||
            (multipleDiet.includes('OTHER') && whichOtherDietSubQuestion === '') ||
            (drugs === 'YES' &&
                !(
                    whichOneDrugsLocal[0]?.howOftenDrugs?.length > 0 &&
                    whichOneDrugsLocal[0]?.whichOne?.length > 0 &&
                    Number.isInteger(parseInt(whichOneDrugsLocal[0]?.howOftenDrugs))
                ))
        ) {
            return 1;
        }
        return 0;
    };

    const allStates = [
        { name: 'multipleDiet', value: multipleDiet.length > 0 },
        { name: 'sports', value: !!sports },
        { name: 'exercise', value: !!exercise },
        { name: 'smoke', value: !!smoke },
        { name: 'alcohol', value: !!alcohol },
        { name: 'drugs', value: !!drugs },
        { name: 'sleepHours', value: !!sleepHours },
        { name: 'rSelect', value: !!rSelect },
        { name: 'nightSleep', value: !!nightSleep },
        { name: 'snoreSelect', value: !!snoreSelect },
        { name: 'grindSelect', value: !!grindSelect },
        { name: 'restedSelect', value: !!restedSelect },
        { name: 'stressSelect', value: !!stressSelect },
        { name: 'moodSelect', value: !!moodSelect },
        { name: 'energySelect', value: !!energySelect },
        { name: 'otherInfo', value: !!otherInfo },
    ];

    const whichIsNull = () => {
        const firstNull = allStates.find((state) => state.value === false);
        if (typeof firstNull === 'object') return firstNull.name;
    };

    useEffect(() => {
        if (isFromHealthData && userData) {
            const elementName = whichIsNull();
            const element = document.getElementById(elementName);
            scrollIntoView(element, {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [
        multipleDiet.length > 0,
        !sports,
        !exercise,
        !smoke,
        !alcohol,
        !drugs,
        !sleepHours,
        !rSelect,
        !nightSleep,
        !snoreSelect,
        !grindSelect,
        !restedSelect,
        !stressSelect,
        !moodSelect,
        !energySelect,
        !otherInfo,
    ]);

    const multipleDisabled = () => {
        return !!(multipleDiet.length === 0 || (multipleDiet.includes('OTHER') && whichOtherDietSubQuestion === ''));
    };

    const drugsDisabled = () => {
        return (
            drugs === '' ||
            (drugs === 'YES' &&
                !(
                    whichOneDrugsLocal[0]?.howOftenDrugs?.length > 0 &&
                    whichOneDrugsLocal[0]?.whichOne?.length > 0 &&
                    Number.isInteger(parseInt(whichOneDrugsLocal[0]?.howOftenDrugs))
                ))
        );
    };

    return (
        <div className={classes.contentContainerBody}>
            {/* main Part */}
            <div className={classes.fixedQuestion}>
                {isFromHealthData && (
                    <Typography className={classess.topHeading}>{mainData.your_diet[0].text}</Typography>
                )}

                <div
                    className={
                        (isFromHealthData || newFromTrue) && multipleDisabled() ? classes.highlight : classes.main
                    }
                    ref={multipleDisabled() ? messageRefTwo : null}
                    id={whichIsNull() === 'multipleDiet' ? 'multipleDiet' : null}
                >
                    <LifeStyleDiet mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>
                {/* divider */}
                {isFromHealthData && (
                    <div style={{ marginBlock: 30 }}>
                        <hr className={classess.divider} />
                    </div>
                )}
                {/* divider */}

                {isFromHealthData && (
                    <Typography className={classess.topHeading}>{mainData.sports_physical_activity[0].text}</Typography>
                )}

                <div
                    className={(isFromHealthData || newFromTrue) && sports === '' ? classes.highlight : classes.main}
                    ref={!multipleDisabled() && sports.length === 0 ? messageRefTwo : null}
                    id={whichIsNull() === 'sports' ? 'sports' : null}
                >
                    <LifeStyleSports mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>
                {/* exercise start */}
                <div
                    className={(isFromHealthData || newFromTrue) && !exercise ? classes.highlight : classes.main}
                    ref={!multipleDisabled() && sports !== '' && !exercise ? messageRefTwo : null}
                    id={whichIsNull() === 'exersice' ? 'exersice' : null}
                >
                    <LifeStyleSmoke mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>
                {/* exercise end */}

                {/* divider */}
                {isFromHealthData && (
                    <div style={{ marginBlock: 30 }}>
                        <hr className={classess.divider} />
                    </div>
                )}
                {/* divider */}

                {isFromHealthData && (
                    <Typography className={classess.topHeading}>{mainData.other_habits[0].text}</Typography>
                )}

                {/* Smoke */}
                <div
                    ref={!multipleDisabled() && sports !== '' && exercise && !smoke ? messageRefTwo : null}
                    className={(isFromHealthData || newFromTrue) && !smoke ? classes.highlight : classes.main}
                    id={whichIsNull() === 'smoke' ? 'smoke' : null}
                >
                    <LifeStyleSmoking mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>
                {/* Smoke */}
                {/* next */}
                <div
                    ref={!multipleDisabled() && sports !== '' && exercise && smoke && !alcohol ? messageRefTwo : null}
                    className={(isFromHealthData || newFromTrue) && !alcohol ? classes.highlight : classes.main}
                    id={whichIsNull() === 'alcohol' ? 'alcohol' : null}
                >
                    <LifeStyleAlcohol mainData={mainData} allData={allData} isFromHealthData={isFromHealthData} />
                </div>

                {/* drugs */}
                <div
                    className={(isFromHealthData || newFromTrue) && drugsDisabled() ? classes.highlight : classes.main}
                    ref={
                        !multipleDisabled() && sports !== '' && exercise && smoke && alcohol && drugsDisabled()
                            ? messageRefTwo
                            : null
                    }
                    id={whichIsNull() === 'drugs' ? 'drugs' : null}
                >
                    <LifeStyleDrugs
                        mainData={mainData}
                        isFromHealthData={isFromHealthData}
                        whichOneDrugsLocal={whichOneDrugsLocal}
                        setWhichOneDrugsLocal={setWhichOneDrugsLocal}
                    />
                </div>
                {/* divider */}
                {isFromHealthData && (
                    <hr className={classess.divider} style={{ marginBlock: isFromHealthData ? 30 : null }} />
                )}
                {/* divider */}

                {/* your sleep start */}

                {isFromHealthData && (
                    <Typography className={classess.topHeading}>{mainData.sleep_group_title[0].text}</Typography>
                )}

                {isFromHealthData ? (
                    <div
                        id={whichIsNull() === 'sleepHours' ? 'sleepHours' : null}
                        className={isFromHealthData && !sleepHours ? classes.highlight : classes.main}
                    >
                        <LifeStyleYourSleep mainData={mainData} />
                    </div>
                ) : null}

                {/* falling asleep */}
                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'rSelect' ? 'rSelect' : null}
                        className={isFromHealthData && !rSelect ? classes.highlight : classes.main}
                    >
                        <LifeStyleButtonComponent
                            setSelected={setRSelect}
                            heading={mainData?.['2_falling_asleep_question'][0].text}
                            mainData={mainData}
                            data={fallAsleepData}
                            selected={rSelect}
                        />
                    </div>
                )}
                {/* falling asleep */}

                {/* falling asleep */}
                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'nightSleep' ? 'nightSleep' : null}
                        className={isFromHealthData && !nightSleep ? classes.highlight : classes.main}
                    >
                        <LifeStyleButtonComponent
                            setSelected={setNightSleep}
                            heading={mainData?.['3_sleeping_question'][0].text}
                            mainData={mainData}
                            data={sleepProblems}
                            selected={nightSleep}
                        />
                    </div>
                )}
                {/* falling asleep */}

                {/* snoring question */}
                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'snoreSelect' ? 'snoreSelect' : null}
                        className={isFromHealthData && !snoreSelect ? classes.highlight : classes.main}
                    >
                        <LifeStyleButtonComponent
                            setSelected={setSnoreSelect}
                            heading={mainData?.['4_snoring_question'][0].text}
                            mainData={mainData}
                            data={snoreAnswer}
                            selected={snoreSelect}
                        />
                    </div>
                )}
                {/* snoring question */}

                {/* grinding question */}
                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'grindSelect' ? 'grindSelect' : null}
                        className={isFromHealthData && !grindSelect ? classes.highlight : classes.main}
                    >
                        <LifeStyleButtonComponent
                            setSelected={setGrindSelect}
                            heading={mainData?.['5_grinding_question'][0].text}
                            mainData={mainData}
                            data={grindAnswer}
                            selected={grindSelect}
                        />
                    </div>
                )}
                {/* grinding question */}

                {/* rested answer */}
                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'restedSelect' ? 'restedSelect' : null}
                        className={isFromHealthData && !restedSelect ? classes.highlight : classes.main}
                    >
                        <LifeStyleButtonComponent
                            setSelected={setRestedSelect}
                            heading={mainData?.['6_rested_question'][0].text}
                            mainData={mainData}
                            data={restedAnswer}
                            selected={restedSelect}
                        />
                    </div>
                )}

                {/* divider */}
                {isFromHealthData && (
                    <hr className={classess.divider} style={{ marginTop: isFromHealthData ? 30 : null }} />
                )}
                {/* divider */}
                {/* rested answer */}
                {/* ====== mental health and energy ====== */}
                {isFromHealthData && (
                    <>
                        <div style={{ marginTop: 30 }}>
                            <Typography className={classess.topHeading}>
                                {mainData.mental_health_group_title[0].text}
                            </Typography>
                        </div>

                        {/* rested answer */}
                        <div
                            id={whichIsNull() === 'stressSelect' ? 'stressSelect' : null}
                            className={isFromHealthData && !stressSelect ? classes.highlight : classes.main}
                        >
                            <LifeStyleButtonComponent
                                setSelected={setStressSelect}
                                heading={mainData?.['1_stress_question'][0].text}
                                mainData={mainData}
                                data={stressedAnswer}
                                selected={stressSelect}
                            />
                        </div>
                        {/* rested answer */}

                        {/* mood answer */}
                        <div
                            id={whichIsNull() === 'moodSelect' ? 'moodSelect' : null}
                            className={isFromHealthData && !moodSelect ? classes.highlight : classes.main}
                        >
                            <LifeStyleButtonComponent
                                setSelected={setMoodSelect}
                                heading={mainData?.['2_mood_question'][0].text}
                                mainData={mainData}
                                data={moodAnswer}
                                selected={moodSelect}
                            />
                        </div>
                        {/* mood answer */}

                        {/* energy level answer */}
                        <div
                            id={whichIsNull() === 'energySelect' ? 'energySelect' : null}
                            className={isFromHealthData && !energySelect ? classes.highlight : classes.main}
                        >
                            <LifeStyleButtonComponent
                                setSelected={setEnergySelect}
                                heading={mainData?.['3_energy_question'][0].text}
                                mainData={mainData}
                                data={energyLevelAnswer}
                                selected={energySelect}
                            />
                        </div>
                    </>
                )}
                {/* energy level answer end */}
                {/* ====== mental health and energy ====== */}
                {/* your sleep end */}

                {/* divider */}
                {isFromHealthData && <hr className={classess.divider} style={{ marginTop: 30 }} />}
                {/* divider */}

                {/* LifeStyleOtherInfo starts */}
                {isFromHealthData && (
                    <>
                        <div style={{ marginTop: 30 }}>
                            <Typography className={classess.topHeading}>
                                {mainData.other_info_group_title[0].text}
                            </Typography>
                        </div>
                        <div
                            id={whichIsNull() === 'otherInfo' ? 'otherInfo' : null}
                            className={isFromHealthData && !otherInfo ? classes.highlight : classes.main}
                        >
                            <LifeStyleOtherInfo mainData={mainData} />
                        </div>
                    </>
                )}
                {/* LifeStyleOtherInfo ends */}

                <div
                    className=""
                    style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                BackBtn(step, history, user, isFromHealthData);
                            }}
                            fullWidth={matches}
                        />
                    ) : (
                        <OutlinedButton
                            text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                            onPress={() => {
                                Cancel();
                            }}
                            fullWidth={matches}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            // disabled={isDiabled()}
                            onPress={() => {
                                if (drugs === 'YES') {
                                    const isDrugs = true;
                                    LifeStyleDrugsWhichOne(isDrugs);
                                    return;
                                }
                                lifeStyleData();
                            }}
                            marginBottom={matches && 20}
                            fullWidth={matches}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            // disabled={isDiabled()}
                            onPress={() => {
                                if (is_open_state) {
                                    setIsOpenState(false);
                                }
                                if (drugs === 'YES') {
                                    const isDrugs = true;
                                    LifeStyleDrugsWhichOne(isDrugs);
                                    return;
                                }
                                lifeStyleData();
                            }}
                            marginBottom={matches && 20}
                            fullWidth={matches}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
