import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { HealthInsurance } from '@/components/Search/SearchBar';

import { dayjs } from '@/utils/dayjsSetup';

import { useConfigStore, useCookiesStore, useTrackingStore } from '@/store';

export const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const BASE_API_URL_FOR_PRACTITIONERS = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;

export const CURRENT_ENV = import.meta.env.VITE_ENVIRONMENT;

export async function processAPI(endpoint, requestMethod, opts) {
    const { method, body } = { method: requestMethod, body: null, ...opts };

    const res = await fetch(`${BASE_API_URL}/${endpoint}`, {
        method: method,
        ...(body && { body: JSON.stringify(body) }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.json();
}

export async function processAPIWithHeaders(endpoint, requestMethod, opts) {
    const { method, body, headers } = {
        method: requestMethod,
        body: null,
        headers: null,
        ...opts,
    };

    const res = await fetch(`${BASE_API_URL}/${endpoint}`, {
        method: method,
        ...(body && { body: JSON.stringify(body) }),
        ...(headers && { headers: headers }),
    });

    return res.json();
}

export async function processAPIWithHeadersForPractitioners(endpoint, requestMethod, opts) {
    const { method, body, headers } = {
        method: requestMethod,
        body: null,
        headers: null,
        ...opts,
    };

    const res = await fetch(`${BASE_API_URL_FOR_PRACTITIONERS}/${endpoint}`, {
        method: method,
        ...(body && { body: JSON.stringify(body) }),
        ...(headers && { headers: headers }),
    });

    return res.json();
}

export const fetcher = (url, token) => axios.get(url).then((res) => res.data);

export const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
};

export const emailExists = async (email = '', setError = () => {}, errorMessage = '') => {
    const res = await Auth.signIn(email, '123')
        .then(() => {
            setError('');
        })
        .catch((error) => {
            const { code } = error;
            setError('');
            if (code === 'NotAuthorizedException') {
                setError(errorMessage);
            }
        });
    return res;
};

export const callbackEmail = (emailExists, text, { isFetching, setIsFetching }) => {
    if (isFetching) return;

    setIsFetching(true);
    emailExists(text)
        .then(() => {
            setIsFetching(false);
        })
        .catch((error) => {
            console.error(error);
            setIsFetching(false);
        });
};

export const getISODate = (date, languageGlobal) => {
    console.debug('FValidUntill123 date', date);

    const dateArr = date.split(languageGlobal === 'en' ? '/' : '.');

    console.debug('FValidUntill123', date, languageGlobal, dateArr);

    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
};

export const getISODateMonth = (date, languageGlobal) => {
    const dateArr = date.split(languageGlobal === 'en' ? '/' : '.');
    console.debug('FValidUntill123', date, languageGlobal, dateArr);
    return `${dateArr[1]}-${dateArr[0]}`;
};

export const getLocalDateMonth = (date, languageGlobal) => {
    return date.replaceAll(languageGlobal === 'en' && '-', '/').replaceAll(languageGlobal === 'de' && '-', '.');
};

/**
 * Format Date
 * e.g. formats date from "2022-02-02" to "02/02/2022"
 */
export const formatUserRawDate = (dob, language) => {
    const seperator = language === 'en' ? '/' : '.';
    return dob?.split('-').reverse().join(seperator);
};

export const formatDate = (date, format) => {
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = `0${month}`;
    }
    let day = date.getDate();
    if (day < 10) {
        day = `0${day}`;
    }
    const map = {
        mm: month,
        dd: day,
        yy: date.getFullYear().toString().slice(-2),
        full: date.getFullYear(),
    };

    console.debug(map);

    return format.replace(/mm|dd|yy|full/gi, (matched) => map[matched]);
};

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

// Format Date
// e.g. formats date from "2022-10-28T06:53:16.143Z" to "27 May 2022"

export const formatRawDate = (date, languageGlobal) => {
    const months = {
        1: languageGlobal === 'en' ? 'January' : 'Januar',
        2: languageGlobal === 'en' ? 'February' : 'Februar',
        3: languageGlobal === 'en' ? 'March' : 'März',
        4: languageGlobal === 'en' ? 'April' : 'April',
        5: languageGlobal === 'en' ? 'May' : 'Mai',
        6: languageGlobal === 'en' ? 'June' : 'Juni',
        7: languageGlobal === 'en' ? 'July' : 'Juli',
        8: languageGlobal === 'en' ? 'August' : 'August',
        9: languageGlobal === 'en' ? 'September' : 'September',
        10: languageGlobal === 'en' ? 'October' : 'Oktober',
        11: languageGlobal === 'en' ? 'November' : 'November',
        12: languageGlobal === 'en' ? 'December' : 'Dezember',
    };
    const dateObj = new Date(date);
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return `${day} ${months[month]} ${year}`;
};

export const anamnesisDateFormat = (date, languageGlobal) => {
    if (date.split(languageGlobal === 'en' ? '/' : '.').length > 0) {
        console.debug(
            'date1234',
            `${date.split(languageGlobal === 'en' ? '/' : '.')[0]} - ${
                date.split(languageGlobal === 'en' ? '/' : '.')[1]
            }`,
            date
        );
        return `${date.split(languageGlobal === 'en' ? '/' : '.')[0]}-${
            date.split(languageGlobal === 'en' ? '/' : '.')[1]
        }`;
    }
};

export const anamnesisRawDate = (date, languageGlobal) => {
    const monthsObj = {
        '01': languageGlobal === 'en' ? 'January' : 'Januar',
        '02': languageGlobal === 'en' ? 'February' : 'Februar',
        '03': languageGlobal === 'en' ? 'March' : 'März',
        '04': languageGlobal === 'en' ? 'April' : 'April',
        '05': languageGlobal === 'en' ? 'May' : 'Mai',
        '06': languageGlobal === 'en' ? 'June' : 'Juni',
        '07': languageGlobal === 'en' ? 'July' : 'Juli',
        '08': languageGlobal === 'en' ? 'August' : 'August',
        '09': languageGlobal === 'en' ? 'September' : 'September',
        10: languageGlobal === 'en' ? 'October' : 'Oktober',
        11: languageGlobal === 'en' ? 'November' : 'November',
        12: languageGlobal === 'en' ? 'December' : 'Dezember',
    };
    console.debug('date1234', date, monthsObj);
    return monthsObj[date];
};

// global languages
export const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

// health overview menu items
export const menuSteps = [
    'Personal Information',
    'Your Body',
    'Pre-existing Conditions',
    'Allergies and Reactions',
    'Medications',
    'Family',
    'Lifestyle',
];

export const JourneyType = {
    SEARCH_BOOK: 'search-book',
    PROFESSIONAL_DETAILS: 'professional-details',
};

export const AppointmentStatus = {
    PENDING_BOOKING: 'PENDING_BOOKING',
    BOOKED: 'BOOKED',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED',
    DECLINED: 'DECLINED',
};

export const menuItems = () => {
    // HealthData PathNames
    const stepsPathname = [];
    menuSteps.forEach((step) => {
        stepsPathname.push(step.trim().toLowerCase().replaceAll(' ', '-'));
    });
    return stepsPathname;
};

// convert string case to titleCase
export const titleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
};

// BMI Calculator Function
export const calculateBMI = (weight /** Weight will be in KGs */, height /** Height will be in CM */) => {
    const heightInMeter = height / 100;
    const heightSquare = heightInMeter ** 2;
    const bmi = weight / heightSquare;
    return bmi === Infinity ? 0 : bmi.toFixed(2);
};

export const formatDateForTable = (date) => {
    return dayjs(date).format('DD.MM.YYYY');
};

export const trackStormlyEvent = (eventName, props) => {
    if (useCookiesStore.getState().analyticsCookies && window.stormly) {
        window.stormly('event', eventName, props);
    }
};

export const trackStormlyUserProperty = (props) => {
    if (useCookiesStore.getState().analyticsCookies && window.stormly) {
        window.stormly('set', props);
    }
};

export const trackStormlyAnamnesisSection = () => {
    const section = useTrackingStore.getState().currentAnamnesisSection;
    const completedSections = useTrackingStore.getState().completedAnamnesisSections;

    if (!useCookiesStore.getState().analyticsCookies || completedSections[section]) {
        return;
    }

    trackStormlyEvent('anamnesisSectionFinished', {
        sectionName: section,
        time: Math.floor((Date.now() - useTrackingStore.getState().anamnesisStarted) / 1000),
    });

    const newCompletedSections = { ...completedSections };
    newCompletedSections[section] = true;

    useTrackingStore.setState({ completedAnamnesisSections: newCompletedSections });
};

/** Get object key by its value */
export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

//* * Function for prismic text array */
export const getText = (data) => data?.[0]?.text;

/** Format date by language  */
export const formatDateByLanguage = (date = '2000-02-02', i18n) => {
    const lan = languageMap[i18n.language];
    /** Current language */
    const isEnglish = lan === 'en-us';
    const rawDate = String(date).replaceAll(isEnglish ? '/' : '.', '-'); // Typecast date to string
    const format = isEnglish ? 'DD/MM/YYYY' : 'DD.MM.YYYY';
    return dayjs(rawDate, 'DD-MM-YYYY').format(format);
};

export const handleContinueWithAccountStep = (history, user, link) => {
    if (user) history.push(link);
    else history.push(`/login?redirectTo=${link}`);
};

export const handleContinueAsGuestStep = (history, link) => {
    history.push(link);
};

export const prismicArrayValues = (mainDataArray) => {
    const newArray = [];
    mainDataArray.forEach((e) => {
        const newObj = {};
        for (const key of Object.keys(e)) {
            if (typeof e[key] === 'string' || e[key] instanceof String) {
                newObj.api_value = e[key];
            } else {
                newObj.value = e[key][0].text;
            }
        }
        newArray.push(newObj);
    });
    return newArray;
};

/**
 * Move array elements to start of array
 *
 * @param {Array} array
 * @param {Array} elements
 * @returns new array
 */
export const moveElementsToStart = (array = [], elements = []) => {
    const result = [];
    const elementSet = new Set(elements);

    for (let i = 0; i < array.length; i++) {
        if (elementSet.has(array[i])) {
            result.push(array[i]);
        }
    }

    for (let i = 0; i < array.length; i++) {
        if (!elementSet.has(array[i])) {
            result.push(array[i]);
        }
    }

    return result;
};

/**
 * Function to handle date of birth field validation (can be used as onChange, onBlur and onClick function for DateTextField Type DOB).
 *
 * @param {string} dob  Date to validate
 * @param {string} languageGlobal Current selected language
 * @return {boolean} True if date is valid and false otherwise
 */
export const validateDOB = (dob = '', languageGlobal = 'en-us') => {
    const formatError = (dob) => {
        const year = dayjs().diff(dayjs(dob, 'YYYY-MM-DD'), 'years');
        const month = dayjs().diff(dayjs(dob, 'YYYY-MM-DD'), 'months');
        const date = dayjs().diff(dayjs(dob, 'YYYY-MM-DD'), 'days');
        const year1900 = dayjs().diff(dayjs('1900-01-01', 'YYYY-MM-DD'), 'days');

        if ((Math.sign(date) === -1 || date === 0) && month === 0 && year === 0) return false;
        if (isNaN(year) || date > year1900 || Math.sign(year) === -1) return false;
        return dayjs(dob, 'YYYY-MM-DD', true).isValid();
    };

    let formattedDate;
    if (dob.length >= 10) {
        formattedDate = getISODate(dob, languageGlobal);
    }

    const returnValue = formatError(formattedDate);

    if (dob === '') return false;
    return returnValue;
};

/**
 *
 *
 * @param {string} date Date to validate
 * @param {string} languageGlobal Current selected language
 * @returns {boolean} True if date is valid and false otherwise
 */
export const validateInsurance = (date = '', languageGlobal = 'en-us') => {
    const formatError = (date) => {
        const year = dayjs().diff(dayjs(date, 'YYYY-MM-DD'), 'years');
        const month = dayjs().diff(dayjs(date, 'YYYY-MM-DD'), 'months');
        const d = dayjs().diff(dayjs(date, 'YYYY-MM-DD'), 'days');
        const year3000 = dayjs().diff(dayjs('3001-01-01', 'YYYY-MM-DD'), 'years');

        if (Math.sign(d) === 1 && month === 0 && year === 0) {
            return false;
        }

        return !(isNaN(year) || Math.abs(year) > Math.abs(year3000) || Math.sign(year) === 1 || Math.sign(date) === 1);
    };

    let formattedDate;
    if (date.length >= 10) {
        formattedDate = getISODate(date, languageGlobal);
    }

    const returnValue = formatError(formattedDate);

    if (date === '') return false;

    if (!dayjs(date, languageGlobal === 'en' ? 'DD/MM/YYYY' : 'DD.MM.YYYY', true).isValid()) return false;

    return returnValue;
};

/**
 * SignOut logged in user or if not logged in replace page to provided route.
 *
 * @param {object} user
 * @param {function} history
 * @param {string} redirectPath
 */
export const signOut = async (user = null, history = () => {}, redirectPath = '/') => {
    try {
        if (user) await Auth.signOut();

        if (redirectPath) history.replace(redirectPath);

        window.location.reload(); // This makes sure previously input forms data is cleared
    } catch (error) {
        console.error(error);
    }
};

export const textToBase64Image = (text, width, height) => {
    // canvas
    const canvas = document.createElement('canvas');
    canvas.width = width || 100;
    canvas.height = height || 100;

    // context
    const context = canvas.getContext('2d');
    context.font = '40px TheDoctor';

    // settings
    const textWidth = context.measureText(text).width;
    const canvasWidth = context.canvas.width;
    const canvasHeight = context.canvas.height;

    // axes
    const x = (canvasWidth - textWidth) / 2;
    const y = (canvasHeight + 30) / 2 - 30 / 2;

    context.fillText(text, x, y);

    return canvas.toDataURL('image/png');
};

/**
 * Function to check whether the value is a Base64 encoded string or not.
 *
 * @param {string} value base64 encoded string or any string text
 * @returns {boolean} true if value base64 encoded string or false value is not base64 encoded
 */
export const isBase64 = (value) => {
    const base64Regex = /^data:(.*?);base64,([a-zA-Z0-9+/=]+)$/;
    return base64Regex.test(value);
};

/**
 * Function to check if allFormsPages contains the specific form or not.
 *
 * @param {string[]} allFormsPages allForms pages
 * @param {string} form form to check
 * @returns {boolean} 'true' if `allForms` contain `form` valid, 'false' otherwise.
 */
export const isAllFormsContain = (allFormsPages = [], form = '') => {
    if (allFormsPages.length <= 0) return false;
    const isFormsContain = allFormsPages.some((f) => f.route.includes(form));
    return isFormsContain;
};

/**
 * Function to replace date separator by currenr global language.
 * @param {string | Date} date to be modified
 * @param {string} lan current language
 * @returns {string} modified date string
 */
export const replaceDateSeparator = (date = '', lan = 'en') => {
    switch (lan) {
        case 'en':
            return date.replaceAll('.', '/');
        case 'de':
            return date.replaceAll('/', '.');
        default:
            return date.replaceAll('.', '/');
    }
};

export const isProcessedPrismicValues = (objectToRender, prismicData) => {
    return Object.entries(objectToRender).reduce((result, [key, value]) => {
        if (value && value[0]?.text && !value[1]?.text) {
            result[key] = value[0].text;
        } else {
            result[key] = value;
            if (key === 'privacy_policy_link') {
                result.privacy_policy_link = prismicData.filter((data) => data.id === value.id);
            }
        }
        return result;
    }, {});
};

export const unsubscribeGuest = async (userId, code) => {
    try {
        await axios.post(
            `${import.meta.env.VITE_API_PROTOCOL}://${
                import.meta.env.VITE_API_DOMAIN
            }/public/user/accounts/guest/unsubscribe?user_id=${userId}&code=${code}`,
            null,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const registerUser = async (registerData, token) => {
    try {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        const response = await axios.post(
            `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}/secure/users/register`,
            registerData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                    customer_id: customer_id,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const bookAppointment = async (userId, bookAppointmentData, token) => {
    try {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        const response = await axios.post(
            `${import.meta.env.VITE_API_PROTOCOL}://${
                import.meta.env.VITE_API_DOMAIN
            }/secure/user/appointments/book?user_id=${userId}`,
            bookAppointmentData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                    customer_id: customer_id,
                },
            }
        );

        if (response.data) return [true, response.data.id, null];
        return [false, '', 'Unknown error'];
    } catch (error) {
        console.debug(error);
        return [false, '', error.response?.data?.error ?? ''];
    }
};

export const fetchAvailableProfessionals = async (
    location_key,
    professional_type_key,
    appointment_type_keys,
    insurance_type_key,
    journey_type
) => {
    try {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;

        const queryParams = new URLSearchParams({
            location_key: location_key,
            professional_type_key: professional_type_key,
            appointment_type_keys: appointment_type_keys,
            insurance_type_key: insurance_type_key,
            journey_type: journey_type,
        }).toString();

        const url = new URL(`${BASE_API_URL_FOR_PRACTITIONERS}/public/users/availability/search`);
        url.search = queryParams;

        const response = await axios.get(url.toString(), {
            headers: {
                'Content-Type': 'application/json',
                customer_id: customer_id,
            },
        });

        return response.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getSharedDocuments = async ({ userId }) => {
    try {
        const authUser = await Auth.currentAuthenticatedUser();
        const user = await authUser.signInUserSession;
        const token = user?.idToken.jwtToken;
        const API_URL = `${BASE_API_URL}/secure/user/documents/shared?user_id=${userId}`;
        const res = await axios.get(API_URL, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return res.data;
    } catch (error) {
        console.debug('Error:', error);
    }
};

export const createQuestionObj = (title, label, questionKey, properties = {}) => {
    return {
        title: [
            {
                text: title,
            },
        ],
        label: [
            {
                text: label,
            },
        ],
        question_key: [
            {
                text: questionKey,
            },
        ],
        ...properties,
    };
};

export const getConsentTreatments = ({
    selectedTreatments,
    treatments,
    selectedLocation,
    allDocuments,
    selectedHealthInsurance,
}) => {
    const isConsentActive = (consentTreatments, insuranceType) => {
        const activeStatus = {
            public: consentTreatments.is_active_for_public_insurance,
            private: consentTreatments.is_active_for_private_insurance,
            self_payer: consentTreatments.is_active_for_self_payer,
        };
        return activeStatus[insuranceType] ?? false;
    };

    const formatConsentTreatment = (treatment, consentTreatments) => ({
        treatmentKey: treatment.key[0].text,
        treatmentName: treatment.name[0].text,
        title: consentTreatments.title[0].text,
        infoText: consentTreatments.info_text[0].text,
    });

    const result = [];
    for (const treatmentKey in selectedTreatments) {
        const treatment = treatments[selectedLocation]?.[treatmentKey];
        const consentText = allDocuments[treatment?.consent_text?.id];

        if (!consentText || !isConsentActive(consentText, selectedHealthInsurance)) {
            continue;
        }
        result.push(formatConsentTreatment(treatment, consentText));
    }

    return result;
};

const professionalTypes = {
    en: {
        DOCTOR: ['Doctor', 'Doctors', 'doctor', 'doctors'],
        THERAPIST: ['Therapist', 'Therapists', 'therapist', 'therapists'],
        ALTERNATIVE_PRACTITIONER: [
            'Alternative practitioner',
            'Alternative practitioners',
            'alternative practitioner',
            'alternative practitioners',
        ],
        COSMETICIAN: ['Cosmetician', 'Cosmeticians', 'cosmetician', 'cosmeticians'],
    },
    de: {
        DOCTOR: ['Arzt', 'Ärztin', 'Ärzt:innen', 'Ärzt:in'],
        THERAPIST: ['Therapeut', 'Therapeutin', 'Therapeut:innen', 'Therapeut:in'],
        ALTERNATIVE_PRACTITIONER: ['Heilpraktiker', 'Heilpraktikerin', 'Heilpratktiker:innen', 'Heilpraktiker:in'],
        COSMETICIAN: ['Kosmetiker', 'Kosmetikerin', 'Kosmetiker:innen', 'Kosmetiker:in'],
    },
};

export const replaceProfessionalType = ({ text, language }) => {
    const { currentCustomer } = useConfigStore.getState();
    const professional_type = currentCustomer?.professional_type ?? 'DOCTOR';

    if (professional_type === 'DOCTOR') return text;

    const replace = professionalTypes[language][professional_type];
    const search = professionalTypes[language].DOCTOR;

    let editedText = text;
    replace.forEach((item, index) => {
        editedText = editedText.replace(search[index], item);
    });

    return editedText;
};

export const getInsuranceAvailability = (treatmentsArray) => {
    const insuranceAvailability = {
        [HealthInsurance.PUBLIC]: false,
        [HealthInsurance.PRIVATE]: false,
        [HealthInsurance.SELF_PAYER]: false,
    };

    treatmentsArray.some((treatment) => {
        if (treatment.public_insurance) insuranceAvailability[HealthInsurance.PUBLIC] = true;
        if (treatment.private_insurance) insuranceAvailability[HealthInsurance.PRIVATE] = true;
        if (treatment.self_payer) insuranceAvailability[HealthInsurance.SELF_PAYER] = true;

        return (
            insuranceAvailability[HealthInsurance.PUBLIC] &&
            insuranceAvailability[HealthInsurance.PRIVATE] &&
            insuranceAvailability[HealthInsurance.SELF_PAYER]
        );
    });

    return insuranceAvailability;
};

export const isEternoDomain = () => window.location.hostname.includes(import.meta.env.VITE_ETERNO_DOMAIN);

export const isCorrectWhiteLabelDomain = (domain) => window.location.hostname.includes(domain);

export const isZeroDomain = () => window.location.hostname.includes('mein-zeroportal.de');

export const isLocalEnvironment = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export const isDevEnvironment = () => {
    return import.meta.env.VITE_ENVIRONMENT === 'dev';
};

export const isProdEnvironment = () => {
    return import.meta.env.VITE_ENVIRONMENT === 'prod';
};

export const redirectToEternoCloud = () => window.open('https://www.eterno.cloud/', '_self');

export const redirectToZero = () => window.open('https://patients.mein-zeroportal.de/customer/zero-praxen', '_self');

export const generateUniqueRandomArray = (length, min, max) => {
    const uniqueArray = [];
    const generatedNumbers = {};

    while (uniqueArray.length < length) {
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        if (!generatedNumbers[randomNumber]) {
            uniqueArray.push(randomNumber);
            generatedNumbers[randomNumber] = true;
        }
    }

    return uniqueArray;
};
export const requestDownloadCode = async (userSession) => {
    const user_id = userSession.idToken.payload.sub;
    const token = userSession.idToken.jwtToken;
    const { currentCustomer } = useConfigStore.getState();
    const customer_id = currentCustomer?.customer_id?.[0].text;

    return await axios.post(
        `${BASE_API_URL}/secure/user/documents/generate-download-code?user_id=${user_id}`,
        undefined,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        }
    );
};

export const getDocumentTypeTranslation = (translations, documentType) => {
    const foundTranslation = translations?.find((translation) => translation.type_of_doc_responses === documentType);
    return foundTranslation ? foundTranslation.type_of_document[0].text : 'Unknown Document Type';
};

export const validateCode = async (userSession, code) => {
    const user_id = userSession.idToken.payload.sub;
    const token = userSession.idToken.jwtToken;
    const { currentCustomer } = useConfigStore.getState();
    const customer_id = currentCustomer?.customer_id?.[0].text;

    return await axios.post(
        `${BASE_API_URL}/secure/user/documents/validate-download-code?user_id=${user_id}`,
        { code: code },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        }
    );
};

export const downloadDocument = (url) => {
    window.open(url, '_blank', 'noreferrer');
};
