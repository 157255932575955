import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import ToolTipBox from '@/components/Common/ToolTipBox';
import Loader from '@/components/Loader';
import UnderlinedButton from '@/components/UnderlinedButton';

import { ContextProvider } from '@/context/ProjectContext';

import { useUserAllAppointments } from '@/hooks/useUserAllAppointments';
import { useUserSharedForms } from '@/hooks/useUserSharedForms';

import { useConfigStore, usePersistedPrismicStore } from '@/store';

import PastAppointments from './MyAppointments/PastAppointments';
import RenderEmptyAppointments from './MyAppointments/RenderEmptyAppointments';
import UpcomingAppointmnets from './MyAppointments/UpcomingAppointmnets';
import UpcomingCard from './MyAppointments/UpcomingCard';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        minHeight: '70vh',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    mainHeading: {
        ...theme.typography.HL1,
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        marginTop: 10,
    },
    appointmentsSwitch: {
        display: 'flex',
        alignItems: 'end',
        gap: '1rem',
    },
    mainContainer: {
        marginBottom: 20,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContentClassDetail: {
        marginTop: 15,
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        marginBottom: 20,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.31rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
}));

const MyAppointments = ({ fromDashboard = false }) => {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();
    const { data, setData } = useUserAllAppointments();
    const { isLoading } = useUserSharedForms();
    // prismic store
    const { [i18n.language]: appointmentPrismicData } = usePersistedPrismicStore((state) => state.appointmentData);
    const { content, dataSet, allAppointmentsPrismicData: newResult } = appointmentPrismicData;
    console.debug('🚀 ~ file: MyAppointments.jsx:54 ~ appointmentPrismicData:', appointmentPrismicData);

    const { [i18n.language]: doctorData } = usePersistedPrismicStore((state) => state.doctorData);

    const { allAppointmentsPrismicData: newResultDoc } = doctorData;

    const {
        [i18n.language]: { allAppointmentsPrismicData: location },
    } = usePersistedPrismicStore((state) => state.locationData);

    // using context
    const { setActiveResponseDataCon, setActiveResp } = useContext(ContextProvider);

    // init states
    const [dataSetDoctors, setDataSetDoctors] = useState(false);
    const [showAllAppointments, setShowAllAppointments] = useState(false);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    // TODO: refactor this garbage
    const getAppointments = (dataArray, appointmentPrismicData, doctorPrismicData, locationPrismicData) => {
        if (dataArray.length > 0) {
            dataArray?.forEach((act) => {
                const activeLocationID = act.sub_loc_key || act.location_key;
                const data__ = act;

                const filteredDocs = doctorPrismicData.filter(
                    (s) => s.type !== 'page_professionals' && s?.data?.key?.[0]?.text === act.professional.key
                );

                const doctorData = {};
                filteredDocs.forEach((d) => {
                    const docLanguageData = d.data;
                    const typeID = docLanguageData.type.id;
                    const docLanguageID = docLanguageData.location_hub.id;
                    const docMainResult = appointmentPrismicData;
                    const docServiceData = [];
                    const docDataLoc = docMainResult.find((fil) => fil.id === docLanguageID);
                    docLanguageData.locationData = docDataLoc?.data;
                    if (
                        docLanguageData.secondary_location_mappings.length > 0 &&
                        !docLanguageData.secondaryLocationDataSet
                    ) {
                        let secondaryLocationMappings = docLanguageData.secondary_location_mappings.map(
                            (location) => doctorPrismicData.find((entry) => entry.id === location.mapping?.id)?.data
                        );

                        secondaryLocationMappings = secondaryLocationMappings
                            .filter((entry) => !!entry)
                            .map((location) => {
                                return {
                                    professional_type: doctorPrismicData.find(
                                        (entry) => entry.id === location.professional_type.id
                                    )?.data,
                                    secondary_locations: location.secondary_locations
                                        .map(
                                            (secondaryLocation) =>
                                                doctorPrismicData.find(
                                                    (entry) => entry.id === secondaryLocation.secondary_location.id
                                                )?.data
                                        )
                                        .filter((entry) => !!entry),
                                };
                            });

                        docLanguageData.secondary_location_mappings = secondaryLocationMappings;
                        docLanguageData.secondaryLocationDataSet = true;
                    }

                    // for services key
                    const serviceKeys = docLanguageData.services;
                    serviceKeys.forEach((service) => {
                        const filterServices = docMainResult.filter((s) => s.id === service.service.id);
                        if (filterServices.length > 0) {
                            docServiceData.push(filterServices[0].data);
                        }
                    });
                    docLanguageData.servicesList = docServiceData;

                    // type
                    const filterType = docMainResult.find((idd) => idd.id === typeID);
                    docLanguageData.specialityType = filterType?.data;
                    doctorData[d.lang] = docLanguageData;
                });

                data__.doctor = doctorData;
                const locationFilter = locationPrismicData?.filter((f) => f.data.key[0].text === activeLocationID);
                const locationMainData = {};
                locationFilter.forEach((loc) => {
                    locationMainData[loc.lang] = loc.data;
                });
                data__.location = locationMainData;
            });
        }
        setDataSetDoctors(true);
        return dataArray;
    };

    const activeAppointments = useMemo(
        () => (data && newResultDoc ? getAppointments([...data?.active], newResult, newResultDoc, location) : null),
        [newResultDoc, location, data, newResult]
    );

    const inactiveAppointments = useMemo(
        () => (data && newResultDoc ? getAppointments([...data?.inactive], newResult, newResultDoc, location) : null),
        [newResultDoc, location, data, newResult]
    );

    const filteredAppointments = useMemo(
        () => activeAppointments?.filter((item) => currentCustomer?.customer_id[0]?.text === item.customer_id),
        [data, currentCustomer]
    );

    const addAppointmentToUpcoming = (appointment) => {
        const ad = data;
        const inactiveData = ad?.inactive;
        if (inactiveData !== null && inactiveData !== undefined && inactiveData.length > 0) {
            inactiveData.splice(0, 0, appointment);
        } else {
            inactiveData.push(appointment);
        }
        setData(ad);
        setActiveResponseDataCon(ad);
        setActiveResp(ad);
    };

    console.debug('activeResponseData: UpcomingAppointment', data);

    // {* CANCEL APPOINTMENT *}
    const moveAppointmentToCancelled = (appointment) => {
        // Update the state with the new active and inactive arrays
        setData({
            active: data.active.filter((a) => a.id !== appointment.id),
            inactive: [appointment, ...data.inactive],
        });
        setActiveResp({
            active: data.active.filter((a) => a.id !== appointment.id),
            inactive: [appointment, ...data.inactive],
        });
        setActiveResponseDataCon({
            active: data.active.filter((a) => a.id !== appointment.id),
            inactive: [appointment, ...data.inactive],
        });
    };

    if (!dataSet) return <Loader />;
    return (
        <div>
            {fromDashboard ? (
                filteredAppointments && filteredAppointments[0]?.hasOwnProperty('doctor') ? (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography className={classes.classTitle}>
                                {content?.your_next_appointment_title[0].text}
                            </Typography>
                            <UnderlinedButton
                                text={content?.see_all_cta[0].text}
                                onPress={() => {
                                    history.push('/appointments');
                                }}
                            />
                        </div>
                        <div className={classes.cardContentClassDetail}>
                            <UpcomingCard
                                dataSet={dataSet}
                                mainData={content}
                                dataSetDoctos={dataSetDoctors}
                                upComming={filteredAppointments[0]}
                                moveAppointmentToCancelled={moveAppointmentToCancelled}
                            />
                        </div>
                    </div>
                ) : (
                    <RenderEmptyAppointments mainData={content} fromDashboard={fromDashboard} />
                )
            ) : (
                <div className={classes.contentContainer}>
                    <div className={classes.topBar}>
                        <Typography className={classes.mainHeading}>
                            {dataSet ? content?.title[0].text : 'My Appointments'}
                        </Typography>
                        <ToolTipBox text={content?.show_all_appointments_tooltip[0].text}>
                            <div className={classes.appointmentsSwitch}>
                                <AntSwitch
                                    checked={showAllAppointments}
                                    onChange={() => setShowAllAppointments(!showAllAppointments)}
                                />
                                <Typography style={{ marginBottom: '2px' }}>
                                    {content?.show_all_appointments[0].text}
                                </Typography>
                            </div>
                        </ToolTipBox>
                    </div>
                    <div>
                        <div className={classes.mainContainer}>
                            <UpcomingAppointmnets
                                dataSet={dataSet}
                                mainData={content}
                                dataSetDoctos={dataSetDoctors}
                                activeResponseData={data}
                                moveAppointmentToCancelled={moveAppointmentToCancelled}
                                showAllAppointments={showAllAppointments}
                            />
                        </div>
                        <div>
                            <PastAppointments
                                dataSet={dataSet}
                                mainData={content}
                                inactiveResponseData={inactiveAppointments}
                                dataSetDoctos={dataSetDoctors}
                                addAppointmentToUpcoming={addAppointmentToUpcoming}
                                showAllAppointments={showAllAppointments}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyAppointments;
