import { Button, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import close from '@/assets/Icons/close_grey.svg';

import { Context as AuthContext } from '@/context/AuthContext';

import { getErrorMessageFromPrismicContent } from '@/utils/errorMessages';
import { requestDownloadCode } from '@/utils/helpers';

import { useDisableResend } from '../../hooks/useDisableResend';
import { useDownloadDocumentModalStore, usePersistedPrismicStore } from '../../store';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import InputCode from './DownloadDocumentModalComponents/InputCode';
import RequestCode from './DownloadDocumentModalComponents/RequestCode';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        ...theme.typography.content,
        marginTop: '0.625rem',
    },
    title: {
        ...theme.typography.HL1,
        marginTop: '0.625rem',
        width: '90%',
        padding: 0,
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        paddingBottom: '1.25rem',
        paddingLeft: 0,
        paddingRight: 0,
        padding: 0,
    },
    btnClose: {
        position: 'absolute',
        right: '0.625rem',
    },
    btnCloseIcon: {
        width: '1.25rem',
        height: '1.25rem',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            flexDirection: 'column',
            gap: '1rem',
        },
    },
    errorText: theme.typography.errorText,
}));

const DownloadDocumentModal = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const { isResendDisabled, disableResend, resendButtonText } = useDisableResend();

    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const { isModalOpen, closeModal } = useDownloadDocumentModalStore(
        useShallow(({ isModalOpen, closeModal }) => ({ isModalOpen: isModalOpen, closeModal: closeModal }))
    );
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const {
        state: { usersession },
    } = useContext(AuthContext);

    const paddingValue = (matches, matchesMd, matchesLg, matchesXl, defaultValue) => {
        if (matches) return '1.25rem';
        if (matchesMd) return '1.875rem';
        if (matchesLg || matchesXl) return '2.5rem';
        return defaultValue;
    };

    const handleRequestCode = async () => {
        setIsLoading(true);
        setError(undefined);
        try {
            await requestDownloadCode(usersession);
            setIsCodeRequested(true);
        } catch (e) {
            setError(getErrorMessageFromPrismicContent(e.response?.data.error_code, content));
        }
        disableResend();
        setIsLoading(false);
    };

    const renderContent = () => {
        if (isLoading) return <EternoSpinner customStyles={{ textAlign: 'center', transform: 'scale(0.5)' }} />;
        if (isCodeRequested)
            return (
                <InputCode
                    classes={classes}
                    isResendDisabled={isResendDisabled}
                    resendButtonText={resendButtonText}
                    handleRequestCode={handleRequestCode}
                    error={error}
                    setError={setError}
                    content={content}
                />
            );
        return (
            <RequestCode
                classes={classes}
                handleRequestCode={handleRequestCode}
                isResendDisabled={isResendDisabled}
                error={error}
                content={content}
            />
        );
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    width: '38.0625rem',
                    borderRadius: 8,
                    paddingLeft: paddingValue(matches, matchesMd, matchesLg, matchesXl, '2.5rem'),
                    paddingRight: paddingValue(matches, matchesMd, matchesLg, matchesXl, '2.5rem'),
                    paddingTop: '1.25rem',
                    paddingBottom: paddingValue(matches, matchesMd, matchesLg, matchesXl, '2.5rem'),
                },
            }}
        >
            <Button color="primary" className={classes.btnClose} onClick={closeModal}>
                <img src={close} className={classes.btnCloseIcon} />
            </Button>
            <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
                {content?.title[0]?.text}
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>{renderContent()}</DialogContent>
        </Dialog>
    );
};

export default DownloadDocumentModal;
