import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import close from '@/assets/Icons/close_grey.svg';

import { Context as AuthContext } from '@/context/AuthContext';

import { processAPIWithHeaders } from '@/utils/helpers';

import { useConfigStore, usePersistedPrismicStore } from '@/store';

import { AntSwitch } from './AntSwitchCommon';
import DialogBox from './DialogBox';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        dialogRoot: {
            backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
        },
        text: {
            ...theme.typography.content,
        },
        title: {
            ...theme.typography.HL1,
            paddingTop: 0,
            position: 'absolute',
            marginTop: 23,
            fontSize: 'bold',
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },

        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        placeholder: {
            backgroundColor: 'blue',
            height: 100,
            width: '100%',
        },

        mainContent: {
            width: '100%',
            height: 220,
            backgroundColor: '#d8d8d8',
            borderRadius: 7,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        heading: {
            ...theme.typography.HL1,
        },

        contentText: {
            ...theme.typography.content,
        },
        checkMark: {
            width: '0.875em',
            height: '0.875em',
            marginTop: 2,
        },
        icon: {
            width: '3.75em',
            height: '3.75em',
        },
        registrationForm: {
            backgroundColor: 'white',

            width: '100%',

            borderRadius: 8,
        },
        registrationFormContent: {
            marginLeft: 50,
            marginRight: 50,

            paddingBottom: 43,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 30,
                marginRight: 30,
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 20,
                marginRight: 20,
                paddingTop: 23,
                paddingBottom: 23,
            },
        },
        formControl: {
            width: '100%',
            backgroundColor: theme.palette.common.lightBrown,
            marginBottom: 20,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        downArrow: {
            height: '0.75em',
        },
        dropdownFilled: {
            backgroundColor: theme.palette.common.lightBrown,
        },
        textFields: {
            height: '3.75em',
        },
        textFieldsError: {
            height: '3.75em',
            boxShadow: '0 0 0 1px #da3030',
        },
        dropDownContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        dropDownRoot: {
            '&:hover': {
                backgroundColor: theme.palette.common.lightBrown,
            },
        },
        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 18,
            borderRadius: '2em',
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        buttonText: {
            ...theme.typography.buttonText,
        },
        alreadyMember: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
            width: '100%',
            textAlign: 'center',
            textDecorationLine: 'underline',
            textDecorationColor: theme.palette.common.darkGrey,
            textUnderlineOffset: '0.25em',
        },
        check: {
            widht: 30,
            height: 30,
        },
        contentTextLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        dropdownStyle: {
            backgroundColor: theme.palette.common.lightBrown,
        },
        underlineText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',

            lineHeight: '1.5rem',
            textTransform: 'uppercase',
            textDecorationLine: 'underline',
            textDecorationColor: theme.palette.common.yellow,
            textDecorationThickness: 2,
            color: theme.palette.common.darkGrey,
            width: '100%',
            textAlign: 'center',
            textUnderlineOffset: '0.25em',
            '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
        },
        subheading: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        registerDuringBooking: {
            textAlign: 'center',
            marginTop: '1.5rem',
        },
    };
});

const LoginDialogBox = ({ openPopUp, onClosePopUp }) => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const theme = useTheme();
    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.loginData);

    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const { setuser, setusersession, setuseraccounts } = useContext(AuthContext);
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [code, setCode] = useState('');
    const [verifyAccount, setVerifyAccount] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);

    const [errEmail, setErrEmail] = useState('');
    const [errPassword, setErrPassword] = useState('');
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [open, setOpen] = useState(false);
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const [error, setError] = useState({
        title: 'Invalid Code',
        text: 'Please enter correct code',
    });

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrEmail('');
        setErrPassword('');
        if (verifyAccount) {
            if (code.length !== 6) {
                setError({
                    title: content?.invalid_code_error[0].text,
                    text: content?.invalid_code_message[0].text,
                });
                setOpen(true);
                return;
            }
            verifyCode();
        } else {
            let err = false;
            if (email === '') {
                setErrEmail(content.required_field_error_message[0].text);
                err = true;
            } else if (!isEmail(email)) {
                setErrEmail(dataSet && content?.invalid_email_error_message[0].text);
                err = true;
            }

            if (password === '') {
                setErrPassword(content.required_field_error_message[0].text);
                err = true;
            }

            if (err) {
                return;
            }
            signIn();
        }
    };

    async function signIn(setup = false) {
        console.debug('signing in');
        try {
            setLocalLoading(true);
            const user = await Auth.signIn(email, password);
            console.debug(user);

            const { username } = user;
            const token = user.signInUserSession.idToken.jwtToken;
            const userData = user.attributes;
            const { currentCustomer } = useConfigStore.getState();
            const customer_id = currentCustomer?.customer_id?.[0].text;

            if (setup) {
                const response = await processAPIWithHeaders('secure/users', 'POST', {
                    body: {
                        id: user.username,
                        email: userData.email,
                        name: {
                            first: userData['custom:firstName'],
                            last: userData['custom:lastName'],
                        },
                        gender: userData['custom:gender'],
                        date_of_birth: userData.dob,
                        title: userData.title,
                        phone: {
                            number: '0151521111',
                        },
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                        customer_id: customer_id,
                    },
                });
                console.debug(response);
            }

            const response = await processAPIWithHeaders(`secure/users/${username}`, 'GET', {
                headers: {
                    Authorization: token,
                },
            });
            console.debug(response);

            const userAccounts = await processAPIWithHeaders(`secure/user/accounts/${username}`, 'GET', {
                headers: {
                    Authorization: token,
                },
            });
            console.debug(userAccounts);
            setuser(response);
            setuseraccounts(userAccounts);
            setusersession(user.signInUserSession);
            setLocalLoading(false);
            onClosePopUp();
        } catch (error) {
            setLocalLoading(false);
            if (error.code === 'UserNotConfirmedException') {
                await Auth.resendSignUp(email);
                setVerifyAccount(true);
            } else {
                setError({
                    title: content?.error_signing_in[0].text,
                    text: content?.user_not_found[0].text,
                });
                setOpen(true);
            }

            console.debug('error signing in', error);
        }
    }

    async function verifyCode() {
        console.debug('verifying');

        try {
            setLocalLoading(true);
            const result = await Auth.confirmSignUp(email, code);
            console.debug(result);
            signIn(true);
        } catch (error) {
            setLocalLoading(false);
            setError({
                title: content?.invalid_code_error[0].text,
                text: content?.invalid_code_message[0].text,
            });
            setOpen(true);
            console.debug('error verifying:', error);
        }
    }

    console.debug('loginDialog: ', content);

    return (
        dataSet && (
            <Dialog
                open={openPopUp}
                onClose={onClosePopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    root: classes.dialogRoot,
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        minHeight: 300,
                        width: matchesXs ? '100%' : '70%',
                        maxWidth: 450,
                        borderRadius: 8,
                    },
                }}
            >
                <DialogActions>
                    <Button onClick={onClosePopUp} color="primary">
                        <img
                            src={close}
                            style={{
                                width: 30,
                                height: 30,
                                objectFit: 'contain',
                            }}
                        />
                    </Button>
                </DialogActions>

                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item md={12} sm={12} xs={12}>
                            <div className={classes.registrationForm}>
                                <div className={classes.registrationFormContent}>
                                    <Typography className={classes.heading}>
                                        {verifyAccount
                                            ? content.verify_account_heading[0].text
                                            : content.heading[0].text}
                                    </Typography>
                                    <Typography className={classes.subheading} style={{ marginTop: 5 }}>
                                        {verifyAccount
                                            ? content.verify_account_sub_heading[0].text
                                            : content.sub_heading[0].text}
                                    </Typography>

                                    <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
                                        {verifyAccount && (
                                            <TextField
                                                id="verifyCode"
                                                label={content.verify_code_textfield_label[0].text}
                                                variant="filled"
                                                color="primary"
                                                placeholder={content.verify_code_textfield_placeholder[0].text}
                                                value={code}
                                                onChange={(event) => {
                                                    const text = event.target.value;
                                                    setCode(text);
                                                }}
                                                fullWidth
                                                classes={{
                                                    root: classes.textFields,
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRoot,
                                                        focused: classes.fieldFocused,
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                            />
                                        )}

                                        {!verifyAccount && (
                                            <div>
                                                <TextField
                                                    id="email"
                                                    label={content.email_textfield_label[0].text}
                                                    value={email}
                                                    onChange={(event) => {
                                                        const text = event.target.value;
                                                        setEmail(text);
                                                    }}
                                                    variant="filled"
                                                    color="primary"
                                                    fullWidth
                                                    style={{ marginTop: 15 }}
                                                    classes={{
                                                        root: errEmail ? classes.textFieldsError : classes.textFields,
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            focused: 'focused',
                                                            shrink: 'shrink',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.filledInputRoot,
                                                            focused: classes.fieldFocused,
                                                            shrink: 'shrink',
                                                        },
                                                    }}
                                                />
                                                {errEmail && (
                                                    <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                                        {errEmail}
                                                    </Typography>
                                                )}
                                            </div>
                                        )}
                                        {!verifyAccount && (
                                            <div>
                                                <TextField
                                                    id="password"
                                                    label={content.password_textfield_label[0].text}
                                                    variant="filled"
                                                    color="primary"
                                                    value={password}
                                                    onChange={(event) => {
                                                        const password = event.target.value;
                                                        setPassword(password);
                                                    }}
                                                    fullWidth
                                                    type={showPassword ? 'text' : 'password'}
                                                    style={{ marginTop: 15 }}
                                                    classes={{
                                                        root: errPassword
                                                            ? classes.textFieldsError
                                                            : classes.textFields,
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            focused: 'focused',
                                                            shrink: 'shrink',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        // <-- This is where the toggle button is added.
                                                        classes: {
                                                            root: classes.filledInputRoot,
                                                            focused: classes.fieldFocused,
                                                            shrink: 'shrink',
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOutlinedIcon />
                                                                    ) : (
                                                                        <VisibilityOffOutlinedIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {errPassword && (
                                                    <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                                        {errPassword}
                                                    </Typography>
                                                )}
                                            </div>
                                        )}
                                        {!verifyAccount && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                }}
                                            >
                                                <Link
                                                    to="/reset-password"
                                                    onClick={() => {
                                                        onClosePopUp();
                                                    }}
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            marginTop: 10,
                                                            fontSize: 14,
                                                        }}
                                                        className={classes.underlineText}
                                                    >
                                                        {content?.forgot_password_button[0].text}
                                                    </Typography>
                                                </Link>
                                            </div>
                                        )}

                                        {!verifyAccount && (
                                            <div
                                                style={{
                                                    marginTop: 10,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <AntSwitch
                                                    checked={checked}
                                                    onChange={() => {
                                                        setChecked(!checked);
                                                    }}
                                                    name="notifications"
                                                    className={classes.userData}
                                                    //  disabled = {content?.category_3_cookies.length === 0 ? true : false}
                                                />

                                                <div className={classes.subheading} style={{ marginLeft: 10 }}>
                                                    <span>{content.remember_credentials_text[0].text}</span>
                                                </div>
                                            </div>
                                        )}

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            disableElevation
                                            classes={{
                                                root: classes.submitButton,
                                                label: classes.buttonText,
                                            }}
                                        >
                                            {localLoading ? (
                                                <CircularProgress />
                                            ) : verifyAccount ? (
                                                content.verify_button[0].text
                                            ) : (
                                                content.login_button[0].text
                                            )}
                                        </Button>
                                        <div className={classes.registerDuringBooking}>
                                            <Typography>{t('RegisterDuringBooking')}</Typography>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {/* login end */}
                </DialogContent>
                <DialogBox
                    open={open}
                    onClose={() => {
                        console.debug('closing dialog');
                        setOpen(false);
                    }}
                    title={error.title}
                    text={error.text}
                />
            </Dialog>
        )
    );
};

export default LoginDialogBox;
