import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import info from '@/assets/Icons/info.svg';

import { menuItems } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

import ToolTipBox from '../../Common/ToolTipBox';
import CardHeading from './CardHeading';
import CardWrapper from './CardWrapper';

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        position: 'relative',
    },

    parentContainer: {
        marginBlock: '1em',
    },

    bodyImageParent: {
        display: 'flex',
        justifyContent: 'center',
        borderRightWidth: '3px',
        borderRightStyle: 'solid',
        borderRightColor: '#F5F5F5',
        [theme.breakpoints.down('xs')]: {
            border: '0px',
            justifyContent: 'flex-start',
        },
    },

    textGroup: {
        paddingBlock: '.5rem',
        paddingInline: '2rem',
    },

    textHeading: {
        fontFamily: 'MessinaSerif-Regular',
        fontStyle: 'italic',
        fontSize: '21px',
    },

    textSubHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.brown,
    },
}));

const menuList = menuItems();

const YourBodyDetails = ({
    // Default value if one is null
    gender = '-',
    age = '-',
    weight = '-',
    weightUnit,
    bmi = '-',
    height = '-',
    heightUnit,
    bloodType = '-',
    handleEdit,
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { content, dataArr },
    } = usePersistedPrismicStore((state) => state.healthProfileData);
    console.debug('🚀 ~ file: YourBodyDetails.jsx ~ line 16 ~ YourBodyDetails ~ mainData', dataArr);

    const bmiRange = useCallback(
        (bmi = 14) => {
            if (Object.keys(dataArr).length > 0)
                if (bmi < 18.5) {
                    return dataArr?.bmi_range_underweight_title[0].text;
                } else if (bmi >= 18.5 && bmi <= 25) {
                    return dataArr?.bmi_range_normal_title[0].text;
                } else if (bmi > 25 && bmi <= 30) {
                    return dataArr?.bmi_range_overweight[0].text;
                } else {
                    return dataArr?.bmi_range_obesity[0].text;
                }
        },
        [dataArr]
    );

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <CardWrapper isEditable onEdit={() => handleEdit(1)}>
                    <div className={classes.contentContainerBody}>
                        <CardHeading>{content?.your_body_title[0].text}</CardHeading>
                        <Grid container spacing={2} className={classes.parentContainer} style={{ padding: 0 }}>
                            <Grid item sm={4} xs={12} style={{ padding: 0 }}>
                                <div className={classes.bodyImageParent}>
                                    <img
                                        src={
                                            content?.[
                                                gender?.toLowerCase() === 'male' || gender === 'Männlich'
                                                    ? 'male_illustration'
                                                    : 'female_illustration'
                                            ]?.url
                                        }
                                        style={{
                                            width: content?.[
                                                gender?.toLowerCase() === 'male' || gender === 'Männlich'
                                                    ? 'male_illustration'
                                                    : 'female_illustration'
                                            ]?.dimensions?.width,
                                            height: content?.[
                                                gender?.toLowerCase() === 'male' || gender === 'Männlich'
                                                    ? 'male_illustration'
                                                    : 'female_illustration'
                                            ]?.dimensions?.height,
                                        }}
                                        alt={
                                            content?.[
                                                gender.toLowerCase() === 'male' || gender === 'Männlich'
                                                    ? 'male_illustration'
                                                    : 'female_illustration'
                                            ]?.alt
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item sm={8} xs={12} style={{ padding: 10 }}>
                                <Grid container>
                                    <Grid item md={6} xs={6} style={{ padding: 0 }}>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>{age || '-'}</Typography>
                                            <Typography className={classes.textSubHeading}>
                                                {content?.age_title[0].text}
                                            </Typography>
                                        </div>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>
                                                {weight || '-'}
                                                {weight && weightUnit}
                                            </Typography>
                                            <Typography className={classes.textSubHeading}>
                                                {content?.weight_title[0].text}
                                            </Typography>
                                        </div>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>{bmi || '-'}</Typography>
                                            <Typography
                                                className={classes.textSubHeading}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '.5rem',
                                                }}
                                            >
                                                {content?.bmi_title[0].text}
                                                {bmi ? (
                                                    <div className={classes.buttonContainerInfo}>
                                                        <ToolTipBox text={bmiRange(bmi)}>
                                                            <img
                                                                src={info}
                                                                alt="information about bmi"
                                                                style={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    objectFit: 'contain',
                                                                }}
                                                            />
                                                        </ToolTipBox>
                                                    </div>
                                                ) : null}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={6} style={{ padding: 0 }}>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>{gender || '-'}</Typography>
                                            <Typography className={classes.textSubHeading}>
                                                {content?.gender_title[0].text}
                                            </Typography>
                                        </div>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>
                                                {height || '-'}
                                                {height && heightUnit}
                                            </Typography>
                                            <Typography className={classes.textSubHeading}>
                                                {content?.height_title[0].text}
                                            </Typography>
                                        </div>
                                        <div className={classes.textGroup}>
                                            <Typography className={classes.textHeading}>{bloodType || '-'}</Typography>
                                            <Typography className={classes.textSubHeading}>
                                                {content?.blood_type_title[0].text}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </CardWrapper>
            )}
        </>
    );
};

export default memo(YourBodyDetails);
