/** Others */
import { omit } from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Re-usable user components */
import PersonalInformation from '@/components/Common/User/PersonalInformation';
import { ConsentStyles } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import useFormLabels, { pageNameLabels } from '@/hooks/useFormLabels';
import useFormSteps from '@/hooks/useFormSteps';
import useI18nCountries from '@/hooks/useI18nCountries';
import useScrollToTop from '@/hooks/useScrollToTop';

import { usePersistedPrismicStore } from '@/store';

import AccountInsuranceSection from '../../../HealthProfileAnamnesis/AccountCommponents/AccountInsuranceSection';

const PersonalInformationPage = () => {
    /** Styles */
    const classes = ConsentStyles();
    const { i18n } = useTranslation();

    /** Scroll to top on mount */
    useScrollToTop();

    /** Context */
    const {
        consentFormsContent: content,
        formData,
        setFormData,
        handleChange,
        professional,
        professionalAddress,
    } = useContext(ConsentFormsContext);

    const {
        state: { personal_information },
    } = useContext(HealthProfileContextMain);

    const {
        state: { user },
    } = useContext(AuthContext);

    const { isConsentAllForms, steps } = useFormSteps();
    const hasAnamnesis = steps?.some((step) => step.includes('anamnesis'));

    useEffect(() => {
        if (isConsentAllForms && hasAnamnesis && !user) {
            setFormData((prev) => {
                return {
                    ...prev,
                    ...personal_information,
                    country: {
                        code: personal_information?.countryCode ?? '',
                        name: countryList[personal_information?.countryCode?.toLocaleUpperCase()],
                    },
                    phoneNo: {
                        number: personal_information?.phoneNumber ?? '',
                    },
                    street: personal_information?.streetNumber ?? '',
                };
            });
        }
    }, [isConsentAllForms, personal_information]);

    /** HealthProfile Prismic */
    const {
        [i18n.language]: { mainDataAccountEterno, mainData, dataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);
    const {
        [i18n.language]: { benefits: accountData },
    } = usePersistedPrismicStore((state) => state.registerData);

    /** Form Labels */
    const labels = useFormLabels({ ...content, ...accountData });

    /** Countries */
    const countryList = useI18nCountries();

    useEffect(() => {
        if (!Object.keys(formData?.country).length && Object.keys(countryList).length > 0) {
            setFormData((prev) => ({
                ...prev,
                country: {
                    code: 'de',
                    name: countryList.DE,
                },
            }));
        }
    }, [countryList, formData?.country]);

    const getHeading = () => {
        const baseHeading = content?.corterierContract?.introText || '';
        if (professional && professionalAddress) {
            return `${baseHeading} ${professional.name}, ${professionalAddress}`;
        }
        return baseHeading;
    };

    if (!accountData) return <Loader />;

    return (
        <>
            <PersonalInformation
                /** Form States */
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                heading={getHeading()}
                labels={omit(labels[pageNameLabels.PERSONAL_INFO], ['phoneLabel', 'titleLabel', 'middleNameLabel'])}
                isDOBFullWidth
                isOnlyGender
            />
            <div className={classes.mt30}>
                {/* HEALTH INSURANCE */}
                {dataSet && (
                    <AccountInsuranceSection
                        mainData={mainData}
                        mainDataAccountEterno={mainDataAccountEterno}
                        heading={content?.titles?.insuranceDetails || ''}
                        fullWidth
                    />
                )}
            </div>
        </>
    );
};

export default PersonalInformationPage;
