import { Grid, Typography } from '@material-ui/core';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Context as ConsentManagerContext } from '@/context/ConsentManagerContext';

import { formatDateForTable } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

import FilledButton from '../../../FilledButton';
import Loader from '../../../Loader';
import TableRow from './TableRow';
import tableStyles from './tableStyles';

const OverviewTable = ({ doctorDocuments }) => {
    const classes = tableStyles();
    const history = useHistory();
    const { i18n } = useTranslation();

    // custom hook (prismic data)
    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { loading: contentLoading, content: consentDataContext } = consentPrismicData;

    const [DocTypes, setDocTypes] = useState(null);

    const {
        state: { tableType },
    } = useContext(ConsentManagerContext);

    console.debug('🚀 ~ file: Table.jsx:19 ~ content', consentDataContext);
    useEffect(() => {
        if (!contentLoading) {
            setDocTypes(consentDataContext.data_operations_table_document_types);
        }
    }, [consentDataContext]);

    // setting type to prismic type text
    const setDocumentTypeToPrismic = useCallback(
        (type) => {
            if (!DocTypes) return;
            const filteredType = DocTypes.filter((prismicType) => {
                return prismicType.data_operations_table_document_types_backend === type;
            });
            return filteredType[0];
        },
        [DocTypes]
    );

    const colNameRight = tableType
        ? consentDataContext.data_operations_table_document_column[0].text
        : consentDataContext.data_operations_table_shared_with_column[0].text;

    const colNameLeft = tableType
        ? consentDataContext.data_operations_table_shared_with_column[0].text
        : consentDataContext.data_operations_table_document_column[0].text;

    /**
     * The rows are sorted by date in a descending order
     */
    doctorDocuments?.sort(function (a, b) {
        /**
         * Turn your strings into dates, and then subtract them
         * to get a value that is either negative, positive, or zero.
         */
        return new Date(b.meta.created_at) - new Date(a.meta.created_at);
    });

    if (contentLoading) return <Loader />;

    return (
        <Grid container className={classes.table}>
            <Grid container className={classes.tableHead}>
                <Grid item xs={4}>
                    <Typography className={classes.headTitle}>{colNameLeft}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.headTitle}>{colNameRight}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.lastColumn}>
                    <Typography className={classes.headTitle}>
                        {consentDataContext ? consentDataContext.data_operations_table_date_column[0].text : 'Date'}
                    </Typography>
                </Grid>
            </Grid>
            {doctorDocuments ? (
                doctorDocuments.slice(0, 3).map(({ display_name, doc_type, meta: { created_at } }, index) => {
                    return (
                        <TableRow
                            key={index}
                            sharedWith={display_name[0].text}
                            documentType={
                                setDocumentTypeToPrismic(doc_type)?.data_operations_table_document_types_frontend[0]
                                    .text
                            }
                            date={formatDateForTable(created_at)}
                        />
                    );
                })
            ) : (
                <Loader />
            )}
            <div className={classes.footerButton}>
                <FilledButton
                    onPress={() => {
                        history.push('/consent-history');
                    }}
                    text={!contentLoading ? consentDataContext?.overview_button_history[0].text : 'History'}
                />
            </div>
        </Grid>
    );
};

export default memo(OverviewTable);
