import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { languageMap } from '@/utils/helpers';

/**
 *
 * Hook for setting prismic data according to current language
 *
 * @param {object} prismicData just pass the prismic return value
 * @param {string} type prismic document type (optional)
 * @param {string} id prismic document id (optional)
 * @returns {loading, content} loading if mainData is false and content has prismic object in current language
 */
function usePrismicData(prismicData = null, type = '', id = '') {
    const { i18n } = useTranslation();
    const [mainData, setMainData] = useState(null);

    useEffect(() => {
        if (!prismicData) return;

        // TODO: should remove this hook after changing all the states that use it
        let dataArray = prismicData.results;
        if (!prismicData.results) dataArray = prismicData[i18n.language];

        const content = {
            'en-us': {},
            'de-de': {},
        };

        if (!dataArray) return;

        dataArray.forEach((result) => {
            if (type) {
                if (id) {
                    if (result.id === id) {
                        content[result.lang] = result.data;
                    }
                } else if (result.type === type) {
                    content[result.lang] = result.data;
                }
            } else {
                content[result.lang] = result.data;
            }
        });
        const lan = languageMap[i18n.language];
        setMainData(content[lan]);
    }, [prismicData, type, id, i18n.language]);

    return { loading: !mainData || Object.keys(mainData).length === 0, content: mainData };
}

export default usePrismicData;
