import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ACCOUNT_Health_insurance from '@/assets/Icons/ACCOUNT_Health_insurance.svg';

import { DateTextField } from '@/components/Common/Date/DateTextField';
import DialogBox from '@/components/Common/DialogBox';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import { insurance_list_private, insurance_list_public } from '@/components/Common/User/InsuranceData';
import FilledButton from '@/components/FilledButton';
import Icon from '@/components/Icon/Icon';
import OutlinedButton from '@/components/OutlinedButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProvider } from '@/context/ProjectContext';

import { getISODate } from '@/utils/helpers';

import '../Acount.css';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',

            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',

            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            marginTop: 10,
        },
        accountData: {
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: `rgba(${r} ,${g} ,${b} ,0.2)`,
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        menuItem: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            color: theme.palette.common.darkGrey,
            textTransform: 'none',
        },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIcon: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: 'rgba(255,200,119,0.2)',
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});

const HealthInsurance = ({ mainData, dataSet }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));
    const [insurance, setInsurance] = useState(false);
    const [billingEdit, setBillingEdit] = useState(false);

    const [einsuranceType, seteInsuranceType] = useState('');
    console.debug('🚀 ~ file: HealthInsurance.jsx:604 ~ mainData', mainData);
    const [eInsuranceName, seteInsuranceName] = useState('');
    const [einsuranceNumber, seteInsuranceNumber] = useState('');
    const [einsuranceNumberTwo, seteInsuranceNumberTwo] = useState('');
    const [etext, seteText] = useState('');
    const [anchorE2, setAnchorE2] = useState(null);

    const [insuranceType, setInsuranceType] = useState('PRIVATE');
    const [insuranceName, setInsuranceName] = useState('');
    const [insuranceNumber, setInsuranceNumber] = useState('');
    const [insuranceNumberTwo, setInsuranceNumberTwo] = useState('');
    const [text, setText] = useState('');

    const [validUntil, setValidUntil] = useState('');

    const [cardHolderName, setCardHolderName] = useState('');
    const [estreet, seteStreet] = useState('');
    const [ecardHolderName, seteCardHolderName] = useState('');
    const [street, setStreet] = useState('Kastanienallee 90');
    const [error, setError] = useState(false);
    // Validation
    const isValidated =
        einsuranceType === '' ||
        (einsuranceType === 'PRIVATE' && eInsuranceName === '') ||
        (validUntil.length > 0 && error);
    const isInsuranceInfoEmpty =
        validUntil === '' &&
        insuranceNumber === '' &&
        insuranceName === '' &&
        insuranceNumberTwo === '' &&
        insuranceType !== 'SELFPAYER';

    // for outing
    const [insuranceTypeContextt, setInsuranceTypeContextt] = useState('');

    // loader
    const [loader, setLoader] = useState(false);

    const { insuranceTypeContext, setInsuranceTypeContext, languageGlobal } = useContext(ContextProvider);

    const {
        state: { user, usersession, userAccount },
        setUserHealthInsurance,
    } = useContext(AuthContext);

    const userData = user;
    const [openChangeDialogBilling, setOpenChangeDialogBilling] = useState(false);

    const handleCloseInsuranceType = (insuranceType = '') => {
        // clear info
        seteInsuranceName('');
        seteInsuranceNumber('');
        seteInsuranceNumberTwo('');
        setValidUntil('');
        seteText('');

        setAnchorE2(null);
        if (insuranceType !== '') {
            seteInsuranceType(insuranceType);
        }
    };

    // Main Functions

    const [healthInsuranceData, setHealthInsuranceData] = useState();

    const healthInsuranceAccount = async () => {
        console.debug(
            'validUntil12345',
            validUntil,
            validUntil &&
                getISODate(
                    validUntil
                        .replaceAll(languageGlobal === 'en' && '.', '/')
                        .replaceAll(languageGlobal === 'de' && '/', '.'),
                    languageGlobal
                ),
            languageGlobal
        );
        try {
            setLoader(true);
            let body;

            if (einsuranceType === 'PUBLIC') {
                body = {
                    user_id: userAccount.user_id,
                    insurance_type: einsuranceType.toUpperCase(),
                    insurance_company: eInsuranceName,
                    insurance_company_id: einsuranceNumber,
                    insurance_id: einsuranceNumberTwo,
                    insurance_valid_until:
                        validUntil.length > 0
                            ? getISODate(
                                  validUntil
                                      .replaceAll(languageGlobal === 'en' && '.', '/')
                                      .replaceAll(languageGlobal === 'de' && '/', '.'),
                                  languageGlobal
                              )
                            : '',
                    insurance_status: etext.toUpperCase(),
                };
            } else if (einsuranceType === 'PRIVATE') {
                body = {
                    user_id: userAccount.user_id,
                    insurance_type: einsuranceType.toUpperCase(),
                    insurance_company: eInsuranceName,
                };
                if (einsuranceNumberTwo) body.insurance_id = einsuranceNumberTwo;
            } else {
                body = {
                    user_id: userAccount.user_id,
                    insurance_type: einsuranceType.toUpperCase(),
                };
            }
            console.debug('🚀 ~ file: HealthInsurance.jsx:608 ~ healthInsuranceAccount ~ body:', body);

            const resp = await axios.post(
                `${BASE_API_URL}/secure/user/accounts/${userAccount.user_id}/health-insurances`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: usersession.idToken.jwtToken,
                    },
                }
            );
            console.debug('Response ', resp);
            if (einsuranceType === 'PUBLIC') {
                setUserHealthInsurance({
                    user_id: userAccount.user_id,
                    insurance_type: einsuranceType.toUpperCase(),
                    insurance_company: eInsuranceName,
                    insurance_company_id: einsuranceNumber,
                    insurance_id: einsuranceNumberTwo,
                    insurance_valid_until: getISODate(
                        validUntil
                            .replaceAll(languageGlobal === 'en' && '.', '/')
                            .replaceAll(languageGlobal === 'de' && '/', '.'),
                        languageGlobal
                    ),
                    insurance_status: etext.toUpperCase(),
                });
            } else if (einsuranceType === 'PRIVATE') {
                if (einsuranceNumberTwo) {
                    setUserHealthInsurance({
                        user_id: userAccount.user_id,
                        insurance_type: einsuranceType.toUpperCase(),
                        insurance_company: eInsuranceName,
                        insurance_id: einsuranceNumberTwo,
                    });
                } else {
                    setUserHealthInsurance({
                        user_id: userAccount.user_id,
                        insurance_type: einsuranceType.toUpperCase(),
                        insurance_company: eInsuranceName,
                    });
                }
            }

            resp.status === 200 && setInsurance(false);
            resp && setLoader(false);
        } catch (error) {
            setLoader(false);
            console.debug('error is ', error);
        }
    };
    console.debug('einsuranceType', einsuranceType);
    useEffect(() => {
        const { user_id } = userAccount;
        const token = usersession.idToken.jwtToken;
        const fetchData = async () => {
            const res = await axios.get(
                `${BASE_API_URL}/secure/user/accounts/${user_id}/health-insurances`,

                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                    },
                }
            );
            console.debug(res);
            setHealthInsuranceData(res);
        };

        fetchData().catch((error) => {
            console.debug('error is ', error);
        });
    }, []);
    const [healthCheckUp, setHealthCheckUp] = useState(false);

    /*
     * Local states for don't save button
     * */
    const [insuranceNameLocal, setInsuranceNameLocal] = useState('');
    const [insuranceTypeLocal, setInsuranceTypeLocal] = useState('');
    const [insuranceNumberLocal, setInsuranceNumberLocal] = useState('');
    const [insuranceNumberTwoLocal, setInsuranceNumberTwoLocal] = useState('');
    const [validUntilLocal, setValidUntilLocal] = useState('');
    const [textLocal, setTextLocal] = useState('');

    useEffect(() => {
        console.debug(healthInsuranceData);
        {
            healthInsuranceData === null ? setHealthCheckUp(false) : setHealthCheckUp(true);
        }
        console.debug('Health Check Up ', healthCheckUp);
        if (
            healthInsuranceData &&
            healthInsuranceData.data !== undefined &&
            Object.keys(healthInsuranceData.data).length > 0
        ) {
            setInsuranceName(healthInsuranceData.data.insurance_company);
            setInsuranceNameLocal(healthInsuranceData.data.insurance_company);

            setInsuranceType(healthInsuranceData.data.insurance_type);
            setInsuranceTypeLocal(healthInsuranceData.data.insurance_type);

            setInsuranceTypeContextt(healthInsuranceData.data.insurance_type);
            console.debug('insurance name', eInsuranceName, einsuranceNumber);

            if (healthInsuranceData.data.insurance_id) {
                setInsuranceNumberTwo(healthInsuranceData.data.insurance_id);
                setInsuranceNumberTwoLocal(healthInsuranceData.data.insurance_id);
            }

            if (healthInsuranceData.data.insurance_type === 'PUBLIC') {
                setInsuranceNumber(healthInsuranceData.data.insurance_company_id);
                setInsuranceNumberLocal(healthInsuranceData.data.insurance_company_id);

                setValidUntil(healthInsuranceData.data.insurance_valid_until);
                setValidUntilLocal(healthInsuranceData.data.insurance_valid_until);

                setText(healthInsuranceData.data.insurance_status);
                setTextLocal(healthInsuranceData.data.insurance_status);

                const FValidUntil =
                    healthInsuranceData.data.insurance_valid_until &&
                    healthInsuranceData.data.insurance_valid_until.split('-');

                const FValidUntill = FValidUntil
                    ? `${FValidUntil[2]}${languageGlobal === 'en' ? '/' : '.'}${FValidUntil[1]}${
                          languageGlobal === 'en' ? '/' : '.'
                      }${FValidUntil[0]}`
                    : '';
                setValidUntil(FValidUntill);
                setValidUntilLocal(FValidUntill);
            }
        }
        setInsuranceTypeContext(insuranceTypeContextt);
        console.debug('INsurance data', insuranceTypeContext);
    }, [healthInsuranceData]);

    return (
        <Grid container direction={matches ? 'column-reverse' : 'row'}>
            <Grid item md={insurance ? 12 : 6} xs={12}>
                <div
                    className={classes.accountData}
                    style={{
                        paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    }}
                >
                    <Icon src={ACCOUNT_Health_insurance} description="account health insurance" />
                    <div className="">
                        <Typography className={classes.subHeading} style={{ fontSize: '0.75rem' }}>
                            {/* {t("HealthInsurance")} */}
                            {dataSet ? mainData.health_insurance_section_title[0].text : t('HealthInsurance')}
                        </Typography>
                    </div>

                    {insurance && (
                        <Grid container spacing={2} style={{ marginTop: -10 }}>
                            <Grid item md={6} xs={12}>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {/* drop down inc company starts */}

                                    <DropDown
                                        type="default"
                                        mainDataHeading={mainData.health_insurance_type_label[0].text}
                                        mainDataArray={mainData?.insurance_type_values}
                                        dataSet={dataSet}
                                        setData={handleCloseInsuranceType}
                                        data={einsuranceType}
                                        value="insuranceType"
                                    />
                                    {/* drop down inc company ends */}

                                    {/* drop down inc name starts */}

                                    {einsuranceType !== 'SELFPAYER' && (
                                        <DropDown
                                            type="default"
                                            mainDataHeading={mainData.health_insurance_provider_name_label[0].text}
                                            mainDataArray={
                                                einsuranceType === 'PUBLIC'
                                                    ? insurance_list_public
                                                    : einsuranceType === 'PRIVATE'
                                                      ? insurance_list_private
                                                      : []
                                            }
                                            setData={seteInsuranceName}
                                            data={eInsuranceName}
                                            compulsory=""
                                            fullWidth={matches}
                                            validateForm={null}
                                            widthID="insurance_company_type"
                                        />
                                    )}

                                    <div>
                                        {einsuranceType === 'PUBLIC' && (
                                            <TextField
                                                id="insuranceCompanyId"
                                                label={
                                                    dataSet
                                                        ? mainData.health_insurance_company_id[0].text
                                                        : t('InsuranceNumber')
                                                }
                                                variant="filled"
                                                type="text"
                                                color="primary"
                                                value={einsuranceNumber}
                                                onChange={(event) => {
                                                    const text = event.target.value;
                                                    seteInsuranceNumber(text);
                                                }}
                                                fullWidth
                                                classes={{
                                                    root: classes.textFields,
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRoot,
                                                        focused: classes.fieldFocused,
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                style={{ flex: 1 }}
                                            />
                                        )}

                                        {einsuranceType === 'PUBLIC' && (
                                            <TextField
                                                id="insuranceCompanyNumber"
                                                label={
                                                    dataSet
                                                        ? mainData.health_insurance_number[0].text
                                                        : t('InsuranceNumber')
                                                }
                                                variant="filled"
                                                type="text"
                                                color="primary"
                                                value={einsuranceNumberTwo}
                                                onChange={(event) => {
                                                    const text = event.target.value;
                                                    seteInsuranceNumberTwo(text);
                                                }}
                                                fullWidth
                                                classes={{
                                                    root: classes.textFields,
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRoot,
                                                        focused: classes.fieldFocused,
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                style={{ flex: 1 }}
                                            />
                                        )}
                                        {/* Date Field Starts */}

                                        {einsuranceType === 'PUBLIC' && (
                                            <DateTextField
                                                type="insuranceDate"
                                                separator={mainData?.date_seprator[0]?.text}
                                                mainHeading={mainData.health_insurance_expiry_label[0].text}
                                                setDOB={setValidUntil}
                                                dob={validUntil}
                                                setError={setError}
                                                error={validUntil.length > 0 && error}
                                                errorMessage={mainData?.date_error[0].text}
                                                placeHolderMonth={
                                                    mainData?.health_insurance_expiry_placeholder_month[0]?.text
                                                }
                                                placeHolderYear={
                                                    mainData?.health_insurance_expiry_placeholder_year[0]?.text
                                                }
                                                placeHolderDay={
                                                    mainData?.health_insurance_expiry_placeholder_day[0]?.text
                                                }
                                            />
                                        )}
                                        {/* date field ends */}
                                    </div>

                                    {/* text drop down */}
                                    {einsuranceType === 'PUBLIC' && (
                                        <DropDown
                                            type="default"
                                            mainDataHeading={mainData.health_insurance_membership_types_label[0].text}
                                            mainDataArray={mainData?.health_insurance_membership_types}
                                            dataSet={dataSet}
                                            setData={seteText}
                                            data={etext}
                                        />
                                    )}
                                    {/* text drop down end */}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        {matchesxs ? (
                                            <div
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                <div style={{ marginBottom: 10 }}>
                                                    <FilledButton
                                                        disabled={loader || isValidated}
                                                        text={
                                                            dataSet && !loader ? (
                                                                mainData.health_insurance_save_cta[0].text
                                                            ) : !loader ? (
                                                                t('SaveChanges')
                                                            ) : (
                                                                <CircularProgress
                                                                    style={{
                                                                        color: theme.palette.common.yellow,
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        onPress={() => {
                                                            setInsuranceType(einsuranceType);
                                                            setText(etext);
                                                            setInsuranceNumber(einsuranceNumber);
                                                            setInsuranceName(eInsuranceName);
                                                            setCardHolderName(ecardHolderName);
                                                            setInsuranceNumberTwo(einsuranceNumberTwo);
                                                            setValidUntil(validUntil);

                                                            healthInsuranceAccount();
                                                        }}
                                                        color="yellow"
                                                        fullWidth
                                                    />
                                                </div>

                                                <OutlinedButton
                                                    text={
                                                        dataSet
                                                            ? mainData.health_insurance_cancel_cta[0].text
                                                            : t('Cancel')
                                                    }
                                                    onPress={() => {
                                                        setOpenChangeDialogBilling(true);
                                                    }}
                                                    color="yellow"
                                                    fullWidth
                                                    disableRipple
                                                />
                                            </div>
                                        ) : (
                                            <div className={classes.btnClass}>
                                                <OutlinedButton
                                                    text={
                                                        dataSet
                                                            ? mainData.health_insurance_cancel_cta[0].text
                                                            : t('Cancel')
                                                    }
                                                    onPress={() => {
                                                        setOpenChangeDialogBilling(true);
                                                    }}
                                                    disableRipple
                                                    color="yellow"
                                                />

                                                <FilledButton
                                                    disabled={loader || isValidated}
                                                    text={
                                                        dataSet && !loader ? (
                                                            mainData.health_insurance_save_cta[0].text
                                                        ) : !loader ? (
                                                            t('SaveChanges')
                                                        ) : (
                                                            <CircularProgress
                                                                style={{
                                                                    color: theme.palette.common.yellow,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    onPress={() => {
                                                        setInsuranceType(einsuranceType);
                                                        setText(etext);
                                                        setInsuranceNumber(einsuranceNumber);
                                                        setInsuranceName(eInsuranceName);
                                                        setCardHolderName(ecardHolderName);
                                                        setInsuranceNumberTwo(einsuranceNumberTwo);
                                                        setValidUntil(validUntil);

                                                        healthInsuranceAccount();
                                                    }}
                                                    color="yellow"
                                                />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {userData && !insurance && insuranceType === 'SELFPAYER' && (
                        <Typography className={classes.userData}>
                            {mainData['self-payer_insurance_type'][0].text}
                        </Typography>
                    )}

                    {userData && !insurance && insuranceName && (
                        <Typography className={classes.userData}>{`${
                            dataSet ? mainData.health_insurance_provider_name_label[0].text : t('InsuranceName')
                        }: ${insuranceName}`}</Typography>
                    )}

                    {userData && !insurance && insuranceNumber && (
                        <Typography className={classes.userData}>{`${
                            dataSet ? mainData.health_insurance_company_id[0].text : t('InsuranceNumber')
                        }: ${insuranceNumber}`}</Typography>
                    )}

                    {userData && !insurance && insuranceNumberTwo && (
                        <Typography className={classes.userData}>{`${
                            dataSet && einsuranceType === 'PUBLIC'
                                ? mainData.health_insurance_number[0].text
                                : t('InsuranceNumber')
                        }: ${insuranceNumberTwo}`}</Typography>
                    )}

                    {userData && !insurance && validUntil && (
                        <Typography className={classes.userData}>{`${
                            dataSet ? mainData.health_insurance_expiry_label[0].text : 'Valid Until'
                        }: ${validUntil}`}</Typography>
                    )}

                    {!isInsuranceInfoEmpty && !insurance && (
                        <UnderlinedButton
                            text={dataSet ? mainData.edit_cta[0].text : t('Edit')}
                            onPress={() => {
                                seteStreet(street);
                                seteText(text);
                                seteInsuranceNumber(insuranceNumber);
                                seteInsuranceType(insuranceType);
                                seteInsuranceName(insuranceName);
                                seteInsuranceNumberTwo(insuranceNumberTwo);
                                setInsurance(true);
                            }}
                            color={theme.palette.common.darkGrey}
                        />
                    )}

                    {isInsuranceInfoEmpty && !insurance && (
                        <div className="">
                            <Typography className={classes.userData}>
                                {dataSet
                                    ? mainData.health_insurance_missing_text[0].text
                                    : 'You don’t have any payment method saved on your Profile'}
                            </Typography>
                            <div
                                style={{
                                    paddingTop: 20,
                                }}
                            >
                                <FilledButton
                                    text={dataSet && mainData.add_health_insurance_cta[0].text}
                                    onPress={() => {
                                        setInsurance(true);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Grid>
            <DialogBox
                open={openChangeDialogBilling}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpenChangeDialogBilling(false);
                }}
                onPress={() => {
                    /** Resetting Values after don't save click */
                    setInsuranceType(insuranceTypeLocal);
                    setInsuranceName(insuranceNameLocal);
                    setInsuranceNumber(insuranceNumberLocal);
                    setInsuranceNumberTwo(insuranceNumberTwoLocal);
                    setValidUntil(validUntilLocal);
                    setText(textLocal);

                    setOpenChangeDialogBilling(false);
                    setBillingEdit(false);
                    setInsurance(false);
                }}
                title={dataSet ? mainData.sure_warning[0].text : 'Are you sure?'}
                text={dataSet ? mainData.changes_not_saved[0].text : 'The changes you have made will not be saved.'}
                cancelButton
                mainButton
                mainButtonText={dataSet ? mainData.don_t_save[0].text : "Don't Save"}
                mainButtonColor="yellow"
            />
        </Grid>
    );
};

export default HealthInsurance;
