import { useEffect } from 'react';

import { useCookiesStore } from '@/store';

const useAnalytics = () => {
    const analyticsAllowed = useCookiesStore((state) => state.analyticsCookies);

    useEffect(() => {
        if (!import.meta.env.VITE_MATOMO_SRC || !analyticsAllowed) return;

        const _mtm = (window._mtm = window._mtm || []);
        _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
        const d = document;
        const g = d.createElement('script');
        const s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = import.meta.env.VITE_MATOMO_SRC;
        s.parentNode.insertBefore(g, s);
    }, [analyticsAllowed]);
};

export default useAnalytics;
