import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import dustbinIcon from '@/assets/images/dustbinIcon.png';
import pencilIcon from '@/assets/images/pencilIcon.png';

import { DateTextField } from '@/components/Common/Date/DateTextField';
import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { ContextProvider } from '@/context/ProjectContext';

import { getLocalDateMonth } from '@/utils/helpers';

import UnderLinedAddButton from '../UnderLinedAddButton/UnderLinedAddButton';
import { useStyles } from './ExistingConditionStyles';

export default function ExistingConditionTestPerfomed({
    mainData,
    addDataTest,
    deleteEditTest,
    deleteGrayTest,
    editOptionTest,
}) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // context for const variables
    const {
        editState,
        setTestEdit,
        setImagingPerfomed,
        setImagingPerfomedDate,
        setImagingPerfomedArr,
        setImagingPerfomedArrEasy,
        imagingPerfomed,
        imagingPerfomedDate,
        imagingPerfomedArr,
        imagingEdit,
        setErrorDate3,
        errorDate3,
        setImagingEdit,
    } = useContext(ContextProviderTwo);

    const {
        state: { is_edit_test },
        setIsEditImaging,
    } = useContext(HealthProfileContextMain);
    // const [error, setError] = useState(false);
    const { languageGlobal } = useContext(ContextProvider);
    useEffect(() => {
        if (imagingPerfomedArr.length === 0 && is_edit_test) {
            setIsEditImaging(false);
        }
    }, []);

    // edited

    return (
        <div className={classes.contentContainerBody}>
            <div className={classes.contentContainerBody} style={{ marginTop: imagingPerfomed && 40 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{mainData['3_tests_question'][0].text}</Typography>
                </div>
                {/* Is existing condition */}
                <div className="">
                    {!is_edit_test && (
                        <Grid
                            style={{
                                marginTop: 20,
                            }}
                            container
                        >
                            {mainData &&
                                mainData['3_tests_answer_group'].map((parts) => (
                                    <Grid lg="auto" sm="auto" xs={12}>
                                        <Button
                                            onClick={() => {
                                                setImagingPerfomed(parts.tests_answer_selector);
                                                if (parts.tests_answer_selector === 'None') {
                                                    setImagingPerfomedArr([]);
                                                    setImagingPerfomedArrEasy([]);
                                                }
                                            }}
                                            disableRipple
                                            className={
                                                imagingPerfomed === parts.tests_answer_selector
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                marginBottom: 20,
                                                width: matches && '100%',
                                                display: matches ? null : 'flex',
                                                marginRight: 15,
                                            }}
                                        >
                                            <div className="">
                                                <Typography className={classes.BtncontentText}>
                                                    {parts.tests_answer[0].text}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </div>

                {/* gray protion */}
                {imagingPerfomedArr && imagingPerfomedArr.length > 0 && (
                    <div className="">
                        <div className={classes.graySection}>
                            <Typography className={classes.mainHeading}>
                                {mainData['3_tests_summary_title'][0].text}
                            </Typography>
                            {imagingPerfomedArr.map((all, index) => (
                                <div className={classes.flexClass}>
                                    <Typography className={classes.subHeading}>
                                        {all.a}. {all.c && getLocalDateMonth(all.c, languageGlobal)}
                                    </Typography>
                                    <div className={classes.flexImg}>
                                        <img
                                            src={pencilIcon}
                                            alt=""
                                            className={classes.icons}
                                            onClick={() => {
                                                if (is_edit_test) {
                                                    setIsEditImaging(false);
                                                }
                                                !editState && editOptionTest(index, all);
                                            }}
                                        />
                                        <img
                                            src={dustbinIcon}
                                            alt=""
                                            className={classes.pointer}
                                            onClick={() => {
                                                if (is_edit_test) {
                                                    setIsEditImaging(false);
                                                }
                                                deleteGrayTest(index, all);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* end */}

                {/* gray section ends */}
                {imagingPerfomed && imagingPerfomed?.toLocaleLowerCase() !== 'none' ? (
                    <div className="">
                        <Typography className={classes.mainHeading}>
                            {mainData['3_tests_subquestion'][0].text}
                        </Typography>
                        <Grid container spacing={1} style={{ marginTop: 20 }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <DateTextField
                                    type="monthAndYear"
                                    separator={mainData.dob_placeholder_separator[0].text}
                                    mainHeading={mainData.enter_date[0].text}
                                    setDOB={setImagingPerfomedDate}
                                    dob={imagingPerfomedDate}
                                    setError={setErrorDate3}
                                    error={errorDate3}
                                    errorMessage={mainData.date_error[0].text}
                                    placeHolderMonth={mainData.dob_placeholder_month[0].text}
                                    placeHolderYear={mainData.dob_placeholder_year[0].text}
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : null}

                {/* for edit in state */}
                {imagingPerfomed &&
                    (is_edit_test
                        ? imagingPerfomedArr.length > 0
                        : imagingPerfomed.length !== 0 && imagingPerfomed.toLocaleLowerCase() !== 'none') && (
                        <div className={classes.maginB}>
                            {!imagingEdit && (
                                <UnderLinedAddButton
                                    onClick={() => {
                                        if (is_edit_test) {
                                            setIsEditImaging(false);
                                            return;
                                        }
                                        if (errorDate3) {
                                            return;
                                        }
                                        addDataTest({
                                            openState: false,
                                            openState2: false,
                                        });
                                    }}
                                    underLineText={mainData.add_another_test_cta[0].text}
                                />
                            )}
                            {imagingEdit && (
                                <div className="">
                                    <div
                                        className=""
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                        }}
                                    >
                                        <FilledButton
                                            text={mainData?.continue_cta[0].text}
                                            disabled={errorDate3}
                                            onPress={() => {
                                                setTestEdit(false);
                                                addDataTest({
                                                    openState: false,
                                                    openState2: false,
                                                });
                                            }}
                                        />
                                        <UnderlinedButton
                                            text={mainData?.cancel[0].text}
                                            onPress={() => {
                                                setTestEdit(false);
                                                deleteEditTest();
                                            }}
                                            noMarginTop
                                            marginLeft={20}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
}
