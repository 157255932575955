import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { useBookingStore } from '@/store';

import { AntSwitch } from '../../../Common/AntSwitchCommon';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';
import SignatureDigitalMobile from './SignatureDigitalMobile';
import SignaturePad from './SignaturePad';

const SignatureFieldMobile = ({ signCanvas, tabNumber, setTabNumber }) => {
    const classes = consentFormsStyles();

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    const handleDrawSign = () => {
        const newTabNumber = tabNumber === 0 ? 1 : 0;
        setTabNumber(newTabNumber);
        useBookingStore.setState({ isSignatureEmpty: newTabNumber === 1 });
    };

    const drawnSignature = tabNumber === 1;
    return (
        <div>
            <div className={clsx(classes.mobileSwichContainer, classes.px24)}>
                <AntSwitch checked={drawnSignature} onChange={handleDrawSign} />
                <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                    {content?.signaturePopup?.useOwnSignature || 'Use my own signature'}
                </Typography>
            </div>
            <div className={clsx(classes.mobilePanel, classes.my10)}>
                {drawnSignature ? <SignaturePad ref={signCanvas} /> : <SignatureDigitalMobile />}
            </div>
        </div>
    );
};

export default SignatureFieldMobile;
