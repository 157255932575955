import { menuItems } from '@/utils/helpers';

import createDataContext from './createDataContext';

const healthContextProvider = (state, action) => {
    switch (action.type) {
        case 'set_step':
            return {
                ...state,
                step: action.payload,
            };
        case 'set_selected_prac':
            return {
                ...state,
                selectedPrac: action.payload,
            };
        case 'set_login_anamnesis':
            return {
                ...state,
                loginAnamnesis: action.payload,
            };
        case 'set_personal_information':
            return {
                ...state,
                personal_information: action.payload,
            };
        case 'set_your_body':
            return {
                ...state,
                your_body: action.payload,
            };
        case 'set_current_problems':
            return {
                ...state,
                current_problems: action.payload,
            };
        case 'set_medications':
            console.debug('medication1: contextq');
            return {
                ...state,
                medications: action.payload,
            };
        case 'set_allergies_new':
            console.debug('Questions6: contexte');
            return {
                ...state,
                allergiesNew: action.payload,
            };
        case 'set_allergies_two':
            console.debug('Questions6: contextq');

            return {
                ...state,
                allergiesTwo: action.payload,
            };
        case 'set_existing_condition':
            return {
                ...state,
                existing_condition: action.payload,
            };
        case 'set_families':
            return {
                ...state,
                families: action.payload,
            };
        case 'set_life_style':
            console.debug('InReducer: lifeStyle', action.payload);
            return {
                ...state,
                life_style: action.payload,
                life_style_context: action.payload,
            };
        case 'save_changes_option':
            return {
                ...state,
                save_changes_option: action.payload,
            };
        case 'page_no_summary':
            return {
                ...state,
                page_no_summary: action.payload,
            };
        case 'set_accepted_file_name':
            return {
                ...state,
                acceptedFileName: action.payload,
            };
        case 'set_file_upload_steps':
            return {
                ...state,
                fileUploadSteps: action.payload,
            };
        case 'set_is_upload_button_disabled':
            return {
                ...state,
                isUploadButtonDisabled: action.payload,
            };
        case 'is_open_state':
            return {
                ...state,
                is_open_state: action.payload,
            };
        case 'whichOneDrugsContext':
            console.debug('reducer123:', action.payload);
            return {
                ...state,
                whichOneDrugs: action.payload,
            };
        case 'set_uploaded_by_me_data':
            return {
                ...state,
                uploadedByMeData: action.payload,
            };
        case 'set_is_document_available':
            return {
                ...state,
                isDocumentAvailable: action.payload,
            };
        case 'is_edit_imaging':
            return {
                ...state,
                is_edit_imaging: action.payload,
            };
        case 'is_edit_test':
            return {
                ...state,
                is_edit_test: action.payload,
            };
        case 'is_edit_pre_existing':
            return {
                ...state,
                is_edit_pre_existing: action.payload,
            };
        case 'review_option':
            return {
                ...state,
                review_option: action.payload,
            };
        case 'is_edit_overview':
            return {
                ...state,
                is_edit_overview: action.payload,
            };
        default:
            return state;
    }
};

const setStep = (dispatch) => async (data) => {
    dispatch({
        type: 'set_step',
        payload: data,
    });
};

// const handelPages = () => {

// }
const pages = {
    0: 'professional',
    1: 'personal-info',
    2: 'your-body',
    3: 'existing-conditions',
    4: 'allergies',
    5: 'medications',
    6: 'families',
    7: 'life-style',
    8: 'summary',
    // 9: "uploaded-by-me",
    // 10:"confirmConsent"
};
const pagesLogout = {
    0: 'professional',
    1: 'personal-info',
    2: 'your-body',
    3: 'existing-conditions',
    4: 'allergies',
    5: 'medications',
    6: 'families',
    7: 'life-style',
    8: 'account',
    9: 'summary',
    // 10: "uploaded-by-me",
    // 11: "confirmConsent",
};

// health-data menu array
const healthDataMenu = menuItems();

const setSelectedPrac = (dispatch) => async (data) => {
    dispatch({
        type: 'set_selected_prac',
        payload: data,
    });
};
// for login
const setLoginAnamnesis = (dispatch) => async (data) => {
    dispatch({
        type: 'set_login_anamnesis',
        payload: data,
    });
};

const UpdateSteps =
    (dispatch) =>
    async (step, history, user, isFromHealthData = false, isAllConsentForms = false, isLastStep = false) => {
        step += 1;
        console.debug('step2:', step);
        dispatch({
            type: 'set_step',
            payload: step,
        });
        console.debug('healthDataMenu.length', healthDataMenu.length, step);

        if (isFromHealthData) {
            if (step === healthDataMenu.length) {
                // resetting step
                dispatch({
                    type: 'set_step',
                    payload: 0,
                });
                history.replace(`/health-profile-overview`);
            } else history.replace(`/health-data?step=${healthDataMenu[step]}`);
        }
    };

const setReviewOption = (dispatch) => async (data) => {
    dispatch({
        type: 'review_option',
        payload: data,
    });
};

const BackBtn =
    (dispatch) =>
    async (step, history, user, isFromHealthData = false) => {
        step -= 1;
        dispatch({
            type: 'set_step',
            payload: step,
        });
        console.debug('step2: 2', step);
        if (isFromHealthData) history.replace(`/health-data?step=${healthDataMenu[step]}`);
    };
// Data for individual pages

const setPersonalInformation = (dispatch) => async (data) => {
    dispatch({
        type: 'set_personal_information',
        payload: data,
    });
};

const setYourBody = (dispatch) => async (data) => {
    dispatch({
        type: 'set_your_body',
        payload: data,
    });
};

const setCurrentProblems = (dispatch) => async (data) => {
    dispatch({
        type: 'set_current_problems',
        payload: data,
    });
};

const setMedications = (dispatch) => async (data) => {
    dispatch({
        type: 'set_medications',
        payload: data,
    });
};

const setAllergies = (dispatch) => async (data) => {
    console.debug('Questions6: context');

    dispatch({
        type: 'set_allergies_new',
        payload: data,
    });
};

const setAllergiesTwo = (dispatch) => async (data) => {
    console.debug('Questions6: context');
    dispatch({
        type: 'set_allergies_two',
        payload: data,
    });
};

const setExistingCondition = (dispatch) => async (data) => {
    dispatch({
        type: 'set_existing_condition',
        payload: data,
    });
};

const setFamilies = (dispatch) => async (data) => {
    dispatch({
        type: 'set_families',
        payload: data,
    });
};

const setLifeStyle = (dispatch) => async (data) => {
    dispatch({
        type: 'set_life_style',
        payload: data,
    });
};

const saveChangesOption = (dispatch) => async (data) => {
    dispatch({
        type: 'save_changes_option',
        payload: data,
    });
};

const setIsOpenState = (dispatch) => async (data) => {
    dispatch({
        type: 'is_open_state',
        payload: data,
    });
};

const setIsEditPreExisting = (dispatch) => async (data) => {
    dispatch({
        type: 'is_edit_pre_existing',
        payload: data,
    });
};

const setIsEditImaging = (dispatch) => async (data) => {
    dispatch({
        type: 'is_edit_imaging',
        payload: data,
    });
};

const setIsEditTest = (dispatch) => async (data) => {
    dispatch({
        type: 'is_edit_test',
        payload: data,
    });
};

const setIsEditOverview = (dispatch) => async (data) => {
    dispatch({
        type: 'save_changes_option',
        payload: true,
    });

    dispatch({
        type: 'is_edit_overview',
        payload: data,
    });
};

const setWhichOneDrugs = (dispatch) => async (data) => {
    dispatch({
        type: 'whichOneDrugsContext',
        payload: data,
    });
};

const PageNoSummary = (dispatch) => async (data) => {
    dispatch({
        type: 'page_no_summary',
        payload: data,
    });
};

const onSaveButton = (dispatch) => async (history, is_edit_overview) => {
    dispatch({
        type: 'save_changes_option',
        payload: false,
    });
    if (is_edit_overview) {
        history.push(`/health-profile-overview`);
    }
};

const onCancelButton = (dispatch) => async () => {
    dispatch({
        type: 'save_changes_option',
        payload: false,
    });
};

const setAcceptedFileName = (dispatch) => async (data) => {
    dispatch({
        type: 'set_accepted_file_name',
        payload: data,
    });
};

const setFileUploadSteps = (dispatch) => async (data) => {
    dispatch({
        type: 'set_file_upload_steps',
        payload: data,
    });
};

const setIsUploadButtonDisabled = (dispatch) => async (data) => {
    dispatch({
        type: 'set_is_upload_button_disabled',
        payload: data,
    });
};

const setUploadedByMeData = (dispatch) => async (data) => {
    dispatch({
        type: 'set_uploaded_by_me_data',
        payload: data,
    });
};

const setIsDocumentAvailable = (dispatch) => async (data) => {
    dispatch({
        type: 'set_is_document_available',
        payload: data,
    });
};

const resetFile = (dispatch) => async () => {
    dispatch({
        type: 'set_accepted_file_name',
        payload: null,
    });
    dispatch({
        type: 'set_file_upload_steps',
        payload: 1,
    });
    dispatch({
        type: 'set_is_upload_button_disabled',
        payload: true,
    });
};

export const { Provider, Context } = createDataContext(
    healthContextProvider,
    {
        setWhichOneDrugs: setWhichOneDrugs,
        setIsEditOverview: setIsEditOverview,
        setReviewOption: setReviewOption,
        setStep: setStep,
        setSelectedPrac: setSelectedPrac,
        UpdateSteps: UpdateSteps,
        setLoginAnamnesis: setLoginAnamnesis,
        BackBtn: BackBtn,
        setPersonalInformation: setPersonalInformation,
        setYourBody: setYourBody,
        setCurrentProblems: setCurrentProblems,
        setMedications: setMedications,
        saveChangesOption: saveChangesOption,
        PageNoSummary: PageNoSummary,
        setAllergies: setAllergies,
        setExistingCondition: setExistingCondition,
        setFamilies: setFamilies,
        setLifeStyle: setLifeStyle,
        onSaveButton: onSaveButton,
        onCancelButton: onCancelButton,
        setAllergiesTwo: setAllergiesTwo,
        // isUserLogedin,
        setIsOpenState: setIsOpenState,
        // File Upload
        setAcceptedFileName: setAcceptedFileName,
        setFileUploadSteps: setFileUploadSteps,
        setIsUploadButtonDisabled: setIsUploadButtonDisabled,
        resetFile: resetFile,
        setIsEditPreExisting: setIsEditPreExisting,
        setIsEditImaging: setIsEditImaging,
        setIsEditTest: setIsEditTest,
        setUploadedByMeData: setUploadedByMeData,
        setIsDocumentAvailable: setIsDocumentAvailable,
    },
    {
        is_edit_overview: false,
        step: 0,
        pagesLogout: pagesLogout,
        selectedPrac: '',
        loginAnamnesis: false,
        personal_information: {
            gender: '',
            title: '',
            firstName: '',
            lastName: '',
            middleName: '',
            email: '',
            dob: '',
            phoneNumber: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            country: '',
            occuption: '',
            countryCode: '',
        },
        your_body: {
            gender: '',
            weight: '',
            gainedOrLost: '',
            kg: '',
            weeks: '',
            height: '',
            pergnant: '',
            mounth: '',
        },
        current_problems: {
            anyProblem: '',
            whichOne: '',
        },
        medications: {
            bloodThinner: '',
            whichBloodThinner: '',
            sleepingPills: '',
            whichSleepingPills: '',
            medicationType: '',
            whichMedication: '',
            allData: [],
        },
        allergiesNew: {
            anyAllergy: '',
            allergyType: '',
            allergyArr: {},
            data2: [],
        },
        allergiesTwo: {
            anyAllergy: '',
            allergyType: '',
            allergyArr: {},
            data2: [],
        },
        existing_condition: {
            pastCondition: '',
            surgeries: '',
            surgeriesArr: [],
            sergeryDataAll: [],
            sergeryQuestion: '',
            sergeryWhen: '',
            newCondition: [],
            whenPastCondition: '',
            imagingArr: [],
            imagingTest: '',
            imagingEasy: [],
        },
        families: {
            relativeDisease: '',
            whichRelative: '',
            relativeAge: '',
            allDisease: [],
            newArray: [],
            dataFamily: [],
        },
        life_style: {
            multipleDiet: '',
            whichDiet: '',
            sports: '',
            weekDays: '',
            exercise: '',
            smoke: '',
            smokePerDay: '',
            smokeHowLong: '',
            alcohol: '',
            alcoholKind: '',
            alcoholPerWeek: '',
            drugs: '',
            whichOtherDietSubQuestion: '',
            whichOneDrugsContext: [],
        },
        pages: pages,
        save_changes_option: false,
        page_no_summary: 0,
        // File Upload
        acceptedFileName: '',
        review_option: false,
        fileUploadSteps: 1,
        isUploadButtonDisabled: true,
        is_open_state: false,
        is_edit_pre_existing: false,
        is_edit_imaging: false,
        is_edit_test: false,
        uploadedByMeData: {},
        isDocumentAvailable: true,
        whichOneDrugs: [{ whichOne: '', howOftenDrugs: '' }],
        life_style_context: {
            multipleDiet: '',
            whichDiet: '',
            sports: '',
            weekDays: '',
            exercise: '',
            smoke: '',
            smokePerDay: '',
            smokeHowLong: '',
            alcohol: '',
            alcoholKind: '',
            alcoholPerWeek: '',
            drugs: '',
            whichOtherDietSubQuestion: '',
            whichOneDrugsContext: [],
        },
    }
);
