import { makeStyles } from '@material-ui/core/styles';

import { isDevEnvironment } from '@/utils/helpers';

const useStyles = makeStyles(() => ({
    versionLabel: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '15px',
        position: 'fixed',
        left: '1rem',
        bottom: '0.75rem',
        color: 'rgba(121,121,121,0.80)',
        mixBlendMode: 'difference',
    },
}));

const DevVersionLabel = () => {
    const classes = useStyles();

    return isDevEnvironment() && <p className={classes.versionLabel}>Version: {import.meta.env.VITE_VERSION}</p>;
};

export default DevVersionLabel;
