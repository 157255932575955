import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { memo, useEffect, useState } from 'react';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import ic_arrow_top from '@/assets/Icons/ic_arrow_top.svg';
import ic_close from '@/assets/Icons/ic_close.svg';

import { useAppointmentStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    searchedItemSelected: {
        display: 'inline-flex',
        marginTop: 15,
        cursor: 'pointer',
    },
    searchedItemSelectedInner: {
        ...theme.typography.content,
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        marginRight: 20,
        padding: '18px 25px',
        borderRadius: 10,
        display: 'flex',
    },
    searchedText: { fontFamily: 'MessinaSans-Regular', paddingRight: 10 },
    dataResult: {
        height: 'auto',
        maxHeight: '12rem',
        overflow: 'hidden',
        overflowY: 'auto',
        cursor: 'pointer',
        marginLeft: 2,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        backgroundColor: theme.palette.common.lightBrown,
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        // paddingTop: 10,
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            // marginLeft: 0,
        },
    },

    dataResultInerMain: {
        padding: '20px 15px',
        display: 'flex',
        scrollbarWidth: 0,
    },
    fieldFocused: {
        backgroundColor: theme.palette.common.darkGrey,
        boxShadow: `0 0 0 1px ${theme.palette.common.yellow}`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        border: `solid 2px gray`,
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    textFields: {
        height: '3.75em',
    },

    inputLabel: {
        '&.focused': {},
        '&.shrink': {
            fontSize: '1rem',
        },
    },

    newWidthContainer: {
        width: '100%',
    },
    showData: {
        fontFamily: 'MessinaSans-Regular',
    },
}));

const AutoCompleteDropDown = ({
    mainDataHeading,
    mainDataPlaceHolder,
    mainDataArray,
    data,
    setData,
    toMatch,
    widthID,
    relation,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [filter, setFilter] = useState([]);
    const [wordEnter, setWordEnter] = useState([]);
    const [wordEnterr, setWordEnterr] = useState([]);
    const [showTreatments, setShowTreatments] = useState(false);
    const [activeWidth, setActiveWidth] = useState('0');
    const setSession = useAppointmentStore((state) => state.setSession);

    useEffect(() => {
        setWordEnterr(data.map((entry) => entry.name));
    }, []);

    const onFiller = (e) => {
        const searchWord = e.target.value ?? '';
        const searchWordd = e.target.value ?? '';
        const newFilter = mainDataArray.filter((value) => {
            return (
                value.name.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) &&
                !wordEnterr.includes(value.name)
            );
        });
        if (searchWord === '') {
            setFilter(newFilter);
        } else {
            setFilter(newFilter);
        }
        console.debug(searchWordd);

        setWordEnter(searchWordd);
        setShowTreatments(true);
        handleClickListItem();
    };

    const testing = (item) => {
        console.debug('first12:', item.name);
        setWordEnter(item.name);
        setWordEnterr([...wordEnterr, item.name]);
        setData([...data, item]);
        setWordEnter('');
        console.debug('[...d, item]', item.name);
        if (relation === 'treatments') {
            setSession({
                ...useAppointmentStore.getState().session,
                threatment: [...data, item],
            });
        }
    };

    const handleAddRequest = (event) => {
        if (event.target.value !== '' && event.key === 'Enter') {
            setWordEnterr([...wordEnterr, wordEnter]);
            console.debug(wordEnterr);
        }
    };

    const removeData = (event, key, eachData) => {
        console.debug('key index: ', key);
        console.debug(eachData);
        setWordEnterr([...wordEnterr.slice(0, key), ...wordEnterr.slice(key + 1, wordEnterr.length)]);
        setData([...data.slice(0, key), ...data.slice(key + 1, wordEnterr.length)]);
        if (relation === 'treatments') {
            setSession({
                ...useAppointmentStore.getState().session,
                threatment: [...data.slice(0, key), ...data.slice(key + 1, wordEnterr.length)],
            });
        }
    };

    const handleClickListItem = () => {
        const w = document.getElementById(widthID).clientWidth;
        console.debug(w);
        setActiveWidth(w.toString());
    };

    const imageClicked = (e) => {
        document.getElementById(widthID).focus();
        onFiller(e);
    };

    return (
        <>
            <div className={classes.newWidthContainer}>
                <div>
                    <Grid
                        style={{
                            marginRight: 20,
                            width: '100%',
                        }}
                        container
                    >
                        <TextField
                            id={widthID}
                            label={mainDataHeading}
                            placeholder={mainDataPlaceHolder}
                            variant="filled"
                            color="primary"
                            autoComplete="off"
                            value={wordEnter}
                            fullWidth
                            onBlur={() => {
                                setTimeout(() => {
                                    setShowTreatments(false);
                                }, [200]);
                            }}
                            onFocus={onFiller}
                            onChange={onFiller}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                width: matchesMd ? '100%' : '60%',
                                marginTop: 20,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" disableRipple>
                                            {showTreatments && filter.length > 0 ? (
                                                <img src={ic_arrow_top} />
                                            ) : (
                                                <img src={ic_arrow_bottom} onClick={(e) => imageClicked(e)} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyPress={handleAddRequest}
                        />
                    </Grid>
                </div>

                {filter.length !== 0 && showTreatments && (
                    <div className={classes.dataResult} style={{ width: parseInt(activeWidth) + 58 }}>
                        {filter.map((data) => {
                            if (toMatch && !data.isPublicInsruance && toMatch === 'PUBLIC') return;
                            if (toMatch && !data.isPrivateInsurance && toMatch === 'PRIVATE') return;
                            if (toMatch && !data.isSelfPayer && toMatch === 'SELF-PAYER') return;
                            return (
                                <div
                                    className={classes.dataResultInerMain}
                                    onClick={() => testing(data)}
                                    style={{
                                        padding: filter.length > 1 ? '13px 15px' : '20px 15px',
                                    }}
                                >
                                    <p
                                        className={classes.showData}
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {data.name}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            {wordEnterr.length !== 0 &&
                wordEnterr.map((eachData, key) => (
                    <div className={classes.searchedItemSelected} key={key}>
                        <div className={classes.searchedItemSelectedInner}>
                            <Typography className={classes.searchedText}>{eachData}</Typography>
                            <img src={ic_close} alt="" onClick={(event) => removeData(event, key, eachData)} />
                        </div>
                    </div>
                ))}
        </>
    );
};
export default memo(AutoCompleteDropDown);
