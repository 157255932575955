import { CircularProgress, DialogContentText, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useShallow } from 'zustand/react/shallow';

import { Context as AuthContext } from '@/context/AuthContext';

import { getErrorMessageFromPrismicContent } from '@/utils/errorMessages';
import { downloadDocument, validateCode } from '@/utils/helpers';

import { useDownloadDocumentModalStore } from '../../../store';
import FilledButton from '../../FilledButton';
import UnderlinedButton from '../../UnderlinedButton';

const commonStyles = { fontFamily: 'MessinaSans-Regular', border: '1px solid #D8DADC', outline: 'none', width: '100%' };

const codeStyles = {
    ...commonStyles,
    height: '11.25rem',
    borderRadius: 15,
    fontSize: '4rem',
};

const codeStylesTab = {
    ...commonStyles,
    height: '7.5rem',
    borderRadius: 10,
    fontSize: '2.5rem',
};

const codeStylesMobile = {
    ...commonStyles,
    height: '2.5rem',
    borderRadius: 5,
    fontSize: '1.25rem',
};

const containerStyles = {
    gap: '0.8rem',
};

const errorBorder = { border: '1px solid #da3030' };

const InputCode = ({ classes, isResendDisabled, resendButtonText, handleRequestCode, error, setError, content }) => {
    const [code, setCode] = useState('');
    const [isPendingDownload, setIsPendingDownload] = useState(false);

    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const codeStyle = matchesXs ? codeStylesMobile : matchesSm || matchesMd ? codeStylesTab : codeStyles;

    const { documentId, closeModal } = useDownloadDocumentModalStore(
        useShallow(({ documentId, closeModal }) => ({ documentId: documentId, closeModal: closeModal }))
    );

    const {
        state: { usersession },
        fetchUserDocumentById,
    } = useContext(AuthContext);

    const isButtonDisabled = code.length !== 6 || isPendingDownload;

    const handleChange = (codeFromInput) => {
        setError(undefined);
        setCode(codeFromInput);
    };

    const handleSubmit = async () => {
        setIsPendingDownload(true);
        try {
            await validateCode(usersession, code);
            const downloadURL = await fetchUserDocumentById(documentId, usersession);
            downloadDocument(downloadURL.download_url);
            closeModal();
        } catch (e) {
            setError(getErrorMessageFromPrismicContent(e.response?.data.error_code, content));
            console.debug('Error: ', e);
        }
        setIsPendingDownload(false);
    };

    return (
        <>
            <DialogContentText
                id="alert-dialog-description"
                classes={{
                    root: classes.text,
                }}
            >
                {content?.input_description[0]?.text}
            </DialogContentText>
            <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={6}
                inputStyle={codeStyle}
                containerStyle={containerStyles}
                hasErrored={!!error}
                errorStyle={errorBorder}
            />
            {error && <p className={classes.errorText}>{error}</p>}
            <div className={classes.btnContainer}>
                <UnderlinedButton text={resendButtonText} onPress={handleRequestCode} disabled={isResendDisabled} />
                <FilledButton
                    disabled={isButtonDisabled || !!error}
                    text={isPendingDownload ? <CircularProgress /> : (content?.download_cta[0].text ?? 'Download')}
                    onPress={handleSubmit}
                    fullWidth={matchesXs}
                />
            </div>
        </>
    );
};

export default InputCode;
