import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';

import ic_arrow_down_black from '@/assets/Icons/ic_down_arrow_black.svg';

import ToolTipBox from '@/components/Common/ToolTipBox';
import FilledButton from '@/components/FilledButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { yourBodyGetAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';

import AppointmentDetailsDocuments from './AppointmentDetailsDocuments';
import MyAppointmentDetailsCard from './MyAppointmentDetailsCard';

const useStyles = makeStyles((theme) => ({
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginRight: '.4rem',
        cursor: 'pointer',
    },
    showDetails: {
        marginTop: '1.3rem',
        textAlign: 'center',
        fontFamily: 'MessinaSans-Regular',
        display: 'flex',
        justifyContent: 'center',
    },
    line: {
        height: '.06rem',
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: '1.3rem',
    },
    showAllBtn: {
        marginTop: '1.3rem',
    },
}));

const UpcomingCard = ({
    dataSet,
    mainData,
    upComming,
    dataSetDoctos,
    moveAppointmentToCancelled,
    debugingParams = 0,
}) => {
    const classes = useStyles();
    const { setAllDataApi } = useContext(ContextProviderTwo);

    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const [showAll, setShowAll] = useState(false);
    const [hideAll, setHideAll] = useState(true);
    const [startDate] = useState(new Date());
    const [resDate, setResDate] = useState();
    console.debug('mainDatadoctos: upComming', upComming);

    const msToTime = (s) => {
        const mins = Math.floor(s / 60000);
        console.debug('mainDatadoctos: mins', mins, upComming?.is_online);
        setResDate(mins);
    };

    useEffect(() => {
        const d = new Date(upComming?.start);
        const resultDate = Math.abs(startDate.getTime() - d.getTime());
        msToTime(resultDate);
    }, [upComming.is_online]);

    useEffect(() => {
        if (user) {
            yourBodyGetAPI(usersession)
                .then((res) => {
                    console.debug('Body1 get from Upcomming page', res);
                    setAllDataApi(res?.data);
                })
                .catch((error) => {
                    console.debug('body1 error', error);
                });
        }
    }, [debugingParams]);

    const disabledToolTip = !upComming.links.online_url || resDate > 30;

    return (
        <div>
            <div className="">
                <MyAppointmentDetailsCard
                    dataSet={dataSet}
                    mainData={mainData}
                    upComming={upComming}
                    dataSetDoctos={dataSetDoctos}
                    moveAppointmentToCancelled={moveAppointmentToCancelled}
                />
            </div>

            {upComming?.is_online && (
                <ToolTipBox text={disabledToolTip ? mainData?.tool_tip_for_online_appointment[0].text : ''}>
                    <div
                        style={{
                            width: 'fit-content',
                        }}
                    >
                        <FilledButton
                            text={dataSet ? mainData.online_appointment_cta[0].text : 'Join Online Appointment'}
                            onPress={() => {
                                window.open(upComming.links.online_url, '_blank', 'noreferrer');
                            }}
                            disabled={disabledToolTip}
                        />
                    </div>
                </ToolTipBox>
            )}

            {upComming?.status !== 'CANCELLED' && upComming?.is_cancellation_confirmed === false && hideAll && (
                <div
                    className={classes.showDetails}
                    onClick={() => {
                        setShowAll(true);
                        setHideAll(false);
                    }}
                >
                    <Typography className={classes.shortText}>
                        {dataSet ? mainData.show_details_cta[0].text : 'Show details'}
                    </Typography>
                    <img src={ic_arrow_down_black} alt="" />
                </div>
            )}
            {showAll && (
                <div className={classes.showAllBtn}>
                    <div className={classes.line} />
                    <AppointmentDetailsDocuments
                        dataSet={dataSet}
                        mainData={mainData}
                        appointment={upComming}
                        setShowAll={setShowAll}
                        setHideAll={setHideAll}
                        enableFillDigitalDocument
                    />
                </div>
            )}
        </div>
    );
};

export default UpcomingCard;
