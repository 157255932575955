import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Context as AuthContext } from '@/context/AuthContext';

import useProfessionalsList from '@/hooks/useProfessionalsList';

import { usePersistedPrismicStore, useProfessionalsStore } from '@/store';

import { useStyles } from './MyDoctors/DoctorClasses';
import RenderData from './MyDoctors/RenderData';

export default function MyDoctors() {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { mainData, dataSet },
    } = usePersistedPrismicStore((state) => state.professionalProfileData);

    const { hubProfessionals } = useProfessionalsStore((state) => state);

    useEffect(() => {
        if (hubProfessionals.length === 1) history.replace(`/professional/${hubProfessionals[0]?.key[0].text}`);
    }, [hubProfessionals]);

    const {
        state: { usersession },
    } = useContext(AuthContext);

    const { loading } = useProfessionalsList(usersession);

    return (
        <>
            {dataSet && (
                <div className={classes.contentContainerBody}>
                    {!loading && <RenderData mainData={mainData} isLoggedIn={!!usersession} />}
                </div>
            )}
        </>
    );
}
// 14
