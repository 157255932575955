import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Consent Manager Context
import { Context as ConsentManagerContext } from '@/context/ConsentManagerContext';

import { usePersistedPrismicStore } from '@/store';

const useStyles = makeStyles({
    tabsContainer: {
        width: '100%',
        borderBottom: '2px solid #F5F5F5',
    },
    tab: {
        color: '#ABABAB',
        textTransform: 'none',
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
});

const StyledTabs = withStyles((theme) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: '5px',
        '& > span': {
            width: '100%',
            backgroundColor: theme.palette.common.yellow,
        },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#ABABAB',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#2E2E2E',
            fontFamily: 'MessinaSans-SemiBold',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.pxToRem(10),
        },
    },
}))((props) => <Tab disableRipple {...props} />);

export default function TableTabs() {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [value, setValue] = useState(0);

    const { setTableType } = useContext(ConsentManagerContext);

    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { content: consentData } = consentPrismicData;

    const handleChange = useCallback(
        (e, newValue) => {
            setValue(newValue);
            setTableType(newValue);
        },
        [value]
    );

    return (
        <div className={classes.tabsContainer}>
            <StyledTabs value={value} onChange={handleChange} className={classes.tabParent}>
                <StyledTab
                    label={
                        consentData ? consentData.data_operations_table_tab_document_type[0].text : 'By Document Type'
                    }
                    className={classes.tab}
                />
                <StyledTab
                    label={consentData ? consentData.data_operations_table_tab_doctor[0].text : 'By Doctor'}
                    className={classes.tab}
                />
            </StyledTabs>
        </div>
    );
}
