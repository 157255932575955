import { Auth } from 'aws-amplify';
import axios from 'axios';

import { useConfigStore } from '@/store';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const PUBLIC_API_PATH = 'public/user/form-data';
const PRIVATE_API_PATH = 'secure/user/form-data';

/**
 * Send Consent Form - API | Public API
 *
 * @param {object} body
 * @param {string} version
 * @returns {Promise} response
 */
export const consentFormServicePublic = async (body, version) => {
    const { currentCustomer } = useConfigStore.getState();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            version: version ?? 'v2',
            customer_id: currentCustomer?.customer_id?.[0].text,
        },
        validateStatus: (status) => (status >= 200 && status < 300) || status === 504,
    };

    try {
        return await axios.post(`${BASE_API_URL}/${PUBLIC_API_PATH}/send-form`, body, config);
    } catch (err) {
        console.debug('Error:', err);
        throw new Error(err);
    }
};

/**
 * Send Consent Form - API | Secure API
 *
 * @param {object} body
 * @param {string} version
 * @returns {Promise} response
 */
export const consentFormServiceSecure = async (body, version) => {
    try {
        const { currentCustomer } = useConfigStore.getState();
        const authUser = await Auth.currentAuthenticatedUser();
        const user = await authUser.signInUserSession;
        const user_id = user?.idToken.payload.sub;
        const token = user?.idToken.jwtToken;
        return await axios.post(`${BASE_API_URL}/${PRIVATE_API_PATH}/${user_id}/share-form`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                version: version ?? 'v2',
                customer_id: currentCustomer?.customer_id?.[0].text,
            },
            validateStatus: (status) => (status >= 200 && status < 300) || status === 504,
        });
    } catch (err) {
        console.debug('Error:', err);
        throw new Error(err);
    }
};

/**
 * Send Consent Form - API | Update Form
 *
 * @param {object} body
 * @returns {Promise} response
 */
export const consentFormServiceUpdate = async (body) => {
    try {
        const authUser = await Auth.currentAuthenticatedUser();
        const user = await authUser.signInUserSession;
        const user_id = user?.idToken.payload.sub;
        const token = user?.idToken.jwtToken;
        const response = await axios.post(`${BASE_API_URL}/${PRIVATE_API_PATH}/update/${user_id}`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return response;
    } catch (err) {
        console.debug('Error:', err);
    }
};

/**
 * Get Single Form Temlate | Public API
 *
 * @param {string} professional_id
 * @param {string} template_id
 * @returns {Promise} response
 */
export const getSingleFormTemplate = async (professional_id, template_id) => {
    try {
        const API_URL = `${BASE_API_URL}/${PUBLIC_API_PATH}/template?professional_id=${professional_id}&template_id=${template_id}`;
        const res = await axios.get(API_URL);
        return res.data;
    } catch (error) {
        console.debug('Error:', error);
    }
};

/**
 * Retrieves form templates based on the provided parameters.
 *
 * @param {object} options - The options object.
 * @param {string} options.userId - The user ID.
 * @param {string} options.professional_id - The professional ID.
 * @param {string} options.insurance_type - The insurance type.
 * @param {Array} options.appointment_type_keys - The appointment type keys.
 * @return {Promise} A Promise that resolves to the form templates data.
 */
export const getRequiredTemplates = async ({ userId, professional_id, insurance_type, appointment_type_keys }) => {
    try {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;

        const authUser = await Auth.currentAuthenticatedUser();
        const user = await authUser.signInUserSession;
        const token = user?.idToken.jwtToken;
        const API_URL = `${BASE_API_URL}/${PRIVATE_API_PATH}/required-templates?user_id=${userId}&professional_id=${professional_id}&insurance_type=${insurance_type}&appointment_type_keys=${appointment_type_keys}`;
        const res = await axios.get(API_URL, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        });
        return res.data;
    } catch (error) {
        console.debug('Error:', error);
    }
};

/**
 * Retrieves the shared documents for a given user.
 *
 * @param {Object} options - The options for retrieving the shared documents.
 * @param {string} options.userId - The ID of the user.
 * @return {Promise<Array>} A Promise that resolves to an array of shared documents.
 */
export const getSharedDocuments = async ({ userId }) => {
    try {
        const authUser = await Auth.currentAuthenticatedUser();
        const user = await authUser.signInUserSession;
        const token = user?.idToken.jwtToken;
        const API_URL = `${BASE_API_URL}/${PRIVATE_API_PATH}/documents?user_id=${userId}`;
        const res = await axios.get(API_URL, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return res.data;
    } catch (error) {
        console.debug('Error:', error);
    }
};

/**
 * Forms Request Handler
 *
 * @param { body, isHerakles = false, isLoggedIn = false }
 * @returns {Promise} response
 */
export const formsRequestHandler = async ({ body, isHerakles = false, isLoggedIn = false }) => {
    try {
        const endPoint = isHerakles ? 'save-external-form' : 'send-form';

        if (isHerakles) {
            const res = await axios.post(`${BASE_API_URL}/${PUBLIC_API_PATH}/${endPoint}`, body, {
                headers: {
                    version: 'v2',
                },
            });
            return res.body;
        }

        if (isLoggedIn) {
            const res = await consentFormServiceSecure(body);
            return res.body;
        }

        const res = await consentFormServicePublic(body);
        return res.body;
    } catch (error) {
        console.debug('Error:', error);
    }
};
