import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

/** ICONS */
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import checkedIcon from '@/assets/images/checkedIcon.svg';
import uncheckedIcon from '@/assets/images/uncheckedIcon.svg';

import { languageMap } from '@/utils/helpers';

import { ConsentStyles } from '..';
import FilledButton from '../../FilledButton';

const ThankYou = ({
    icon,
    iconHeight,
    iconWidth,
    title,
    centerParagraph,
    paragraph,
    pages,
    completed = false,
    buttonText = '',
    onButtonClick = () => {},
}) => {
    const classes = ConsentStyles();
    const { i18n } = useTranslation();
    const lan = languageMap[i18n.language];
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Box className={classes.welcomeContainer}>
            {/* Image holder */}
            <div className={classes.welcomeImageContainer}>
                <img
                    src={icon || WelcomeIcon}
                    className={clsx(classes.welcomeImage, classes.textUnSelect)}
                    height={iconHeight || 'auto'}
                    width={iconWidth || 'auto'}
                    alt="eterno-thankyou-icon"
                />
            </div>
            {/* Header */}
            <div className={classes.welcomeHeader}>
                <Typography
                    variant="h2"
                    className={clsx(
                        classes.thankYouHeading,
                        classes.textUnSelect,
                        lan === languageMap.en ? classes.mx30 : null
                    )}
                >
                    {title}
                </Typography>
            </div>
            {!completed ? (
                <div className={classes.welcomeBody}>
                    <Typography variant="body1" className={clsx(classes.paragraph, classes.textUnSelect, classes.mx20)}>
                        {centerParagraph}
                    </Typography>
                </div>
            ) : null}
            {pages ? (
                <div
                    className={clsx(
                        classes.dFlex,
                        classes.contentCenter,
                        classes.flexWrap,
                        classes.gap20,
                        matchesXS ? classes.directionCol : null
                    )}
                >
                    {pages.map((page) => {
                        const name = typeof page.name === 'object' ? page.name[i18n.language] : page.name;
                        return (
                            <div className={clsx(classes.dFlex, classes.gap5)} key={name}>
                                <img
                                    src={page.active ? checkedIcon : uncheckedIcon}
                                    className={classes.textUnSelect}
                                    alt="eterno-icon"
                                />
                                <Typography className={clsx(classes.paragraph, classes.textUnSelect, classes.bold)}>
                                    {name}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {/* Body */}
            {completed ? (
                <div className={classes.welcomeBody}>
                    <Typography variant="body1" className={clsx(classes.paragraph, classes.textUnSelect, classes.mx20)}>
                        {paragraph}
                    </Typography>
                </div>
            ) : null}

            {buttonText ? (
                <div>
                    <FilledButton text={buttonText} onPress={onButtonClick} />
                </div>
            ) : null}
        </Box>
    );
};

export default ThankYou;
