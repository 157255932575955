import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dustbinIcon from '@/assets/images/dustbinIcon.png';
import pencilIcon from '@/assets/images/pencilIcon.png';

import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import useFormSteps from '@/hooks/useFormSteps';

import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';

import { menuItems, trackStormlyAnamnesisSection } from '@/utils/helpers';

import { useTrackingStore } from '@/store';

import MedicationBloodThinner from './MedicationComponents/MedicationBloodThinner';
import { useStyles } from './MedicationComponents/MedicationClasses';
import MedicationSleepingTablets from './MedicationComponents/MedicationSleepingTablets';
import UnderLinedAddButton from './UnderLinedAddButton/UnderLinedAddButton';

const Medications = ({ mainData, user, usersession, isFromHealthData = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [allDataTwo, setAllDataTwo] = useState([]);
    const [cancelEdit, setCancelEdit] = useState('');
    const [counter, setCounter] = useState(0);
    const {
        bloodThinner,
        whichBloodThinner,
        medicationType,
        whichMedication,
        editOptions,
        setBloodThinner,
        setWhichBloodThinner,
        setMedicationType,
        setWhichMedication,
        setAllData,
        setEditOptions,
        setBody3,
        body3,
        allDataApi,
        setSleepingPills,
        sleepingPills,
        whichSleepingPills,
        setWhichSleepingPills,
        setHasAccount,
        newFromTrue,
        setNewFromTrue,
        allData,
    } = useContext(ContextProviderTwo);
    const medicationIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'medications');
    useEffect(() => {
        if (user && allDataApi !== null && allDataApi !== undefined && body3 === null) {
            if (
                allDataApi?.category_data[medicationIndex] &&
                allDataApi?.category_data[medicationIndex].category === 'medications'
            ) {
                const category2 = allDataApi?.category_data[medicationIndex].data;
                setBloodThinner(category2?.blood_thinners.active ? 'YES' : 'NO');
                setWhichBloodThinner(category2?.blood_thinners.value);
                setSleepingPills(category2?.sleeping_pills.active ? 'YES' : 'NO');
                setWhichSleepingPills(category2?.sleeping_pills.value);
                setMedicationType(category2?.active ? 'YES' : 'NO');
                setAllData(category2?.medications.length > 0 ? category2?.medications : []);
                setBody3({});
            }
        }
    }, [allDataApi]);

    const messageRef = useRef();

    useEffect(() => {
        messageRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }, [counter]);

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    const addData = (adding = false) => {
        console.debug('heko');
        allDataTwo.push(whichMedication);
        setWhichMedication('');
        setAllData([...allData, ...allDataTwo]);
        const allDataFunction = [...allData, ...allDataTwo];
        const whichMedicationFunction = '';
        setEditOptions(false);
        setAllDataTwo([]);
        console.debug('medication1: adding', adding, allDataFunction, whichMedicationFunction);
        if (adding) {
            medicationss(adding, allDataFunction, whichMedicationFunction);
        }
    };

    const editMedication = (index, all) => {
        setCancelEdit(all);
        const filterdAll = allData.filter((a) => a !== all);
        setAllData(filterdAll);
        setEditOptions(true);
        setWhichMedication(all);
        console.debug('medication1: delete edit', allData);
    };

    const deleteMedication = (index, all) => {
        const filterdAll = allData.filter((a) => a !== all);
        setAllData(filterdAll);
        console.debug('medication1: delete edit', allData);
    };
    const {
        state: { step, save_changes_option, medications, is_open_state, is_edit_overview },
        BackBtn,
        UpdateSteps,
        setMedications,
        onSaveButton,
        onCancelButton,
        setIsOpenState,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    const deleteEdit = () => {
        console.debug('medication1: delete edit', cancelEdit);
        allData.push(cancelEdit);
        setCancelEdit('');
        setWhichMedication('');
        setEditOptions(false);
    };

    const medicationss = (adding = false, allDataFunction = [], whichMedicationFunction = '') => {
        console.debug('medication1: ssssss', adding, allDataFunction, whichMedicationFunction);

        if (medicationDisabledFunction()) {
            setCounter(counter + 1);
            setNewFromTrue(true);
            return;
        }

        setNewFromTrue(false);

        if (bloodThinner === '' || medicationType === '') {
            console.debug('medication: error occurs');
            return;
        }

        if (adding) {
            console.debug('medication1: if ander');
            setMedications({
                bloodThinner: bloodThinner,
                whichBloodThinner: whichBloodThinner,
                medicationType: medicationType,
                sleepingPills: sleepingPills,
                whichSleepingPills: whichSleepingPills,
                whichMedication: whichMedicationFunction,
                allData: allDataFunction,
            });
            medicationApi({ allDataFunction: allDataFunction });
        } else {
            console.debug('medication1: if ander else');

            setMedications({
                bloodThinner: bloodThinner,
                whichBloodThinner: whichBloodThinner,
                sleepingPills: sleepingPills,
                whichSleepingPills: whichSleepingPills,
                medicationType: medicationType,
                whichMedication: whichMedication,
                allData: allData,
            });
            medicationApi({ allDataFunction: null });
        }
        // if (is_edit_overview){

        // }
        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);
            onSaveButton(history, is_edit_overview);
        }
    };

    const Cancel = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        setBloodThinner(medications.bloodThinner);
        setWhichBloodThinner(medications.whichBloodThinner);
        setSleepingPills(medications.sleepingPills);
        setWhichSleepingPills(medications.whichSleepingPills);
        setMedicationType(medications.medicationType);
        setWhichMedication(medications.whichMedication);
        setAllData(medications.allData);

        onCancelButton(history);
    };

    const medicationApi = ({ allDataFunction }) => {
        const user_idLocal = user ? usersession?.idToken.payload.sub : '';
        const medicationArrayApi =
            allDataFunction !== null && allDataFunction !== undefined ? allDataFunction : allData;
        const body = {
            user_id: user_idLocal,
            category: 'medications',
            data: {
                blood_thinners: {
                    active: bloodThinner === 'YES',
                    value: whichBloodThinner,
                },
                sleeping_pills: {
                    active: sleepingPills === 'YES',
                    value: whichSleepingPills,
                },
                active: medicationType === 'YES',
                medications: medicationArrayApi,
            },
        };
        console.debug('Body1', body);
        setBody3(body);
        user && yourBodyAPI(usersession, body);
    };

    const medicationDisabledFunction = () => {
        return (
            bloodThinner === '' ||
            medicationType === '' ||
            sleepingPills === '' ||
            (medicationType === 'YES' && whichMedication === '' && allData.length === 0)
        );
    };

    const medicationValidate =
        medicationType === '' || (medicationType === 'YES' && whichMedication === '' && allData.length === 0);

    return (
        <div className={classes.contentContainerBody}>
            <div
                className={bloodThinner === '' && (isFromHealthData || newFromTrue) ? classes.highlight : classes.main}
                ref={bloodThinner === '' ? messageRef : null}
            >
                <MedicationBloodThinner mainData={mainData} user={user} />
            </div>
            {/* blood thinner ends */}
            {/* sleeping tablets start */}
            <div
                className={sleepingPills === '' && (isFromHealthData || newFromTrue) ? classes.highlight : classes.main}
                style={{
                    marginTop: isFromHealthData ? sleepingPills && (matches ? 20 : 40) : 40,
                }}
                ref={bloodThinner !== '' && sleepingPills.length === 0 ? messageRef : null}
            >
                <MedicationSleepingTablets mainData={mainData} isFromHealthData={isFromHealthData} />
            </div>
            {/* sleeping tablets ends */}
            {/* medication */}
            <div
                className={clsx(
                    classes.fixedQuestion,
                    (medicationType === '' ||
                        (medicationType === 'YES' && whichMedication === '' && allData.length === 0)) &&
                        (isFromHealthData || newFromTrue)
                        ? classes.highlight
                        : classes.main
                )}
                style={{
                    marginTop: isFromHealthData ? medicationType && (matches ? 20 : 40) : 40,
                }}
                ref={bloodThinner !== '' && sleepingPills !== '' && medicationValidate ? messageRef : null}
            >
                <Typography className={classes.mainHeading}>{mainData.medications_question[0].text}</Typography>
                <Grid
                    style={{
                        marginTop: 15,
                    }}
                    container
                >
                    {mainData &&
                        mainData.medications_responses.map((parts) => (
                            <Grid lg="auto" sm="auto" xs={6}>
                                <Button
                                    onClick={() => {
                                        console.debug(parts.medications_response_api_value);
                                        setMedicationType(parts.medications_response_api_value);
                                        console.debug('clicked1', is_open_state);
                                        if (parts.medications_response_api_value === 'NO') {
                                            setAllData([]);
                                        }
                                        if (parts.medications_response_api_value === 'YES') {
                                            if (is_open_state) setIsOpenState(false);
                                        }
                                    }}
                                    disableRipple
                                    className={
                                        medicationType === parts.medications_response_api_value
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matches && 10,
                                        width: matches && '90%',
                                        display: matches ? null : 'flex',
                                        marginRight: 15,
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {parts.medications_yes_no_responses[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        ))}
                </Grid>

                {/* </div> */}
                {/* gray protion */}
                {medicationType.toLocaleLowerCase() === 'yes' && allData.length > 0 && (
                    <div className={classes.graySection}>
                        <Typography className={classes.mainHeading}>
                            {mainData.your_medications_summary_title[0].text}
                        </Typography>

                        {allData.map((all, index) => (
                            <div className={classes.flexClass}>
                                <Typography
                                    style={{ wordBreak: 'break-all', paddingRight: 5 }}
                                    className={classes.mainHeading}
                                >
                                    {all}
                                </Typography>
                                <div className="" style={{ display: 'flex' }}>
                                    <img
                                        src={pencilIcon}
                                        alt=""
                                        className={classes.icons}
                                        onClick={() => {
                                            if (is_open_state) {
                                                setIsOpenState(false);
                                            }
                                            editMedication(index, all);
                                        }}
                                    />
                                    <img
                                        src={dustbinIcon}
                                        alt=""
                                        className={classes.pointer}
                                        onClick={() => {
                                            {
                                                if (is_open_state) {
                                                    setIsOpenState(false);
                                                }
                                                deleteMedication(index, all);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/*  */}
                {medicationType.toLocaleLowerCase() === 'yes' && !is_open_state && (
                    <div className="">
                        {editOptions && (
                            <Typography className={classes.mainHeading} style={{ marginTop: 20 }}>
                                {mainData.edit_medications[0].text}
                            </Typography>
                        )}
                        <TextField
                            id="medicationSubQuestionWhich"
                            label={mainData ? mainData.medications_subquestion_which[0].text : t('TransferSlip')}
                            variant="filled"
                            autoComplete="off"
                            color="primary"
                            value={whichMedication}
                            onChange={(event) => {
                                setWhichMedication(event.target.value);
                            }}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                width: matches ? '100%' : '100%',
                                flex: matches ? 1 : 0.5,
                                marginTop: !matches && 20,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                            onKeyPress={(event) => {
                                if (whichMedication.length > 0 && event.key === 'Enter') {
                                    console.debug('medication1: Entering...');
                                    addData();
                                }
                            }}
                        />
                    </div>
                )}
            </div>

            {medicationType.toLocaleLowerCase() === 'yes' &&
                !editOptions &&
                (is_open_state ? allData.length > 0 : true) && (
                    <UnderLinedAddButton
                        onClick={() => {
                            if (is_open_state) {
                                setIsOpenState(false);
                                return;
                            }
                            whichMedication.length > 0 && addData();
                        }}
                        underLineText={mainData.add_medication_cta[0].text}
                        marginTop
                    />
                )}
            {medicationType.toLocaleLowerCase() === 'yes' &&
                editOptions &&
                (is_open_state ? allData.length > 0 : true) && (
                    <div className="">
                        <div
                            className=""
                            style={{
                                marginTop: 30,
                                display: 'flex',
                                justifyContent: 'start',
                            }}
                        >
                            <FilledButton
                                text={mainData?.continue_cta[0].text}
                                onPress={() => {
                                    whichMedication.length > 0 && addData();
                                }}
                            />
                            <UnderlinedButton
                                text={mainData?.cancel[0].text}
                                onPress={() => {
                                    deleteEdit();
                                }}
                                noMarginTop
                                marginLeft={20}
                            />
                        </div>
                    </div>
                )}

            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: matches && 'column-reverse',
                }}
            >
                {!save_changes_option ? (
                    <OutlinedButton
                        text={mainData?.back_cta[0].text}
                        onPress={() => {
                            BackBtn(step, history, user, isFromHealthData);
                            setNewFromTrue(false);
                        }}
                        fullWidth={matches}
                    />
                ) : (
                    <OutlinedButton
                        text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                        onPress={() => {
                            Cancel();
                            setNewFromTrue(false);
                        }}
                        fullWidth={matches}
                    />
                )}
                {!save_changes_option ? (
                    <FilledButton
                        text={mainData?.continue_cta[0].text}
                        onPress={() => {
                            if (medicationType === 'YES' && whichMedication.length > 0) {
                                const adding = true;
                                addData(adding);
                                return;
                            }
                            medicationss();
                        }}
                        fullWidth={matches}
                        marginBottom={matches && 20}
                    />
                ) : (
                    <FilledButton
                        text={mainData?.save_cta[0].text}
                        onPress={() => {
                            if (medicationType === 'YES' && whichMedication.length > 0) {
                                const adding = true;
                                addData(adding);
                                return;
                            }
                            medicationss();
                        }}
                        fullWidth={matches}
                        marginBottom={matches && 20}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(Medications);
