import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import onlineAccess from '@/assets/Illustrations/Online-Acces.svg';

import FilledButton from '@/components/FilledButton';
import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';

import { usePersistedPrismicStore, useUploadDocumentModalStore } from '@/store';

import HealthContainer from '../HealthContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'space-between',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            padding: '2rem',
        },
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100%',
    },
    title: {
        fontSize: '1.5rem',
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 'normal',
    },
    bulletPoints: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '66%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    description: {
        color: '#2e2e2e',
        width: '100%',
        fontSize: '1rem',
        marginTop: '1rem',
        fontFamily: 'MessinaSans-Regular',
        lineHeight: '1.5rem',
    },
    image: {
        marginLeft: '2rem',
        display: 'flex',
        alignItems: 'center',
        width: '33%',
        height: '300px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

// TODO: we need to fix this in prismic and remove this function
const SplitEnglishTranslation = (text, classes) => {
    // Split the text at the first colon
    const parts = text?.split(':');
    const subtitle = parts?.[0]?.trim();
    let bulletPoints = [];

    if (parts?.length > 1) {
        const rest = parts?.slice(1).join(':').trim();

        const regex = /(?<=[a-z])\s+(?=[A-Z])/g;
        bulletPoints = rest
            .split(regex)
            .map((point) => point.trim())
            .filter(Boolean);
    }
    return (
        <>
            <Typography className={classes.description}>{subtitle}</Typography>
            {bulletPoints.length > 0 && (
                <ul>
                    {bulletPoints.map((point) => (
                        <li className={classes.description} key={point}>
                            {point}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

const NoDocumentsPlaceholder = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const openModal = useUploadDocumentModalStore((state) => state.openModal);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const {
        state: { user },
    } = useContext(AuthContext);

    return (
        <HealthContainer>
            <CardWrapper pBlockXs={20} pInlineXs={0}>
                {content ? (
                    <div className={classes.container}>
                        <Typography className={classes.title}>{content?.shared_documents?.[0]?.text}</Typography>
                        <div className={classes.content}>
                            <div className={classes.bulletPoints}>
                                {/* TODO: we need to fix this in prismic */}
                                {i18n.language === 'en' ? (
                                    SplitEnglishTranslation(content?.uploaded_by_me_subtitle?.[0]?.text, classes)
                                ) : (
                                    <>
                                        <Typography className={classes.description}>
                                            {content?.uploaded_by_me_subtitle?.[0]?.text}
                                        </Typography>
                                        <ul style={{ marginTop: 0 }}>
                                            {content?.uploaded_by_me_subtitle?.slice(1).map((item) => (
                                                <li className={classes.description} key={item}>
                                                    {item.text}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                            <span
                                className={classes.image}
                                style={{
                                    backgroundImage: `url(${onlineAccess})`,
                                    backgroundSize: 'cover',
                                }}
                                alt="Online Access"
                            />
                        </div>
                        <div className={classes.buttonContainer}>
                            <FilledButton text={content?.upload_document_cta?.[0]?.text} onPress={openModal} />
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </CardWrapper>
        </HealthContainer>
    );
};

export default NoDocumentsPlaceholder;
