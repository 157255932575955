import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useContext, useMemo } from 'react';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { getText } from '@/utils/helpers';

import { AntSwitch } from '../../Common/AntSwitchCommon';
import PasswordField from '../../Common/PasswordField';
import PrivacyPolicyText from '../../Common/PrivacyPolicyText';
import consentFormsStyles from '../ConsentFormsStyles';

const LINK =
    'https://uploads-ssl.webflow.com/61fd762849432427e585de9a/6331916f3a99dd2d98702b69_Datenschutzrechtliche%20Rollen_final.pdf';
const PRIVACY_URL = 'https://en.eterno.health/ueber-uns/datenschutz';

const SetPassword = ({ content, accountContent }) => {
    const classes = consentFormsStyles();

    //* * Context */
    const {
        consentFormsContent: mainContent,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        passwordError,
        confirmPasswordError,
        policyAccepted,
        setPolicyAccepted,
        validatePassword,
    } = useContext(ConsentFormsContext);

    const handleToggleChange = () => setPolicyAccepted(!policyAccepted);

    const privacyPolicyContent = useMemo(
        () => [
            {
                id: 1,
                text: content['opt-in_text'],
                link: content?.term_and_condition_one,
                href: LINK,
            },
            {
                id: 2,
                text: content?.opt_text_2,
                link: content?.term_and_condition_two,
                href: LINK,
            },
            {
                id: 3,
                text: content?.opt_text_3,
                link: content?.privacy_condition,
                href: PRIVACY_URL,
            },
        ],
        [content]
    );

    return (
        <div>
            <Typography variant="body1" className={[classes.paragraph, classes.mt30, classes.mb10]}>
                {getText(accountContent.enter_a_password) || 'Gib hier ein Passwort für dein Konto an.'}
            </Typography>
            <div>
                <div>
                    <PasswordField
                        label={content.password_label || 'Password'}
                        value={password}
                        onChange={setPassword}
                        errorMessage={mainContent?.heraklesContent?.[passwordError]}
                        validatePassword={validatePassword}
                        passwordOptionHeading={content.passwords_option_heading}
                        optionOne={content.passwords_options[0].text}
                        optionTwo={content.passwords_options[1].text}
                        optionThree={content.passwords_options[2].text}
                    />
                </div>
                <div>
                    <PasswordField
                        label={content.confirm_password_label || 'Confirm Password'}
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        errorMessage={mainContent?.heraklesContent?.[confirmPasswordError]}
                        validatePassword={validatePassword}
                        hideValidMessage
                    />
                </div>
                <Grid container className={classes.mt30}>
                    <Grid item sm={1} xs={2}>
                        <AntSwitch className={classes.mt5} checked={policyAccepted} onChange={handleToggleChange} />
                    </Grid>
                    <Grid item sm={11} xs={10}>
                        <PrivacyPolicyText
                            data={privacyPolicyContent}
                            seeMore={accountContent?.see_more_cta ?? 'See less'}
                            seeLess={accountContent?.see_less_cta ?? 'See more'}
                            hasMarginLeft={false}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default SetPassword;
