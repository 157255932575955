import { createContext, useState } from 'react';

import { processAPIWithHeaders } from '@/utils/helpers';

import { useConfigStore } from '@/store';

export const ContextProvider = createContext();

export const AuthContext = (props) => {
    const [step, setStep] = useState('1');
    const [insuranceType, setInsuranceType] = useState('');
    const [insuranceTypeContext, setInsuranceTypeContext] = useState('');
    const [activeHub, setActiveHub] = useState();
    const [appointmentSearch, setAppointmentSearch] = useState(null);
    const [calendarDataAppointmentKey, setCalendarDataAppointmentKey] = useState(null);
    const [calendarDataInsurance, setCalendarDataInsurance] = useState(null);
    const [myAppointmentsData, setMyAppointmentsData] = useState(null);
    const [myAppointmentsDataStatus, setMyAppointmentsDataStatus] = useState(false);
    const [dateSlot, setDateSlot] = useState();
    const [calenderCanceldData, setCalenderCanceldData] = useState(null);
    const [calenderCanceldDataActive, setCalenderCanceldDataActive] = useState(false);
    const [count, setCount] = useState(0);
    const [cancelData, setCancelData] = useState(null);
    const [activeResponseDataCon, setActiveResponseDataCon] = useState(null);
    const [currDoc, setCurrDoc] = useState([]);
    const [typeId, setTypeId] = useState('');
    const [professionalProfiles, setProfessionalProfiles] = useState({
        'en-us': [],
        'de-de': [],
    });
    const [languageGlobal, setLanguageGlobal] = useState('');

    const updateStep = (s) => {
        setStep(s);
    };

    const fetchAvailableDoctors = async (data) => {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        const response = await processAPIWithHeaders('public/users/availability/search?', 'GET', {
            body: data,
            headers: {
                'Content-Type': 'application/json',
                customer_id: customer_id,
            },
        });

        console.debug(response);
    };

    const [stepsList, setStepsList] = useState([]);
    const [mainDataArray, setMainDataArray] = useState(null);
    const [mainDataActive, setMainDataActive] = useState(false);
    const [dataSetDoctosCon, setDataSetDoctosCon] = useState(false);
    const [mainDatadoctosCon, setMainDatadoctosCon] = useState(null);
    const [activeResp, setActiveResp] = useState([]);

    const updateActiveData = (a) => {
        if (a) {
            const mapping = activeResponseDataCon?.active.filter((fil) => fil.id !== a.id);
            console.debug('activeResponseData: calenderCanceldDataActive', mapping);
        }
    };

    const [isDoctorsPage, setIsDoctorsPage] = useState(false);
    const [loadingIndecator, setLoadingIndecator] = useState(false);

    const [imgAccount, setImgAccount] = useState(null);
    const [appointmentSearchConfirmation, setAppointmentSearchConfirmation] = useState(null);
    const [appointmentSelectionConfirmation, setAppointmentSelectionConfirmation] = useState(null);
    const [doctorConfirmation, setDoctorConfirmation] = useState(null);
    const [verificationLoading, setVerificationLoading] = useState(false);
    const [forBackState, setForBackState] = useState('');
    // logic for find an appointment login
    const [openLogin, setOpenLogin] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({
        title: '',
        text: '',
    });
    const [onceRunState, setOnceRunState] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sideBarMainData, setSideBarMainData] = useState({});
    return (
        <ContextProvider.Provider
            value={{
                typeId: typeId,
                setTypeId: setTypeId,
                currDoc: currDoc,
                setCurrDoc: setCurrDoc,
                setError: setError,
                error: error,
                open: open,
                setOpen: setOpen,
                setOpenLogin: setOpenLogin,
                openLogin: openLogin,
                onceRunState: onceRunState,
                setOnceRunState: setOnceRunState,
                setForBackState: setForBackState,
                forBackState: forBackState,
                updateStep: updateStep,
                step: step,
                insuranceType: insuranceType,
                setInsuranceType: setInsuranceType,
                insuranceTypeContext: insuranceTypeContext,
                setInsuranceTypeContext: setInsuranceTypeContext,
                steps: [
                    'Location',
                    'Profesional',
                    'Type of insurance',
                    'appointment costs',
                    'first appointment vs recurrent',
                    'prescription',
                    'Treatment',
                    'Symptoms',
                    'Group Appointments',
                    'number of appointments',
                ],
                activeHub: activeHub,
                setActiveHub: setActiveHub,
                professionalProfiles: professionalProfiles,
                setProfessionalProfiles: setProfessionalProfiles,
                appointmentSearch: appointmentSearch,
                setAppointmentSearch: setAppointmentSearch,
                fetchAvailableDoctors: fetchAvailableDoctors,
                setStepsList: setStepsList,
                stepsList: stepsList,
                setLanguageGlobal: setLanguageGlobal,
                languageGlobal: languageGlobal,
                setCalendarDataAppointmentKey: setCalendarDataAppointmentKey,
                calendarDataAppointmentKey: calendarDataAppointmentKey,
                setCalendarDataInsurance: setCalendarDataInsurance,
                calendarDataInsurance: calendarDataInsurance,
                setMyAppointmentsData: setMyAppointmentsData,
                myAppointmentsData: myAppointmentsData,
                setMyAppointmentsDataStatus: setMyAppointmentsDataStatus,
                myAppointmentsDataStatus: myAppointmentsDataStatus,
                setDateSlot: setDateSlot,
                dateSlot: dateSlot,
                setCalenderCanceldData: setCalenderCanceldData,
                calenderCanceldData: calenderCanceldData,
                setCalenderCanceldDataActive: setCalenderCanceldDataActive,
                calenderCanceldDataActive: calenderCanceldDataActive,
                count: count,
                setCount: setCount,
                mainDataArray: mainDataArray,
                setMainDataArray: setMainDataArray,
                setMainDataActive: setMainDataActive,
                mainDataActive: mainDataActive,
                setCancelData: setCancelData,
                cancelData: cancelData,
                setActiveResponseDataCon: setActiveResponseDataCon,
                activeResponseDataCon: activeResponseDataCon,
                setDataSetDoctosCon: setDataSetDoctosCon,
                setMainDatadoctosCon: setMainDatadoctosCon,
                mainDatadoctosCon: mainDatadoctosCon,
                dataSetDoctosCon: dataSetDoctosCon,
                updateActiveData: updateActiveData,
                setActiveResp: setActiveResp,
                activeResp: activeResp,
                setIsDoctorsPage: setIsDoctorsPage,
                isDoctorsPage: isDoctorsPage,
                setLoadingIndecator: setLoadingIndecator,
                loadingIndecator: loadingIndecator,
                setImgAccount: setImgAccount,
                imgAccount: imgAccount,
                setAppointmentSearchConfirmation: setAppointmentSearchConfirmation,
                appointmentSearchConfirmation: appointmentSearchConfirmation,
                setAppointmentSelectionConfirmation: setAppointmentSelectionConfirmation,
                appointmentSelectionConfirmation: appointmentSelectionConfirmation,
                setDoctorConfirmation: setDoctorConfirmation,
                doctorConfirmation: doctorConfirmation,
                setVerificationLoading: setVerificationLoading,
                verificationLoading: verificationLoading,
                selectedIndex: selectedIndex,
                setSelectedIndex: setSelectedIndex,
                sideBarMainData: sideBarMainData,
                setSideBarMainData: setSideBarMainData,
            }}
        >
            {props.children}
        </ContextProvider.Provider>
    );
};
