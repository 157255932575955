import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { usePersistedPrismicStore } from '@/store';

const useStyles = makeStyles({
    container: {
        borderBottom: '3px solid #F5F5F5',
        width: '100%',
    },
    tab: {
        color: '#ABABAB',
        textTransform: 'none',
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
});

const StyledTabs = withStyles((theme) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        height: '0.3rem',
        '& > span': {
            width: '100%',
            backgroundColor: theme.palette.common.yellow,
        },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#ABABAB',
        fontSize: '1rem',
        '&:focus': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#2E2E2E',
            fontFamily: 'MessinaSans-SemiBold',
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const SharedDocumentTabs = ({ activeTab, setActiveTab }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const handleTabChange = (e, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <StyledTabs value={activeTab} onChange={handleTabChange} className={classes.container}>
            <StyledTab value="byAppointment" label={content?.by_appointment_tab?.[0]?.text} className={classes.tab} />
            <StyledTab value="others" label={content?.others_tab?.[0]?.text} className={classes.tab} />
        </StyledTabs>
    );
};

export default SharedDocumentTabs;
