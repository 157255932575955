import { Button, Grid, Menu, MenuItem, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import i18next from 'i18next';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from '@/pages/HealthProfileAnamnesis/ExistingConditionComponents/ExistingConditionStyles';

import { DateTextField } from '../Date/DateTextField';

export const compType = {
    FAMILY_HISTORY: 'FamilyHistory',
    EXISTING_CONDITIONS: 'ExistingConditions',
};

const InputFieldQuestions = ({
    componentType,
    mainData,
    idOfComponent,
    dateQuestion,
    setDate = () => {},
    getter,
    getterDate,
    getterRelative,
    callBack = () => {},
    callBackDate = () => {},
    callBackRelative = () => {},
    getterAge,
    callBackAge = () => {},
    getterOther,
    callBackOther = () => {},
    isCompulsory,
    isFamilyOther = false,
    index,
    errorObj,
    setErrorObj = () => {},
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const { setOtherFieldError, setWhichRelative, setOther, setCompulsoryOther } = useContext(ContextProviderTwo);
    const [error, setError] = useState(false);
    const [errOthers, setErrOthers] = useState('');
    const [errRelative, setErrRelative] = useState('');

    useMemo(() => {
        if (error) {
            setErrorObj({ ...errorObj, ...{ [index]: true } });
        } else {
            setErrorObj({ ...errorObj, ...{ [index]: false } });
        }
    }, [error, index]);

    useMemo(() => {
        if (dateQuestion) {
            setDate(dateQuestion);
        } else {
            setDate('');
        }
    }, [dateQuestion]);

    const [privateRelative, setPrivateRelative] = useState('0');
    const [anchorE5, setAnchorE5] = useState(null);
    const [relative, setRelative] = useState('');
    const [age, setAge] = useState('');
    const [familyOther, setFamilyOther] = useState('');

    useMemo(() => {
        if (getterRelative) {
            setRelative(getterRelative);
        } else {
            setRelative('');
            setWhichRelative('');
        }
        if (getterAge) {
            setAge(getterAge);
        } else {
            setAge('');
        }
        if (getterOther) {
            setFamilyOther(getterOther);
        } else {
            setFamilyOther('');
        }
    }, [getterRelative, getterAge]);

    const handleClickGender = (event) => {
        const w = document.getElementById('genderrr').clientWidth;
        console.debug(w);
        setPrivateRelative(w.toString());
        setAnchorE5(event.currentTarget);
    };

    let relative_values = '-';
    const handleCloseMenu = (rel = '') => {
        console.debug('🚀 ~ file: InputFieldQuestions.jsx:98 ~ rel', rel);
        if (rel !== '') {
            setErrRelative('');
            relative_values = rel;
            setWhichRelative(rel);
            setRelative(rel);
            callBackRelative(rel);
            if (isCompulsory === 'OTHER') setCompulsoryOther(true);
            else setCompulsoryOther(false);
        } else {
            setErrRelative(t('RequiredField'));
        }
        setAnchorE5(null);
    };

    return (
        <Grid container spacing={1} style={{ marginTop: 20 }}>
            {isFamilyOther && (
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="other-disease"
                        label={mainData ? mainData.which_diseases[0].text : 'Which Disease?'}
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        onBlur={() => {
                            if (isCompulsory === 'OTHER') {
                                setErrOthers(false);
                                setOther(false);
                                console.debug('index1: getterDate:', isCompulsory);
                                if (familyOther.length === 0) {
                                    setErrOthers(true);
                                    setOther(true);
                                }
                            }
                        }}
                        value={familyOther}
                        onChange={(event) => {
                            callBackOther(event.target.value);
                            setFamilyOther(event.target.value);
                            if (isCompulsory === 'OTHER') {
                                setErrOthers(false);
                                setOther(false);
                                console.debug('index1: getterDate:', isCompulsory);
                                if (event.target.value.length === 0) {
                                    setErrOthers(true);
                                    setOther(true);
                                }
                            }
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            width: '100%',
                            flex: matches ? 1 : 0.5,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            )}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {componentType === compType.FAMILY_HISTORY ? (
                    <>
                        <Button
                            aria-controls="genderr"
                            aria-haspopup="true"
                            onClick={handleClickGender}
                            id="genderrr"
                            disableRipple
                            className={classes.formControl}
                            classes={{
                                label: classes.menuLabel,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'MessinaSans-Regular',
                                        fontSize: '0.875rem',
                                        color: theme.palette.common.darkGrey,
                                        textTransform: 'none',
                                        padding: 0,
                                        marginLeft: 3,
                                    }}
                                >
                                    {mainData
                                        ? mainData.which_relative_subquestion_required[0].text
                                        : 'Which relative?'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'MessinaSans-SemiBold',
                                        fontSize: '1rem',
                                        color: theme.palette.common.darkGrey,
                                        textTransform: 'none',
                                        marginLeft: 3,
                                        textAlign: 'left',
                                    }}
                                >
                                    {mainData?.which_relative_responses?.map(
                                        (val) =>
                                            relative === val.which_relative_response_api_value && (
                                                <Typography
                                                    style={{
                                                        fontFamily: 'MessinaSans-SemiBold',
                                                        fontSize: '1rem',
                                                        color: theme.palette.common.darkGrey,
                                                        textTransform: 'none',
                                                        marginLeft: i18next.language === 'en' ? 0 : 0,
                                                    }}
                                                >
                                                    {val.which_relative_response[0].text}
                                                </Typography>
                                            )
                                    )}
                                </Typography>
                            </div>
                            <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                        </Button>
                        <Menu
                            id="genderr"
                            anchorEl={anchorE5}
                            open={Boolean(anchorE5)}
                            onClose={() => {
                                handleCloseMenu();
                            }}
                            PaperProps={{
                                style: {
                                    width: parseFloat(privateRelative),
                                    backgroundColor: 'white',
                                    paddingTop: 10,

                                    border: `2px solid ${theme.palette.common.yellow}`,
                                    height: 180,
                                },
                            }}
                        >
                            <div
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingLeft: 15,
                                    justifyContent: 'space-between',
                                    paddingRight: 15,
                                    marginBottom: 10,
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontFamily: 'MessinaSans-Regular',
                                            fontSize: '0.875rem',
                                            color: theme.palette.common.darkGrey,
                                            textTransform: 'none',
                                            padding: 0,
                                        }}
                                    >
                                        {mainData.which_relative_subquestion_required[0].text}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontFamily: 'MessinaSans-SemiBold',
                                            fontSize: '1rem',
                                            color: theme.palette.common.darkGrey,
                                            textTransform: 'none',
                                            marginLeft: i18next.language === 'en' ? 0 : 0,
                                        }}
                                    >
                                        {mainData?.which_relative_responses?.map(
                                            (val) =>
                                                relative === val.which_relative_response_api_value && (
                                                    <Typography
                                                        style={{
                                                            fontFamily: 'MessinaSans-SemiBold',
                                                            fontSize: '1rem',
                                                            color: theme.palette.common.darkGrey,
                                                            textTransform: 'none',
                                                            marginLeft: i18next.language === 'en' ? 0 : 0,
                                                        }}
                                                    >
                                                        {val.which_relative_response[0].text}
                                                    </Typography>
                                                )
                                        )}
                                    </Typography>
                                </div>
                                <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                            </div>

                            {mainData.which_relative_responses?.map((valu) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        handleCloseMenu(valu.which_relative_response_api_value);
                                    }}
                                >
                                    {valu.which_relative_response[0].text}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <TextField
                        id={idOfComponent}
                        label={
                            isCompulsory === 'OTHERS'
                                ? mainData.which_one_must[0].text
                                : mainData['pre-existing_conditions_subquestion_response_which'][0].text
                        }
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        value={getter}
                        onBlur={() => {
                            if (isCompulsory === 'OTHERS') {
                                setErrOthers(false);
                                setOtherFieldError(false);
                                if (getter.length === 0) {
                                    setErrOthers(true);
                                    setOtherFieldError(true);
                                }
                            }
                        }}
                        onChange={(event) => {
                            callBack(event.target.value);
                            if (isCompulsory === 'OTHERS') {
                                setErrOthers(false);
                                setOtherFieldError(false);
                                if (event.target.value.length === 0) {
                                    setErrOthers(true);
                                    setOtherFieldError(true);
                                }
                            }
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            width: '100%',
                            flex: matches ? 1 : 0.5,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                )}
                {errOthers && (
                    <Typography className={classes.errorText}>
                        {mainData ? mainData?.required_field_error_message[0].text : 'error of other'}
                    </Typography>
                )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {componentType === compType.FAMILY_HISTORY ? (
                    <TextField
                        // id={idOfComponent}
                        label={mainData ? mainData.relatives_subquestion_age[0].text : t('TransferSlip')}
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        value={age.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                        onChange={(event) => {
                            callBackAge(event.target.value);
                            setAge(event.target.value);
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            width: '100%',
                            flex: matches ? 1 : 0.5,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                ) : (
                    <DateTextField
                        type="monthAndYear"
                        separator={mainData.dob_placeholder_separator[0].text}
                        mainHeading={mainData ? mainData.surgeries_subquestion_when___[0].text : 'When?'}
                        setDOB={setDate}
                        dob={getterDate}
                        setError={setError}
                        isOnBlur
                        onBlur={(e) => {
                            callBackDate(e.target.value);
                        }}
                        error={error}
                        errorMessage={mainData ? mainData.date_error[0].text : 'The date format is invalid.'}
                        placeHolderMonth="MM"
                        placeHolderYear="YYYY"
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default InputFieldQuestions;
