import axios from 'axios';

import { processAPIWithHeaders } from '@/utils/helpers';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const BASE_API_URL_FOR_PRACTITIONERS = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;

export async function adressApi(usersession, body) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;
    // main work starts from here
    try {
        const resp = await axios.post(`${BASE_API_URL}/secure/user/accounts/${user_id}/addresses`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('personalInfoAdress1: ', resp);
        return resp;
    } catch (error) {
        console.debug('personalInfoAdress1 error is ', error);
        return new Error(error.message);
    }
}

export async function userAccountsApi(usersession, body) {
    console.debug(
        '🚀 ~ file: PersonalnformationAPI.js ~ line 31 ~ userAccountsApi ~ body',
        usersession?.idToken.payload.sub,
        body
    );
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;
    try {
        const response = await processAPIWithHeaders(`secure/users/${user_id}`, 'POST', {
            body: body,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('personalInfoAccount1: ', response);
        return response;
    } catch (error) {
        console.debug('error is ', error);
        return new Error(error.message);
    }
}

export async function getAdressAPI(usersession) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession.idToken.jwtToken;
    try {
        const res = await axios.get(
            `${BASE_API_URL}/secure/user/accounts/${user_id}/addresses`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        );
        console.debug(res);
        return res;
    } catch (error) {
        console.debug('error is adress ', error);
        return new Error(error.message);
    }
}
