import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CountryFlag from '@/components/Common/CountryFlag';
import FilledButton from '@/components/FilledButton';

import { ContextProvider } from '@/context/ProjectContext';

import { useConfigStore, useSearchStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        backgroundColor: theme.palette.common.white,
        marginBottom: 20,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 10,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
    },
    //   Main Section Styling
    mainImg: {
        width: '100%',
        height: '70%',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    mainContainer: {
        marginBottom: 20,
        backgroundColor: theme.palette.common.white,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    mainOuterSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 15,
        gap: 8,
    },
    upClass: {
        marginTop: 10,
    },
    mainn: {
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    flagsContainer: {
        display: 'flex',
        gap: 15,
        justifyContent: 'center',
        marginBlock: 5,
    },
    classTitle: {
        textAlign: 'center',
    },
}));

const ImageSectionDoctorDetails = ({ doctors_data, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { isDoctorsPage, setForBackState, setTypeId } = useContext(ContextProvider);
    const history = useHistory();
    const classes = useStyles();
    const [professions, setProfessions] = useState([]);

    useEffect(() => {
        if (!doctors_data) return;
        const newProfessions = [doctors_data.specialityType];
        doctors_data.secondary_location_mappings.forEach((entry) => {
            if (!newProfessions.find((profession) => profession.name[0].text === entry.professional_type.name[0].text))
                newProfessions.push(entry.professional_type);
        });
        setProfessions(newProfessions);
    }, [doctors_data]);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.mainn}>
                <img
                    className={classes.mainImg}
                    src={myAppointmentsDataStatus ? doctors_data?.profile_picture.url : null}
                    alt=""
                />

                <div className={classes.contentContainer}>
                    <div className={classes.mainOuterSection}>
                        <Typography className={classes.classTitle}>
                            {myAppointmentsDataStatus ? doctors_data?.display_name[0].text : null}
                        </Typography>

                        <Typography className={classes.classTitle}>
                            {professions.length === 0
                                ? myAppointmentsDataStatus
                                    ? doctors_data?.specialityType.name[0].text
                                    : 'Meditation Trainer'
                                : professions.map((profession) => profession.name[0].text).join(' / ')}
                        </Typography>
                        <div className={classes.flagsContainer}>
                            {doctors_data?.languages.map((lang) => (
                                <CountryFlag
                                    countryCode={lang.languages1 === 'en' ? 'gb' : lang.languages1}
                                    alt={lang.languages1}
                                />
                            ))}
                        </div>
                        <Typography
                            className={classes.shortText}
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {myAppointmentsDataStatus ? doctors_data?.practice_name[0].text : null}
                        </Typography>
                        {isDoctorsPage && (
                            <div className={classes.upClass}>
                                <FilledButton
                                    text={
                                        dataSet
                                            ? !doctors_data?.is_bookable && doctors_data?.external_booking_link
                                                ? mainData?.external_booking_cta[0].text
                                                : mainData?.continue_booking_cta[0].text
                                            : 'Continue booking'
                                    }
                                    onPress={() => {
                                        if (!doctors_data?.is_bookable && doctors_data?.external_booking_link)
                                            window.open(
                                                doctors_data?.external_booking_link[0].text,
                                                'SingleSecondaryWindowName',
                                                'noreferrer'
                                            );
                                        else history.goBack();
                                    }}
                                    disabled={
                                        !doctors_data?.is_bookable &&
                                        (!doctors_data?.external_booking_link ||
                                            !doctors_data?.external_booking_link[0].text)
                                    }
                                />
                            </div>
                        )}
                        {isDoctorsPage === false && (
                            <div className={classes.upClass}>
                                <FilledButton
                                    text={
                                        dataSet
                                            ? !doctors_data?.is_bookable && doctors_data?.external_booking_link
                                                ? mainData?.external_booking_cta[0].text
                                                : mainData?.book_now_cta[0].text
                                            : 'Book Now'
                                    }
                                    onPress={() => {
                                        if (!doctors_data?.is_bookable && doctors_data?.external_booking_link)
                                            window.open(
                                                doctors_data?.external_booking_link[0].text,
                                                'SingleSecondaryWindowName',
                                                'noreferrer'
                                            );
                                        else {
                                            setForBackState(doctors_data?.key[0].text);

                                            const { currentCustomer } = useConfigStore.getState();
                                            if (currentCustomer.is_accordion_view_active) history.push('/');
                                            else {
                                                useSearchStore.setState({ queryValuesSet: false });
                                                history.push(`/find-appointment/${doctors_data?.key[0].text}`);
                                            }

                                            setTypeId(doctors_data?.type.id);
                                        }
                                    }}
                                    disabled={
                                        !doctors_data?.is_bookable &&
                                        (!doctors_data?.external_booking_link ||
                                            !doctors_data?.external_booking_link[0].text)
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageSectionDoctorDetails;
