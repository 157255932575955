import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Context as AuthContext } from '@/context/AuthContext';

import { dayjs } from '@/utils/dayjsSetup';
import { createQuestionObj } from '@/utils/helpers';

import { useBookingStore, usePersistedPrismicStore, useSearchStore } from '@/store';

import TextfieldQuestion from '../CustomAnamnesis/QuestionTypes/TextfieldQuestion';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        position: 'relative',
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem',
        position: 'relative',
    },
    sectionHeading: {
        color: theme.palette.common.darkGrey,
        textTransform: 'uppercase',
        opacity: 0.5,
        fontSize: '14px',
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        [theme.breakpoints.down('xs')]: {
            gap: '1.5rem',
        },
    },
    dataEntry: {
        display: 'flex',
        alignItems: 'center',
        gap: '1.25rem',
        '& > p:first-child': {
            width: '12rem',
            fontWeight: 'lighter',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '0.5rem',
        },
    },
}));

const ConfirmAppointment = () => {
    const {
        state: { user, userAddresses },
    } = useContext(AuthContext);
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainData: prismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const {
        [i18n.language]: { benefits: registerPrismicData },
    } = usePersistedPrismicStore((state) => state.registerData);
    const {
        [i18n.language]: { content: accountPrismicData },
    } = usePersistedPrismicStore((state) => state.accountData);
    const loading = useBookingStore((state) => state.loading);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const treatments = useSearchStore((state) => state.treatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const locations = useSearchStore((state) => state.locations);
    const selectedTimeslot = useBookingStore((state) => state.selectedTimeslot);
    const additionalInfo = useBookingStore((state) => state.additionalInfo);
    const isAtSecondaryLocation = useBookingStore((state) => state.isAtSecondaryLocation);
    const secondaryLocation = useBookingStore((state) => state.secondaryLocation);

    return (
        <div className={classes.container}>
            {(loading || !user || !userAddresses) && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <div className={classes.section}>
                <Typography className={classes.sectionHeading}>
                    {prismicData?.appointment_details_summary[0]?.text}
                </Typography>
                <div className={classes.dataContainer}>
                    <div className={classes.dataEntry}>
                        <Typography>{prismicData.booking_summary.appointment_service_label[0].text}</Typography>
                        <Typography>
                            {Object.keys(selectedTreatments)
                                .filter((treatmentKey) => selectedTreatments[treatmentKey])
                                .map((treatmentKey) => treatments[selectedLocation]?.[treatmentKey].name[0].text)
                                .join(', ')}
                        </Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{prismicData.booking_summary.location_label[0].text}</Typography>
                        <Typography>
                            {isAtSecondaryLocation
                                ? secondaryLocation.title[0].text
                                : locations[selectedLocation].title[0].text}
                        </Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{prismicData.booking_summary.date_label[0].text}</Typography>
                        <Typography>{dayjs(selectedTimeslot).format('dddd, DD MMM YYYY')}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{prismicData.booking_summary.time_label[0].text}</Typography>
                        <Typography>{dayjs(selectedTimeslot).format('HH:mm')}</Typography>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <Typography className={classes.sectionHeading}>
                    {prismicData?.account_information_summary[0]?.text}
                </Typography>
                <div className={classes.dataContainer}>
                    <div className={classes.dataEntry}>
                        <Typography>{accountPrismicData.name[0].text}</Typography>
                        <Typography>{`${user?.name.first} ${user?.name.last}`}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{registerPrismicData.date_of_birth.split('(')[0]}</Typography>
                        <Typography>{dayjs(user?.date_of_birth).format('DD.MM.YYYY')}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{registerPrismicData.email_label}</Typography>
                        <Typography>{user?.email}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{registerPrismicData.mobile_number__optional_}</Typography>
                        <Typography>{user?.phone.number}</Typography>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <Typography className={classes.sectionHeading}>
                    {prismicData?.contact_details_summary[0]?.text}
                </Typography>
                <div className={classes.dataContainer}>
                    <div className={classes.dataEntry}>
                        <Typography>{accountPrismicData.street_label[0].text}</Typography>
                        <Typography>{userAddresses?.street}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{accountPrismicData.zip_code_label[0].text}</Typography>
                        <Typography>{userAddresses?.zip}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{accountPrismicData.city_label[0].text}</Typography>
                        <Typography>{userAddresses?.city}</Typography>
                    </div>
                    <div className={classes.dataEntry}>
                        <Typography>{accountPrismicData.country_label[0].text}</Typography>
                        <Typography>{userAddresses?.country.name}</Typography>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <Typography>{prismicData.booking_summary.info_question_text[0].text}</Typography>
                <TextfieldQuestion
                    question={createQuestionObj(
                        '',
                        prismicData.booking_summary.info_placeholder_text[0].text,
                        'additional-info'
                    )}
                    answer={additionalInfo}
                    setAnswer={(value) => value.length <= 300 && useBookingStore.setState({ additionalInfo: value })}
                    hideTitle
                    multiline
                    maxRows={2}
                    height="4.75rem"
                />
            </div>
        </div>
    );
};

export default ConfirmAppointment;
