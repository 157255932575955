import arrayShuffle from 'array-shuffle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPractitionerForUser } from '@/service/Appointments/appointmentService';

import { useConfigStore, usePersistedPrismicStore, useProfessionalsStore, useSearchStore } from '@/store';

export default function useProfessionalsList(usersession) {
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.professionalProfileData);
    const professionals = useSearchStore((state) => state.professionals);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    const [loading, setLoading] = useState(true);

    const fetchAndSetUserProfessionals = async () => {
        try {
            const response = await getPractitionerForUser(usersession);
            const { data } = response.data;
            const professionalIds = data.map((professional) => professional.key);
            const professionalData = content
                .filter((r) => professionalIds.includes(r.key[0].text))
                .filter((professional) =>
                    currentCustomer.locations.some(({ prismicId }) => prismicId === professional.location_hub.id)
                );
            useProfessionalsStore.setState({
                mainDataMyProfessionals: {
                    'en-us': professionalData,
                    'de-de': professionalData,
                },
                mainDataMyProfessionalsID: professionalIds,
                mainDataArrActive: true,
                shuffledMyProfessionals: arrayShuffle(professionalData),
            });

            setLoading(false);
        } catch (err) {
            console.debug('MyProfessionals: Error', err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (content.length === 0) return;

        useProfessionalsStore.setState({
            allProfessionals: {
                'en-us': content,
                'de-de': content,
            },
            hubProfessionals: arrayShuffle(
                Object.values(professionals).filter(
                    (professional) => professional.location_hub.id === currentCustomer.locations[0].prismicId
                )
            ),
        });
    }, [content]);

    useEffect(() => {
        if (!usersession || content.length === 0) {
            setLoading(false);
            return;
        }

        fetchAndSetUserProfessionals();
    }, [usersession, content]);

    return { loading: loading };
}
