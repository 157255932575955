import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import blackArrowUp from '@/assets/images/blackArrowUp.png';
import DownloadIcon from '@/assets/images/downloadIcon.svg';
import greenTick from '@/assets/images/greenTick.png';

import FilledButton from '@/components/FilledButton';
import Loader from '@/components/Loader';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import useFormSteps from '@/hooks/useFormSteps';
import useFormTemplate from '@/hooks/useFormTemplate';
import useRequiredTemplatesAndAnamnesisNew from '@/hooks/useRequiredTemplatesAndAnamnesisNew';
import { useUserDocumentDownload } from '@/hooks/useUserDocumentDownload';

import { dayjs } from '@/utils/dayjsSetup';
import { getDocumentTypeTranslation } from '@/utils/helpers';

import {
    useCustomAnamnesisStore,
    usePersistedPrismicStore,
    useSharedFormsStore,
    useUploadDocumentModalStore,
} from '@/store';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        margin: '1rem 0 2rem 0',
    },
    sectionTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 600,
        marginBottom: '1rem',
    },
    container: {
        boxSizing: 'border-box',
    },
    headingContainer: {
        alignItems: 'center',
        marginBottom: '1.25rem',
        width: '100%',
    },
    mainHeading: {
        ...theme.typography.HL1,
    },
    data: {
        marginBottom: '1rem',
    },
    items: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        margin: '1rem 0',
    },
    item: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 500,
    },
    anamnesisText: {
        marginBottom: '0.5rem',
    },
    buttonTitle: {
        marginBottom: '0.5rem',
    },
    buttonContainer: {
        margin: '2rem 0',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    hideDetails: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
        cursor: 'pointer',
    },
}));

const AppointmentDetailsDocuments = ({
    dataSet,
    mainData,
    appointment,
    enableFillDigitalDocument = false,
    setShowAll,
    setHideAll,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {
        consentAllForms: { isConsentAllForms, formTemplates },
        setConsentAllForms,
        setProfessional,
        setSignState,
    } = useConsentFormsContext();

    const {
        state: { userDocuments },
    } = useContext(AuthContext);

    const documentsForAppointment = userDocuments?.data?.filter(
        (document) => document?.appointment_id === appointment?.id
    );

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const openModal = useUploadDocumentModalStore((state) => state.openModal);
    const filledFormsAndAnamnesis = useSharedFormsStore((state) => state.sharedForms);

    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const professionalId = appointment?.professional?.id ?? '';

    const { isLoading, data } = useRequiredTemplatesAndAnamnesisNew({
        appointmentTypeKeys: appointment.data.services.map(({ key }) => key).join(),
        insuranceType:
            appointment?.data?.insurance_coverage_type === 'selfpayer'
                ? 'SELF_PAYER'
                : (appointment?.data?.insurance_coverage_type?.toUpperCase() ?? ''),
        professionalId: professionalId,
        enrichMissingFormsWithTemplateAndName: enableFillDigitalDocument, // add templates info and names on forms and anamnesis
    });

    const { handleDocumentDownload } = useUserDocumentDownload();

    const { currentStepIndex, step } = useFormSteps();
    const { loading: formTemplateLoading } = useFormTemplate({ type: isConsentAllForms ? 'form' : 'query' });
    const signatureTypeForNextForm = formTemplates[currentStepIndex]?.signatureType;

    const formatDocumentDate = (document) => {
        return dayjs(document.meta.created_at).format('DD.MM.YYYY');
    };

    const onConfirmCheckIn = () => {
        const { professional, doctor } = appointment ?? {};
        const { display_name } = doctor?.[i18n.language] ?? {};

        setSignState(signatureTypeForNextForm);
        setProfessional({
            ...professional,
            id: professional?.id,
            name: display_name?.[0]?.text || 'Unknown',
        });

        setConsentAllForms((prev) => ({
            ...prev,
            isConsentAllForms: true,
            forms: data?.missingConsentForms,
            initialRoute: ConsentAllFormsInitialRoutes.MY_APPOINTMENT,
        }));

        handleRedirect();
    };

    const handleRedirect = () => {
        if (data?.missingCustomAnamnesisForms.length > 0) {
            useCustomAnamnesisStore.setState({
                redirectToConsentForms: data.missingConsentForms.length > 0,
                formsToFill: data.missingCustomAnamnesisForms,
                currentForm: 1,
                loading: true,
            });

            history.push(
                `/custom-anamnesis?professional_id=${appointment?.professional?.id}&template_id=${data.missingCustomAnamnesisForms[0].id}`
            );
        } else {
            history.push(step);
        }
    };

    const allNotFilledFormsCount = useMemo(() => {
        if (!data) return 0;

        const missingConsentForms = data?.missingConsentForms ?? [];
        const missingAnamnesisForms = data?.missingCustomAnamnesisForms ?? [];

        return missingConsentForms.length + missingAnamnesisForms.length;
    }, [data]);

    const filledFormsAndAnamnesisWithNames = useMemo(() => {
        if (!filledFormsAndAnamnesis) return [];

        return filledFormsAndAnamnesis
            .filter((form) => form.context.professional_id === professionalId) // only forms relevant for this professional
            .map((form) => ({
                ...form,
                id: form.document?.id,
                name: form.name?.[i18n.language] ?? t('UnknownForm'),
            }));
    }, [filledFormsAndAnamnesis, i18n, professionalId]);

    if (isLoading || formTemplateLoading) return <Loader height="100px" />;

    const hasFormsToFill = allNotFilledFormsCount > 0;

    return (
        <div className={classes.container}>
            <div className={classes.headingContainer}>
                <Typography className={classes.mainHeading}>
                    {dataSet ? mainData.documents_heading[0].text : 'Documents'}
                </Typography>
            </div>

            {/* Check in forms section */}
            <Grid container direction="row" className={classes.mainGrid}>
                {/* Show title if there are filled forms or if filling is enabled and there are forms to fill */}
                {(filledFormsAndAnamnesisWithNames?.length > 0 || (enableFillDigitalDocument && hasFormsToFill)) && (
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.sectionTitle}>
                            {mainData?.checkin_forms_label?.[0]?.text ?? 'Check-in Forms'}
                        </Typography>
                    </Grid>
                )}
                <Grid item md={8} xs={12} className={classes.itemList}>
                    {filledFormsAndAnamnesisWithNames?.length > 0 &&
                        filledFormsAndAnamnesisWithNames?.map(({ id, name }) => (
                            <div key={id} className={classes.items}>
                                <img src={greenTick} alt={`${name} success`} />
                                <Typography className={classes.item}>{name}</Typography>
                            </div>
                        ))}
                    {enableFillDigitalDocument && hasFormsToFill && (
                        <div className={classes.buttonContainer}>
                            <Typography className={classes.buttonTitle}>
                                {dataSet
                                    ? mainData.anamnesis_fill[0].text
                                    : 'Fill out and read the documents before your appointment'}
                            </Typography>
                            <FilledButton
                                text={`${allNotFilledFormsCount !== 1 ? `(${allNotFilledFormsCount}) ` : ''}${t('FormsFlowCta')}`}
                                onPress={onConfirmCheckIn}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>

            {/* Shared document section */}
            {isDocumentExchangeFeatureFlagActivated && (
                <Grid container direction="row" className={classes.mainGrid}>
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.sectionTitle}>
                            {content?.shared_documents?.[0]?.text ?? 'Shared Documents'}
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.itemList}>
                        {documentsForAppointment?.map((document) => (
                            <div key={document.id} className={classes.items}>
                                <img src={DownloadIcon} alt="Download" />
                                <UnderlinedButton
                                    text={`${document.name} - ${getDocumentTypeTranslation(
                                        content?.type_of_doc_response,
                                        document?.type
                                    )} - ${formatDocumentDate(document)}`}
                                    noMarginTop
                                    onPress={() => handleDocumentDownload(document.id)}
                                    textTransformNone
                                    noJSLeft={false}
                                />
                            </div>
                        ))}
                        {/* If feature flag enabled, allow to upload document */}
                        <div className={classes.buttonContainer}>
                            <Typography className={classes.buttonTitle}>
                                {content?.upload_document_info?.[0]?.text ?? t('UploadDocumentInfo')}
                            </Typography>
                            <FilledButton
                                text={content?.upload_document_cta[0]?.text}
                                noMarginTop
                                onPress={() => {
                                    openModal(appointment?.id);
                                }}
                                noJSLeft={false}
                            />
                        </div>
                    </Grid>
                </Grid>
            )}

            {setShowAll && setHideAll && (
                <div
                    className={classes.hideDetails}
                    onClick={() => {
                        setShowAll(false);
                        setHideAll(true);
                    }}
                >
                    <Typography className={classes.shortText}>
                        {dataSet ? mainData.hide_details_cta[0].text : 'Hide details'}
                    </Typography>
                    <img src={blackArrowUp} alt="Arrow Up" />
                </div>
            )}
        </div>
    );
};

export default memo(AppointmentDetailsDocuments);
