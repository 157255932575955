import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDown from '@/assets/images/ArrowDown.png';
import downloadIcon from '@/assets/images/downloadIcon.svg';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { ContextProvider } from '@/context/ProjectContext';

import { useUserDocumentDownload } from '@/hooks/useUserDocumentDownload';

import { formatRawDate, getText } from '@/utils/helpers';

import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
    table: { marginBlock: '1rem' },
    colHeadingText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '12px',
        fontStyle: 'normal',
        textTransform: 'uppercase',
    },
    textCenter: { textAlign: 'center' },
    textRight: { textAlign: 'right' },
    textLeft: { textAlign: 'left' },
    mt10: { marginTop: 10 },
    icon: { fontSize: '2rem', color: theme.palette.common.brown },
    tableHeader: { borderBottom: '3px solid #F5F5F5', padding: '1rem' },
    rowAccordian: {
        width: '100%',
        boxShadow: 'none',
        backgroundColor: 'none',
        borderBottom: '3px solid #F5F5F5',
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    rowsContainer: { width: '100%' },
    row: { paddingBlock: '1rem' },
    rowIcon: {
        width: '24px',
        height: '24px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: { width: '18px', height: '18px' },
    },
    buttonIcon: { width: '1rem', height: '1rem', objectFit: 'contain' },
    rowText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('xs')]: { fontSize: '14px', marginLeft: 0 },
    },
    rowType: { display: 'flex', alignItems: 'center', gap: 10 },
    rowTypeText: {
        fontFamily: 'MessinaSans-SemiBold',
        wordBreak: 'break-all',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: { fontSize: '14px' },
    },
    rowExpanded: { flexDirection: 'column', padding: 0 },
    rowExpandedBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
        paddingBottom: '1.5rem',
        paddingInline: '1rem',
        borderBottom: '3px solid #F5F5F5',
        [theme.breakpoints.down('xs')]: { paddingInline: '1rem' },
    },
    rowExpandedFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingInline: '1rem',
        paddingBlock: '.6rem',
    },
    rowFooterButton: {
        background: 'transparent',
        borderRadius: '5px',
        margin: '.4rem',
        '&:hover': {
            background: 'transparent',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '.2rem 0',
        },
    },
    rowFooterButtonText: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 600,
        color: theme.palette.common.darkGrey,
        fontSize: '1rem',
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: { fontSize: '14px' },
    },
}));

const DocumentTable = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();

    const [DocTypes, setDocTypes] = useState(null);

    const {
        state: { userDocuments },
    } = useContext(AuthContext);

    const {
        state: { uploadedByMeData },
    } = useContext(HealthProfileContextMain);

    const { languageGlobal } = useContext(ContextProvider);

    useMemo(() => {
        if (Object.keys(uploadedByMeData).length > 0) {
            setDocTypes(uploadedByMeData.type_of_doc_response);
        }
    }, [uploadedByMeData]);

    // setting type to prismic type text
    const setDocumentTypeToPrismic = (type) => {
        if (!DocTypes) return;
        const filteredType = DocTypes.filter((prismicType) => {
            return prismicType.type_of_doc_responses === type;
        });
        return filteredType[0];
    };

    const getSpecialtyName = (type) => {
        if (!type) return '';
        const name = uploadedByMeData?.specialty_responses?.find(
            (specialty) => specialty.specialty_response_api_value === type
        );
        return name ? getText(name.specialty_response) : '';
    };

    return (
        <Grid container className={classes.table}>
            {/* Table Header */}
            {/* accrodian */}
            <Accordion className={classes.rowAccordian} expanded={false}>
                <AccordionSummary
                    disabled
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    disableRipple
                    disableTouchRipple
                    expandIcon={<img src={ArrowDown} style={{ visibility: 'hidden' }} />}
                >
                    <Grid container>
                        <Grid item sm={3} xs={6} className={classes.colHeading}>
                            <Typography className={classes.colHeadingText}>
                                {uploadedByMeData?.type_label[0].text}
                            </Typography>
                        </Grid>
                        <Grid item sm={6} xs={6} className={classes.colHeading} style={{ paddingLeft: 10 }}>
                            <Typography className={clsx(classes.colHeadingText, matchesXs ? classes.textRight : null)}>
                                {matchesXs
                                    ? uploadedByMeData?.date_label[0].text
                                    : uploadedByMeData?.file_name_label[0].text}
                            </Typography>
                        </Grid>
                        <Grid item sm={3} xs={6} className={classes.colHeading}>
                            <Typography
                                className={clsx(
                                    classes.colHeadingText,
                                    matchesXs ? classes.textLeft : classes.textRight
                                )}
                                // style={{ width: '72%' }}
                            >
                                {matchesXs
                                    ? uploadedByMeData?.file_name_label[0].text
                                    : uploadedByMeData?.date_label[0].text}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
            </Accordion>
            {/* Table Rows */}
            <Grid container className={classes.rowsContainer}>
                {userDocuments?.data.map(({ id, name, type, specialty_type, meta: { created_at } }) => (
                    <DocumentTableRow
                        key={id}
                        id={id}
                        fileName={name}
                        type={setDocumentTypeToPrismic(type)?.type_of_document[0].text}
                        date={formatRawDate(created_at, languageGlobal)}
                        specialtyName={getSpecialtyName(specialty_type || '')}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

const DocumentTableRow = ({
    id,
    fileName = 'Labresults.jpg',
    type = 'Laboratory',
    date = '08 Aug 22',
    specialtyName = '',
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');
    const [expanded, setExpanded] = useState(false);
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        state: { uploadedByMeData },
    } = useContext(HealthProfileContextMain);

    const { handleDocumentDownload } = useUserDocumentDownload();

    return (
        <Accordion
            className={classes.rowAccordian}
            expanded={expanded}
            onChange={() => setExpanded((prev) => !prev)}
            style={{ background: expanded ? '#FAFAFA' : null }}
        >
            <AccordionSummary
                disableRipple
                disableTouchRipple
                expandIcon={<img src={ArrowDown} className={classes.icon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container className={classes.row}>
                    <Grid item sm={3} xs={6} className={classes.rowItem}>
                        <div className={classes.rowType}>
                            <Typography className={classes.rowTypeText}>{type}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={6} className={classes.rowItem} style={{ paddingLeft: 10 }}>
                        <Typography className={clsx(classes.rowText, matchesXs ? classes.textRight : null)}>
                            {matchesXs ? date : fileName}
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={6} className={clsx(classes.rowItem, classes.textRight)}>
                        <Typography
                            className={clsx(
                                classes.rowText,
                                matchesXs ? classes.mt10 : null,
                                matchesXs ? classes.textLeft : null
                            )}
                        >
                            {matchesXs ? fileName : date}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.rowExpanded}>
                <Box className={classes.rowExpandedBox}>
                    <Typography className={classes.rowText}>{specialtyName}</Typography>
                    {/* <OutlinedButton
                        disabled
                        text={'Share File'}
                        onPress={() => {}}
                        color={theme.palette.common.darkGrey}
                        fullWidth={false}
                    /> */}
                </Box>
                {/* <hr className={classes.border} /> */}
                <Box className={classes.rowExpandedFooter}>
                    <Button
                        startIcon={<img src={downloadIcon} className={classes.buttonIcon} />}
                        disableRipple
                        classes={{
                            root: classes.rowFooterButton,
                            label: classes.rowFooterButtonText,
                        }}
                        onClick={() => {
                            handleDocumentDownload(id);
                        }}
                    >
                        {uploadedByMeData?.download_cta[0].text || 'Download'}
                    </Button>
                    {/* <Button
                        disabled
                        startIcon={<img src={binIcon} className={classes.buttonIcon} />}
                        disableRipple
                        classes={{
                            root: classes.rowFooterButton,
                            label: classes.rowFooterButtonText,
                        }}
                    >
                        {uploadedByMeData?.delete_cta[0].text || 'Delete'}
                    </Button> */}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default DocumentTable;
