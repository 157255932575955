import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersistedPrismicStore } from '@/store';

import './LevelMeter.css';

const useStyles = makeStyles((theme) => ({
    percentHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '28px',
        color: theme.palette.common.darkGrey,
    },
    percentSemiHeading: {
        fontSize: '1rem',
        marginBottom: 20,
        textTransform: 'uppercase',
        color: theme.palette.common.brown,
        fontFamily: 'MessinaSans-Regular',
    },
}));

// debugger;
const LevelCard = ({ text, isEnergy = false, hours, energyLevel }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const [percentage, setPercentage] = useState(0);
    const [percent, setPercent] = useState(0);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    const checkPercent = {
        High: 75,
        Low: 40,
        'Very low': 25,
        'Very high': 94,
    };

    useEffect(() => {
        if (!isEnergy) {
            if (parseInt(hours) > 5 && parseInt(hours) <= 10) {
                setPercentage((hours - 5) * 20);
            } else {
                setPercentage(0);
            }
        } else if (isEnergy) {
            const per = checkPercent[energyLevel];
            console.debug('hours123', per);
            setPercent(per);
        }
    }, [hours]);

    console.debug('hours123', hours, percentage);

    return (
        <div className="gauge">
            <div className="gauge__body">
                <div
                    className="gauge__fill"
                    style={{
                        transform: `rotate(${parseInt(isEnergy ? percent : percentage) * 0.005}turn)`,
                        background: isEnergy
                            ? percent >= 50
                                ? theme.palette.common.secondary
                                : percent >= 30
                                  ? theme.palette.common.yellow
                                  : theme.palette.common.red
                            : theme.palette.common.secondary,
                    }}
                />
                <div className="gauge__cover">
                    <Typography className={classes.percentHeading}>
                        {isEnergy
                            ? parseInt(percent) >= 80
                                ? content?.energy_level_heading[0].text
                                : parseInt(percent) >= 50
                                  ? content?.energy_level_heading1[0].text
                                  : parseInt(percent) >= 30
                                    ? content?.energy_level_heading2[0].text
                                    : content?.energy_level_heading3[0].text
                            : `${hours}  ${content?.hours[0].text}`}
                    </Typography>
                    <Typography className={classes.percentSemiHeading}>{text}</Typography>
                </div>
            </div>
        </div>
    );
};

export default memo(LevelCard);
