import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@/assets/Icons/search_icon.svg';

import { Context as AuthContext } from '@/context/AuthContext';

import { dayjs } from '@/utils/dayjsSetup';
import { getDocumentTypeTranslation } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

import DocumentList from './DocumentList';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    searchContainer: {
        marginTop: '1rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchField: {
        width: '95%',
        marginBottom: '1.25rem',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ABABAB',
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: '#999',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffc877',
            },
        },
    },
    noDocuments: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: '2rem',
        marginTop: '1rem',
    },
}));

const OtherDocuments = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        state: { userDocuments },
    } = useContext(AuthContext);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const { data: documents } = userDocuments;
    const [searchTerm, setSearchTerm] = useState('');

    const filteredDocuments = useMemo(() => {
        const documentsWithoutAppointment = documents.filter((document) => !document.appointment_id);

        if (!searchTerm) return documentsWithoutAppointment;

        const searchLower = searchTerm.toLowerCase();

        return documentsWithoutAppointment.filter((doc) => {
            const nameMatch = doc.name.toLowerCase().includes(searchLower);
            const dateMatch = dayjs(doc.meta.created_at).format('DD.MM.YYYY').includes(searchTerm);
            const typeMatch = getDocumentTypeTranslation(content?.type_of_doc_response, doc.type)
                .toLowerCase()
                .includes(searchLower);

            return nameMatch || dateMatch || typeMatch;
        });
    }, [documents, searchTerm, content]);

    return (
        <div className={classes.container}>
            {filteredDocuments.length === 0 && searchTerm === '' ? (
                <div className={classes.noDocuments}>
                    <Typography>{content?.no_documents_found?.[0]?.text}</Typography>
                </div>
            ) : (
                <>
                    <div className={classes.searchContainer}>
                        <TextField
                            className={classes.searchField}
                            label={content?.search_documents?.[0]?.text}
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={SearchIcon} alt="Search" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <DocumentList documents={filteredDocuments} displayDoctor />
                </>
            )}
        </div>
    );
};

export default OtherDocuments;
