import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getText } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

const useInsuranceType = (currentType = '') => {
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainDataAccountEterno },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    const insuranceTypeText = useMemo(() => {
        if (!mainDataAccountEterno) return;

        const filteredType = mainDataAccountEterno?.insurance_type_values?.find(
            (type) => currentType === type?.health_insurance_type_api_value
        );
        if (filteredType) return getText(filteredType?.health_insurance_type_display_value);

        return '';
    }, [currentType, mainDataAccountEterno]);

    return { insuranceTypeText: insuranceTypeText };
};

export default useInsuranceType;
