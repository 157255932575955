import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMemo } from 'react';
// components
import { useTranslation } from 'react-i18next';

import ProcessDataImg from '@/assets/Illustrations/process_data.svg';

import OverviewEmpty from '@/components/ConsentManagement/OverviewComponents/OverviewEmpty';
import { overviewStyles } from '@/components/ConsentManagement/OverviewComponents/OverviewStyles';
import OverviewTable from '@/components/ConsentManagement/OverviewComponents/OverviewTable/Table';
import TableTabs from '@/components/ConsentManagement/OverviewComponents/OverviewTable/TableTabs';
import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import Icon from '@/components/Icon/Icon';
import UnderlinedButton from '@/components/UnderlinedButton';

import useDoctorDocuments from '@/hooks/useDoctorDocuments';

import { getText, replaceProfessionalType } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

import HealthContainer from '../HealthProfileOverview/HealthContainer';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.darkGrey,
        fontSize: 24,
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '.6rem',
        },
    },
    heading: {
        ...theme.typography.HL1,
    },
    paragraph: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        width: '100%',
    },
    mt10: { marginTop: 10 },
    nullLoading: {
        width: '100%',
        marginBlock: '4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginBlock: '2rem',
        },
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
}));

const ConsentOverview = () => {
    const classes = useStyles();
    const classesOverview = overviewStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

    // custom hook (prismic data)
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.consentData);

    // shared documents
    const { doctorDocuments } = useDoctorDocuments();

    const noContent = useMemo(() => Object.keys(content).length === 0, [content]);

    const createContentWithType = (linkLabel, infoText) => {
        const result = [];
        const linkIndex = infoText.indexOf(linkLabel);

        if (linkIndex !== -1) {
            const beforeText = infoText.substring(0, linkIndex);
            const linkText = linkLabel;
            const afterText = infoText.substring(linkIndex + linkLabel.length);

            if (beforeText) {
                result.push({ content: beforeText, type: 'text' });
            }

            result.push({ content: linkText, type: 'link' });

            if (afterText) {
                result.push({ content: afterText, type: 'text' });
            }
        } else {
            // If linkLabel is not found, treat the entire infoText as text
            result.push({ content: infoText, type: 'text' });
        }

        return result;
    };

    // Text and label
    const linkLabel = i18n.language === 'en' ? 'Privacy Policy' : 'Datenschutzerklärung';
    const infoText = noContent ? '' : getText([content.overview_data_processing_text.at(-1)]);

    const contentWithTypeArray = createContentWithType(linkLabel, infoText);

    const RenderComponent = () => {
        if (Array.isArray(doctorDocuments) && doctorDocuments.length > 0) {
            // check if user documents available
            return (
                <>
                    <TableTabs />
                    <OverviewTable doctorDocuments={doctorDocuments} />
                </>
            );
        }
        if (Array.isArray(doctorDocuments) && doctorDocuments.length === 0) {
            return <OverviewEmpty content={content} />;
        }
        return (
            <div className={classes.nullLoading}>
                <CircularProgress className={classes.progressBar} size={50} />
            </div>
        );
    };

    if (noContent) return null;

    return (
        <HealthContainer>
            <CardWrapper>
                <Grid container spacing={matchesXS ? null : 2} style={{ padding: 0 }}>
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.mainHeading}>{content.overview_page_title[0].text}</Typography>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12}>
                    <Grid item md={12} sm={12} className={classes.mt10}>
                        <Typography variant="p" className={classes.paragraph}>
                            {content.overview_intro_text[0].text}
                        </Typography>
                    </Grid>
                    {Array.isArray(doctorDocuments) && doctorDocuments.length > 0 && (
                        <Typography
                            className={clsx(classesOverview.mainHeading, classesOverview.mt25, classesOverview.mb15)}
                        >
                            {content.overview_data_table_title[0].text}
                        </Typography>
                    )}
                    {RenderComponent()}
                    <div>
                        <Typography className={clsx(classesOverview.mainHeading, classesOverview.mt25)}>
                            {content?.overview_data_processing_title[0].text}
                        </Typography>
                        <Typography className={classesOverview.paragraph}>
                            {replaceProfessionalType({
                                text: content.overview_data_processing_text[0].text,
                                language: i18n.language,
                            })}
                        </Typography>
                        <Grid container className={clsx(classesOverview.twoColContainer)}>
                            <Grid item sm={6} xs={12} className={classesOverview.mt10}>
                                <Box
                                    className={clsx(
                                        classesOverview.emptyBox,
                                        classesOverview.mr15,
                                        classesOverview.pb20
                                    )}
                                >
                                    <Icon src={ProcessDataImg} aspectRatio="2.22 / 1" width="70%" height="unset" />
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography className={classesOverview.paragraph}>
                                    {replaceProfessionalType({
                                        text: content.overview_data_processing_text[1].text,
                                        language: i18n.language,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classesOverview.paragraph}>
                            {replaceProfessionalType({
                                text: content.overview_data_processing_text[2].text,
                                language: i18n.language,
                            })}
                        </Typography>

                        {/* Privacy Policy */}
                        <Typography
                            className={clsx(
                                classesOverview.paragraph,
                                classesOverview.my10,
                                classesOverview.policyLink
                            )}
                        >
                            {contentWithTypeArray?.map(({ content, type }) => {
                                if (type === 'link') {
                                    return (
                                        <UnderlinedButton
                                            key={content}
                                            noMarginTop
                                            textTransformNone
                                            marginLeft={!matchesSM && 5}
                                            marginRight={5}
                                            text={content}
                                            onPress={() =>
                                                window.open('https://www.eterno.health/ueber-uns/datenschutz', '_blank')
                                            }
                                        />
                                    );
                                }

                                return <span key={content}>{content}</span>;
                            })}
                        </Typography>
                    </div>
                </Grid>
            </CardWrapper>
        </HealthContainer>
    );
};

export default ConsentOverview;
