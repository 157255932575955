import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';

/** Child Components */
import { ThankYou } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';

import usePrismicData from '@/hooks/usePrismicData';

import { getText, signOut } from '@/utils/helpers';

import { usePersistedPrismicStore } from '@/store';

const WELCOME_PAGE = '/herakles-course-welcome';

const ThankYouPage = () => {
    const history = useHistory();

    const prismicDataCourse = usePersistedPrismicStore((state) => state.heraklesCourse);
    const { loading, content } = usePrismicData(prismicDataCourse);

    const {
        state: { user },
    } = useContext(AuthContext);

    const getUserText = (userText, guestText) => {
        return getText(content?.[user ? userText : guestText]);
    };

    const iconProp = useMemo(() => {
        if (!content) return null;
        return content[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_for_guest'];
    }, [content, user]);

    if (loading) return <Loader />;

    return (
        <ThankYou
            icon={iconProp?.url ?? WelcomeIcon}
            iconHeight={iconProp?.dimensions?.height ?? 64}
            iconWidth={iconProp?.dimensions?.width ?? 64}
            title={getUserText('eterno_user_thank_you_title', 'guest_thank_you_title') || 'Thank You'}
            centerParagraph={
                getText(content?.profile_synced_text) ||
                'Your health profile is now synced and updated accordingly in the Eterno App. You can simply share it in the future with a selected professional or export it as a pdf.'
            }
            paragraph={getText(content?.thank_you_subtitle) || 'You can bring now the iPad to the Front Desk.'}
            buttonText={getText(content?.all_forms_thankyou_continue) || 'Done'}
            onButtonClick={async () => {
                await signOut(user, history, WELCOME_PAGE);
            }}
        />
    );
};

export default ThankYouPage;
