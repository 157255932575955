import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

import contemporyPlace from '@/assets/images/contemporyPlace.svg';

import FilledButton from '@/components/FilledButton';
import Icon from '@/components/Icon/Icon';

import { replaceProfessionalType } from '@/utils/helpers';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingLeft: 30,
    },

    contentMainText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        textTransform: 'none',
        lineHeight: '1.75rem',
        color: theme.palette.common.darkGrey,
        paddingTop: 30,
        paddingBottom: 30,
        paddingRight: 30,
    },
    emptyClassesImg: {
        width: 300,
        height: 211,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: 200,
            height: 200,
        },
    },
}));

const RenderEmpty = ({ mainData }) => {
    const { i18n } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    console.debug('mainDataOFDAta', mainData);
    return (
        <div
            className={classes.cardContent}
            style={{
                marginTop: 23,
            }}
        >
            <Grid container direction={matches ? 'column-reverse' : 'row'}>
                <Grid item md={7} xs={12}>
                    <div style={{ width: '100%' }}>
                        <Typography className={classes.contentMainText} style={{ marginBottom: 13 }}>
                            {mainData
                                ? replaceProfessionalType({
                                      text: mainData?.no_saved_professionals[0].text,
                                      language: i18n.language,
                                  })
                                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
                        </Typography>
                        <FilledButton
                            text={replaceProfessionalType({
                                text: mainData?.find_an_expert_cta[0].text,
                                language: i18n.language,
                            })}
                            onPress={() => {
                                history.push('/find-appointment');
                            }}
                            marginBottom={30}
                        />
                    </div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: matches ? 'flex-start' : 'end',
                            marginBottom: matches ? 10 : 0,
                        }}
                    >
                        <div className={classes.emptyClassesImg}>
                            <Icon src={contemporyPlace} description="place in eterno" width="100%" height="100%" />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default RenderEmpty;
