import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import usePrismicLogin from ".'@/hooks/usePrismicLogin";
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from login
import { useHistory } from 'react-router-dom';

import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as ResetContext } from '@/context/ResetPaswdContext';

import { useStyles } from '../Login/LoginClasses';

export default function ResetVarification({ dataSet, mainData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const [disableResend, setDisableResend] = useState(false);

    let timer;
    useEffect(() => {
        setLoading(false);
        return () => {
            clearTimeout(timer);
        };
    }, [disableResend]);

    const {
        state: { resetVerficationCode, loading, error, email },
        setResetVerficationCode,
        setLoading,
        setError,
        resetVerfication,
    } = useContext(ResetContext);

    useEffect(() => {
        if (!email) history.goBack();
    }, [email]);

    // resend code function
    async function resendConfirmationCode() {
        const e = email.trim();

        // enabled button on re-send click
        setDisableResend(true);

        const timeOut = 1000 * 60 * 5;
        timer = setTimeout(() => {
            // disabled button on re-send click
            setDisableResend(false);
        }, timeOut);

        Auth.forgotPassword(e)
            .then((data) => {
                console.debug(data);
            })
            .catch((err) => {
                console.debug(err);
                // if error then enable resend
                setDisableResend(false);
                clearTimeout(timer);
            });
    }

    const sendVerificationCode = () => {
        const re = /^(?:[1-9]\d*|\d)$/;
        //
        // !re.test(resetVerficationCode)
        setError(null);
        console.debug('resetVerficationCode: upr', resetVerficationCode, re.test(resetVerficationCode));

        if (resetVerficationCode === '') {
            setError(mainData.required_field_error_message[0].text);
            return;
        }
        if (!re.test(resetVerficationCode) || resetVerficationCode.length !== 6) {
            console.debug('resetVerficationCode', resetVerficationCode);
            setError(mainData.invalid_code[0].text);
            return;
        }
        resetVerfication(history);
    };

    return (
        <div className="">
            <Typography className={classes.heading}>
                {dataSet && mainData.heading_2_enter_verification_code[0].text}
            </Typography>

            <Typography className={classes.subheading} style={{ marginTop: 5 }}>
                {dataSet && mainData.enter_verification_description[0].text}
            </Typography>

            <form
                className={classes.form}
                noValidate
                autoComplete="off"
                // onSubmit={handleFormSubmit}
            >
                <TextField
                    id="verificationcode"
                    label={dataSet && mainData.verification_code_text_field[0].text}
                    variant="filled"
                    color="primary"
                    value={resetVerficationCode}
                    onChange={(event) => {
                        const text = event.target.value;
                        setResetVerficationCode(text);
                    }}
                    fullWidth
                    // classes={{ root: classes.textFields }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                        },
                    }}
                    classes={{
                        root: error ? classes.textFieldsError : classes.textFields,
                    }}
                    InputProps={{
                        classes: {
                            root: classes.filledInputRoot,
                            focused: classes.fieldFocused,
                        },
                    }}
                />
                {error && (
                    <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                        {error}
                    </Typography>
                )}
            </form>
            <div className="" style={{ display: 'flex' }}>
                <Typography
                    className={classes.subHeading}
                    style={{
                        fontFamily: 'MessinaSans-Regular',
                        color: theme.palette.common.darkGrey,
                        textTransform: 'none',
                        marginLeft: 0,
                        textAlign: 'left',
                        fontSize: 14,
                        marginTop: 20,
                    }}
                >
                    {dataSet ? mainData.resend_code_prompt[0].text : 'You didn’t receive any code?'}
                </Typography>
                {/* <div className="" style={{ marginLeft: 5 }}> */}
                <UnderlinedButton
                    disabled={disableResend}
                    text={dataSet ? mainData.resend_code_cta[0].text : t('Resend Code')}
                    color={disableResend ? '#999' : theme.palette.common.darkGrey}
                    onPress={resendConfirmationCode}
                    marginLeft={5}
                />
                {/* </div> */}
            </div>

            {/*  */}
            <div className="" style={{ marginTop: 20 }}>
                <FilledButton
                    text={dataSet && mainData.continue_cta[0].text}
                    onPress={sendVerificationCode}
                    fullWidth
                    loading={loading}
                />
            </div>
        </div>
    );
}
