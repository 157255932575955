import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommentField from '@/components/CommentField';
import Spacer from '@/components/Common/Spacer';

/** Re-usable user components */
import ContactInformation from '@/components/Common/User/ContactInformation';
import { ConsentStyles, CourseDate, PersonalInfoCourse } from '@/components/ConsentForms';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useFormLabels, { pageNameLabels } from '@/hooks/useFormLabels';
import useI18nCountries from '@/hooks/useI18nCountries';
import useScrollToTop from '@/hooks/useScrollToTop';

import { usePersistedPrismicStore } from '@/store';

import AccountInsuranceSection from '../../../HealthProfileAnamnesis/AccountCommponents/AccountInsuranceSection';

const PersonalInformationPage = () => {
    /** Styles */
    const classes = ConsentStyles();
    const { i18n } = useTranslation();

    useScrollToTop();

    const [errorDate, setErrorDate] = useState(false);

    /** Context */
    const {
        consentFormsContent: content,
        formData,
        setFormData,
        handleChange,
        courseStartDate,
        courseStartDateError,
        setCourseStartDate,
        setCourseStartDateError,
    } = useContext(ConsentFormsContext);

    const {
        [i18n.language]: { mainDataAccountEterno, mainData, dataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    /** Form Labels */
    const labels = useFormLabels(content);

    /** Countries */
    const countryList = useI18nCountries();

    const setDateError = (err) => {
        setCourseStartDateError(err ? 'dobError' : '');
    };

    const setCountry = (country) => {
        setFormData((prev) => ({
            ...prev,
            country: {
                code: country,
                name: countryList[country?.toLocaleUpperCase()],
            },
        }));
    };

    useEffect(() => {
        if (!Object.keys(formData?.country).length > 0 && Object.keys(countryList).length > 0) {
            setFormData((prev) => ({
                ...prev,
                country: {
                    code: 'de',
                    name: countryList.DE,
                },
            }));
        }
    }, [countryList]);

    return (
        <>
            {/* COURSE DATE */}
            <CourseDate
                label={content?.formData?.courseStartDate}
                heading={content?.heraklesContent?.introText || ''}
                date={courseStartDate}
                setDate={setCourseStartDate}
                setError={setDateError}
                dateError={courseStartDateError}
                errorMessage={content?.heraklesContent?.[courseStartDateError]}
            />

            {/* SPACE */}
            <Spacer bottom={30} />

            {/* HEALTH INSURANCE */}
            {dataSet && (
                <AccountInsuranceSection
                    mainData={mainData}
                    mainDataAccountEterno={mainDataAccountEterno}
                    heading={content?.titles?.insuranceDetails || ''}
                    fullWidth
                    errorDate={errorDate}
                    setErrorDate={setErrorDate}
                />
            )}

            {/* SPACE */}
            <Spacer bottom={30} />

            {/* PERSONAL INFORMATION */}
            <PersonalInfoCourse
                /** Form States */
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                /** Form Labels/Heading */
                heading={content?.heraklesContent?.contactInfoTitleMain || ''}
                labels={labels[pageNameLabels.PERSONAL_INFO]}
            />

            {/* CONTACT INFORMATION */}
            <div className={classes.mt30}>
                <ContactInformation
                    /** Form States */
                    formData={formData}
                    handleChange={handleChange}
                    /** Form Labels/Heading */
                    heading={content?.heraklesContent?.contactInfoTitle || ''}
                    labels={labels[pageNameLabels.CONTACT_INFO]}
                    /** Country */
                    isCountry
                    setCountry={setCountry}
                />
            </div>

            {/* COMMENT */}
            <div className={classes.mt30}>
                <CommentField
                    heading={content?.heraklesContent?.commentHeading}
                    label={content?.heraklesContent?.commentlabel}
                    name="comment"
                    value={formData?.comment}
                    onChange={handleChange}
                />
            </div>
        </>
    );
};

export default PersonalInformationPage;
